import { useEffect, useState } from "react"
import { Plaintiff, Provider } from "./store/types"
import { exhibitBuilderActions, useExhibitBuilderStore } from "./store"
import { UserExhibitList } from "./UserExhibitList/UserExhibitList"
import { Box, FormControlLabel, Switch } from "@mui/material"
import { CollapseButtons } from "./Collapse/CollapseButtons"
import { useListData } from "./UserExhibitList/useListData"
import { PROVIDER_KEY } from "./store/constants"
import { CollapseProvider } from "./Collapse/collapse"
import { useShallow } from "zustand/react/shallow"
import { EB_DOC_TYPE } from "./types"

export function ProviderUserExhibits({
  providerId,
  plaintiffId,
}: {
  providerId?: Provider["id"]
  plaintiffId?: Plaintiff["id"]
}) {
  const [exactProviderMatch, setExactProviderMatch] = useState(true)
  const userExhibitIds = useExhibitBuilderStore(useShallow(state => Object.keys(state.userExhibitMap)))

  const listData = useListData({
    selectedProviders: providerId ? [providerId] : [],
    selectedPlaintiffs: plaintiffId ? [plaintiffId] : [],
    selectedDocTypes: [PROVIDER_KEY],
    exactProviderMatch,
  })

  useEffect(() => {
    exhibitBuilderActions.setReadOnly(true)
    exhibitBuilderActions.setExhibitBuilderType(EB_DOC_TYPE.DEMAND)
  }, [])

  if (!providerId || !plaintiffId) {
    return null
  }

  return (
    <CollapseProvider ids={userExhibitIds} defaultCollapsed={true}>
      <Box maxWidth="90%">
        <Box>
          <FormControlLabel
            label="Hide all unrelated provider relations"
            control={
              <Switch
                size="small"
                data-test="hide-unrelated"
                color="secondary"
                checked={exactProviderMatch}
                onChange={(_, checked) => {
                  setExactProviderMatch(checked)
                }}
              />
            }
          />
          <CollapseButtons />
        </Box>
        <UserExhibitList listData={listData} />
      </Box>
    </CollapseProvider>
  )
}
