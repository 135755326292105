import {
  PreviewExhibitPayload,
  PreviewUserExhibitPartitionPayload,
  PreviewUserExhibitPayload,
  RenderPdfPayload,
} from "message-broker/types"
import {
  PDF_DATA_QUERY_PARAM,
  PDF_FILENAME_QUERY_PARAM,
  PDF_PAGE_NUMBER_ADJUSTMENT_QUERY_PARAM,
  PDF_PAGE_QUERY_PARAM,
} from "./constants"
import { MESSAGE_TOPIC_ROUTES } from "app/constants"
import { MESSAGE_TOPIC } from "message-broker/topics"

export function toBase64(value: string): string {
  return window.btoa(encodeURIComponent(value))
}

export function fromBase64(value: string): string {
  return decodeURIComponent(window.atob(value))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function payloadToQueryParam(input: Record<string, any>): string {
  try {
    return toBase64(JSON.stringify(input))
  } catch {
    return ""
  }
}

export function queryParamToPayload<T>(input: string): Nullable<T> {
  try {
    return JSON.parse(fromBase64(input))
  } catch {
    return null
  }
}

export const extractExhibitIdFromUrl = (url: string): string | null => {
  if (typeof url !== "string" || url.trim() === "") {
    return null
  }

  // Regular expression to match the pattern /exhibit/{id}/
  const regex = /\/exhibit\/(\d+)\//
  const match = url.match(regex)

  if (match && match[1]) {
    return match[1]
  }

  return null
}

type RoutePath = {
  base: string
  route: string
}

export function getExhibitPreviewPath(payload: PreviewExhibitPayload): Nullable<RoutePath> {
  if ((payload.caseId || payload.documentId) && payload.exhibitId) {
    const { caseId, documentId, exhibitId, partitionId, fileName, page } = payload
    const searchParams = new URLSearchParams()

    if (page !== undefined) {
      searchParams.set(PDF_PAGE_QUERY_PARAM, String(page))
    }
    if (fileName !== undefined) {
      searchParams.set(PDF_FILENAME_QUERY_PARAM, fileName)
    }

    const basePath = caseId ? `${caseId}` : `documents/${documentId}`
    const exhibitPath = `${basePath}/exhibit/${exhibitId}`
    const path = partitionId !== undefined ? `${exhibitPath}/partition/${partitionId}` : exhibitPath

    return {
      base: MESSAGE_TOPIC_ROUTES[MESSAGE_TOPIC.PREVIEW_EXHIBIT] ?? "/",
      route: `${path}?${searchParams.toString()}`,
    }
  }

  return null
}

export function getUserExhibitPreviewPath(payload: PreviewUserExhibitPayload): Nullable<RoutePath> {
  if (!payload.userExhibitId) return null

  const { userExhibitId, page } = payload
  const searchParams = new URLSearchParams()

  if (page !== undefined) {
    searchParams.set(PDF_PAGE_QUERY_PARAM, String(page))
  }

  const path = `user-exhibit/${userExhibitId}`

  return {
    base: MESSAGE_TOPIC_ROUTES[MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT] ?? "/",
    route: `${path}?${searchParams.toString()}`,
  }
}

export function getUserExhibitPartitionPreviewPath(payload: PreviewUserExhibitPartitionPayload) {
  if (!payload.exhibitId) return null

  const { exhibitId, partitionId, fileName, page, pageNumberAdjustment } = payload
  const searchParams = new URLSearchParams()

  searchParams.set(PDF_FILENAME_QUERY_PARAM, fileName)
  searchParams.set(PDF_PAGE_QUERY_PARAM, String(page))
  searchParams.set(PDF_PAGE_NUMBER_ADJUSTMENT_QUERY_PARAM, String(pageNumberAdjustment))

  const path = `exhibit-builder/exhibit/${exhibitId}/partition/${partitionId}`

  return {
    base: MESSAGE_TOPIC_ROUTES[MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT] ?? "/",
    route: `${path}?${searchParams.toString()}`,
  }
}

export function getPdfPreviewPath(payload: RenderPdfPayload): Nullable<RoutePath> {
  if (payload.fileName && payload.url) {
    const searchParams = new URLSearchParams()

    searchParams.set(PDF_DATA_QUERY_PARAM, payloadToQueryParam(payload))
    return {
      base: MESSAGE_TOPIC_ROUTES[MESSAGE_TOPIC.RENDER_PDF] ?? "/",
      route: `pdf-preview?${searchParams.toString()}`,
    }
  }

  return null
}
