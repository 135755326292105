import { memo } from "react"
import { useProviderSummariesActions, useProviderSummariesStore } from "./store"
import { useDatesOfService } from "./store/selectors"
import { SummaryItem } from "./SummaryItem"
import {
  InlineRegenerateButton,
  SummariesActionsContainer,
  SummariesContainer,
  SummaryItemSkeleton,
} from "./styled"
import { Alert, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import Button from "evenup-ui/Button"

export const Summaries = memo(function Summaries() {
  const dates = useProviderSummariesStore(useDatesOfService())
  const status = useProviderSummariesStore(state => state.medicalSummariesState)
  const expandAction = useProviderSummariesStore(state => state.action)
  const { setExpandAction, regenerateMedicalSummaries } = useProviderSummariesActions()

  return (
    <>
      <SummariesActionsContainer>
        <Typography sx={{ fontWeight: 700, fontSize: "10px", lineHeight: "14px" }}>
          AI Generated Summary
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ ml: "auto" }}
          onClick={regenerateMedicalSummaries}
        >
          Regenerate all
        </Button>
        <Button
          variant="text"
          textButton
          color="secondary"
          size="small"
          onClick={() => setExpandAction(expandAction === "expand" ? "collapse" : "expand")}
        >
          <AddIcon /> {expandAction === "expand" ? "Collapse all" : "Expand all"}
        </Button>
      </SummariesActionsContainer>

      {status === "loading" && (
        <SummariesContainer>
          <SummaryItemSkeleton />
          <SummaryItemSkeleton />
          <SummaryItemSkeleton />
        </SummariesContainer>
      )}

      {status === "loaded" && !dates.length && (
        <Alert severity="info" sx={{ py: 0 }}>
          <Typography variant="helper">No summaries available.</Typography>
        </Alert>
      )}

      {status === "error" && (
        <Alert severity="error" sx={{ py: 0 }}>
          <Typography variant="helper">
            Summaries Could Not Generate.{" "}
            <InlineRegenerateButton onClick={regenerateMedicalSummaries}>
              Regenerate summaries
            </InlineRegenerateButton>
          </Typography>
        </Alert>
      )}

      {status !== "loading" && dates.length > 0 && (
        <SummariesContainer>
          {dates.map((date, idx) => (
            <SummaryItem
              key={date}
              dateOfService={date}
              initialOpen={idx === 0 || idx === dates.length - 1}
            />
          ))}
        </SummariesContainer>
      )}
    </>
  )
})
