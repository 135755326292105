import { ReactNode, createContext, useContext, useEffect, useRef } from "react"
import { createStore, useStore } from "zustand"

type CollaseState = {
  collapseStates: Record<string, boolean>
  initializeIfNotExists: (id: string, collapse: boolean) => void
  setCollapseState: (id: string, collapse: boolean) => void
  setCollapseStateForAll: (collapse: boolean) => void
}

const createCollapseStore = () => {
  return createStore<CollaseState>()(set => ({
    collapseStates: {},
    initializeIfNotExists: (id: string, collapse: boolean) => {
      set(state => ({
        collapseStates: {
          ...state.collapseStates,
          [id]: state.collapseStates[id] ?? collapse,
        },
      }))
    },
    setCollapseState: (id: string, collapse: boolean) => {
      set(state => ({
        collapseStates: {
          ...state.collapseStates,
          [id]: collapse,
        },
      }))
    },
    setCollapseStateForAll: (collapse: boolean) => {
      set(state => {
        const collapseStates = Object.keys(state.collapseStates).reduce<CollaseState["collapseStates"]>(
          (acc, id) => {
            acc[id] = collapse
            return acc
          },
          {}
        )

        return {
          collapseStates,
        }
      })
    },
  }))
}

type CollapseStore = ReturnType<typeof createCollapseStore>

const CollapseContext = createContext<CollapseStore | null>(null)

export const CollapseProvider = ({
  ids,
  defaultCollapsed,
  children,
}: {
  ids: string[]
  defaultCollapsed?: boolean
  children: ReactNode
}) => {
  const store = useRef(createCollapseStore()).current

  useEffect(() => {
    ids.forEach(id => {
      store.getState().initializeIfNotExists(id, Boolean(defaultCollapsed))
    })
  }, [ids, store, defaultCollapsed])

  return <CollapseContext.Provider value={store}>{children}</CollapseContext.Provider>
}

type ExtractState<S> = S extends {
  getState: () => infer T
}
  ? T
  : never

export function useCollapseStore<U>(selector: (state: ExtractState<CollapseStore>) => U) {
  const store = useContext(CollapseContext)
  if (!store) {
    throw new Error("useCollapseStore must be used within a CollapseProvider")
  }

  return useStore(store, selector)
}
