import React, { useCallback, useState } from "react"
import { isEqual } from "lodash"
import { useCaseVariables } from "demand/Variables"
import { QueryKey, useMutation, useQuery } from "@tanstack/react-query"
import { CaseEditorInput } from "demand/components/CaseEditor"
import { Provider } from "demand/Providers/types"
import { caseService } from "api/services/case"
import { ProviderSummaryResult } from "api/services/summaries/types"
import { providerSummariesApiService } from "api/services/summaries"
import { Action, UPDATE_INJURY_DETAILS, UPDATE_TEMPLATE } from "demand/Providers/store/reducer"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { TextVariable } from "common/types/variables"
import { TemplatePreview } from "demand/components/TemplatePreview"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { StyledNoTemplate } from "demand/components/styled"
import { queryClient } from "react-query/queryClient"
import { queryKeys } from "react-query/constants"
import { useDebouncedCallback } from "hooks/useDebouncedCallback"
import { usePermissions } from "permissions/usePermissions"
import { ReviewEditorProvider } from "review/ReviewEditor"
import { useFormContext } from "demand/context"
import { EditorHeader } from "./EditorHeader"

type ProviderFormEditorInvalidationProps = {
  invalidateQueryKeys?: QueryKey[]
}

type ProviderFormEditorProps = {
  provider: Provider
  dispatch: React.Dispatch<Action>
  variables: TextVariable[]
  caseId: PrimaryKey
  disabled: boolean
  onBlur: () => void
}

export function ProviderFormEditor({
  variables: customVariables = [],
  dispatch,
  provider,
  disabled,
  caseId,
  onBlur,
}: ProviderFormEditorInvalidationProps & ProviderFormEditorProps): JSX.Element {
  const { variables } = useCaseVariables(customVariables)
  const { currentPlaintiff } = useFormContext()
  const { showErrorMessage } = useHandleMessages()
  const [summaryRequested, setSummaryRequested] = useState(false)
  const [intervalMs, setIntervalMs] = useState<number | false>(false)
  const [forceRefresh, setForceRefresh] = useState(false)
  const hasTemplate = provider?.templated_sections?.[0]
  const isReviewNeeded = provider?.templated_sections?.[0]?.user_action_required ?? false
  const templateContent = provider?.templated_sections?.[0]?.template?.content

  const { mutate: resolveConflict } = useMutation(caseService.overrideProviderTemplate, {
    onSuccess: data => {
      dispatch({
        type: UPDATE_TEMPLATE,
        payload: { providerId: provider.pk, template: data },
      })
      queryClient.invalidateQueries([queryKeys.steps, caseId])
    },
    onError: error => {
      showErrorMessage({ message: "There was an error while trying to update provider.", error })
    },
  })

  const debouncedUpdateInjuryDetails = useDebouncedCallback(({ value }: { value: EditorRoot }) => {
    const customContent = hasTemplate ? value : null

    dispatch({
      type: UPDATE_INJURY_DETAILS,
      payload: { providerId: provider.pk, value, customContent },
    })
  }, 1500)

  const onBlurHandler = useCallback(() => {
    debouncedUpdateInjuryDetails.flush()

    requestAnimationFrame(() => onBlur())
  }, [debouncedUpdateInjuryDetails, onBlur])

  const handleResolve = useCallback(() => {
    if (!provider?.templated_sections?.[0]?.template || !provider.details_json) return

    resolveConflict({
      data: { customContent: provider.details_json },
      caseId,
      providerId: provider.pk,
      templateSectionId: provider?.templated_sections[0]?.pk,
    })
  }, [caseId, provider.pk, provider?.templated_sections, provider?.details_json, resolveConflict])

  const { data: summaryData } = useQuery(
    [queryKeys.providerSummary, provider.pk],
    () => providerSummariesApiService.getProviderSummary({ providerId: provider.pk, refresh: forceRefresh }),
    {
      onSuccess: (data: ProviderSummaryResult) => {
        const hasSummary = data?.status === "success"
        setForceRefresh(false)
        if (hasSummary) {
          setSummaryRequested(false)
          setIntervalMs(false)
        }
      },
      onError: error => {
        showErrorMessage({
          message: "Error getting provider summary",
          error,
        })
        setSummaryRequested(false)
        setIntervalMs(false)
        setForceRefresh(true)
      },
      enabled: summaryRequested || !!intervalMs,
      meta: { disableLoader: true },
      // Refetch the data every second
      refetchInterval: intervalMs,
      retry: false,
    }
  )

  const { caseSectionReviewEnabled } = usePermissions({ suspense: false })
  const editor = (
    <CaseEditorInput
      key={provider.templated_sections?.[0]?.template?.pk ?? "provider-injury-details"}
      name="injury-details"
      dataTest="injury-details"
      error={isReviewNeeded}
      disabled={disabled}
      onChange={debouncedUpdateInjuryDetails}
      variables={variables}
      value={provider.details_json}
      onBlur={onBlurHandler}
      includeAskAI
      caseId={caseId}
      generatorKeys={provider.templated_sections?.[0]?.template?.possible_generator_keys}
      askAiWarningBannerText={
        "When using Litty on Injury Details, the information will need to be manually copy and pasted from the text field."
      }
    />
  )

  return (
    <>
      <EditorHeader isReviewNeeded={isReviewNeeded} handleResolve={handleResolve} />
      {caseSectionReviewEnabled && currentPlaintiff ? (
        <ReviewEditorProvider
          templateType="provider"
          plaintiffId={currentPlaintiff.id}
          providerId={provider.pk}
          reviewRequestId={provider.current_review_run}
        >
          {editor}
        </ReviewEditorProvider>
      ) : (
        editor
      )}
      {isReviewNeeded &&
        templateContent &&
        (isEqual(templateContent, DEFAULT_VALUE) ? (
          <StyledNoTemplate>No template is returned</StyledNoTemplate>
        ) : (
          <TemplatePreview
            key={`${provider.templated_sections?.[0]?.template?.pk}-preview`}
            template={templateContent}
            variables={variables}
          />
        ))}
      {summaryData?.results && (
        <TemplatePreview
          key={`${provider.templated_sections?.[0]?.template?.pk}-summary`}
          template={summaryData.results}
          templateHeader="AI Generated Summary"
          variables={variables}
        />
      )}
    </>
  )
}
