import * as Sentry from "@sentry/browser"
import { CaseView } from "cases/CaseViewProvider"

type getFirstPlaintiffNameReturnType = {
  firstName: string
  lastName: string
  fullName: string
}

type FirstName = Flavor<string, "FirstName">
type LastName = Flavor<string, "LastName">

export function getFirstPlaintiffNameFromCase(caseViewData: CaseView): getFirstPlaintiffNameReturnType {
  return getFirstPlaintiffName({
    plaintiff_first_name: caseViewData.plaintiffFirstName,
    plaintiff_last_name: caseViewData.plaintiffLastName,
    plaintiffs: caseViewData.plaintiffs,
  })
}

export function getFullName(firstName?: FirstName, lastName?: LastName): string {
  return [firstName, lastName].filter(v => !!v).join(" ")
}

export function getPlaintiffName(plaintiff: {
  first_name?: string
  last_name?: string
}): getFirstPlaintiffNameReturnType {
  if (plaintiff.first_name || plaintiff.last_name) {
    return {
      firstName: plaintiff.first_name ?? "",
      lastName: plaintiff.last_name ?? "",
      fullName: getFullName(plaintiff.first_name, plaintiff.last_name),
    }
  }

  // If no request is provided, return empty strings
  Sentry.captureMessage("getPlaintiffName called for a plaintiff with no name", "warning")
  return { firstName: "", lastName: "", fullName: "" }
}

export function getFirstPlaintiffName(request?: {
  plaintiff_first_name?: Nullable<string>
  plaintiff_last_name?: Nullable<string>
  plaintiffs?: { first_name?: string; last_name?: string }[]
}): getFirstPlaintiffNameReturnType {
  // If no request is provided, return empty strings
  if (!request) {
    Sentry.captureMessage("getFirstPlaintiffName called without a request", "warning")
    return { firstName: "", lastName: "", fullName: "" }
  }

  // If the request has plaintiffs, use the first plaintiff's name
  if (
    request.plaintiffs &&
    request.plaintiffs.length > 0 &&
    (request.plaintiffs[0].first_name || request.plaintiffs[0].last_name)
  ) {
    return {
      firstName: request.plaintiffs[0].first_name ?? "",
      lastName: request.plaintiffs[0].last_name ?? "",
      fullName: getFullName(request.plaintiffs[0].first_name, request.plaintiffs[0].last_name),
    }
  }

  // If the request has plaintiff_first_name and plaintiff_last_name, use those
  if (request.plaintiff_first_name || request.plaintiff_last_name) {
    const firstName = request.plaintiff_first_name ?? ""
    const lastName = request.plaintiff_last_name ?? ""
    return {
      firstName,
      lastName,
      fullName: getFullName(firstName, lastName),
    }
  }

  // Otherwise, return empty strings
  Sentry.captureMessage(
    "getFirstPlaintiffName called with a request that has no plaintiffs or plaintiff names",
    "warning"
  )
  return { firstName: "", lastName: "", fullName: "" }
}
