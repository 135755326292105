import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { UserExhibit } from "exhibit-builder/store/types"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { formatDate } from "utils"
import { useShallow } from "zustand/react/shallow"

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
})

const PageRangeContainer = styled(Box)({
  display: "block",
  maxWidth: "250px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
})

export function UserExhibitInfo({ id }: { id: UserExhibit["id"] }) {
  const type = useExhibitBuilderStore(state => state.exhibitBuilderType)
  const dateRange = useExhibitBuilderStore(
    useShallow(state => {
      const recordsAndBills = filesSelectors.getRecordsAndBillsByUserExhibitId(id)(state)
      let startDate: string | undefined
      let endDate: string | undefined

      recordsAndBills.forEach(recordOrBillId => {
        const item = state.recordsAndBillsMap[recordOrBillId]
        const itemStartDate = item?.type === "Medical Bill" ? item.dateOfFirstService : item?.dateOfService
        const itemEndDate = item?.type === "Medical Bill" ? item.dateOfLastService : item?.dateOfService

        if (!itemStartDate || !itemEndDate) {
          return
        }

        if (!startDate || new Date(itemStartDate) < new Date(startDate)) {
          startDate = itemStartDate
        }
        if (!endDate || new Date(itemEndDate) > new Date(endDate)) {
          endDate = itemEndDate
        }
      })

      return { startDate, endDate }
    })
  )

  const totalPages = useExhibitBuilderStore(
    useShallow(state => {
      const partitions = state.exhibitPartitionOrder[id]
      let totalPages = 0
      partitions?.forEach(partitionId => {
        const partition = state.exhibitPartitionMap[partitionId]
        totalPages += partition.endPage - partition.startPage + 1
      })

      return totalPages
    })
  )

  const totalAppts = useExhibitBuilderStore(
    useShallow(state => {
      const recordsAndBills = filesSelectors.getRecordsAndBillsByUserExhibitId(id)(state)

      return recordsAndBills.length
    })
  )

  return (
    <Container>
      <Box>
        Date range: {formatDate(dateRange.startDate, "MM/dd/yyyy", true)} -{" "}
        {formatDate(dateRange.endDate, "MM/dd/yyyy", true)}
      </Box>
      {type === EB_DOC_TYPE.DEMAND && (
        <PageRangeContainer data-test="total-pages">Total pages: {totalPages}</PageRangeContainer>
      )}
      {type === EB_DOC_TYPE.MEDCHRON && (
        <PageRangeContainer data-test="number-of-appts">Appts: {totalAppts}</PageRangeContainer>
      )}
    </Container>
  )
}
