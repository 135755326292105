import { useCallback, useState } from "react"

import { decodeExhibitValue } from "demand/Providers/Provider/ProviderForm/BillsSection/utils"
import {
  EXHIBIT_ID,
  PARTITION_ID,
  USER_EXHIBIT_ID,
} from "demand/Providers/Provider/ProviderForm/BillsSection/constants"
import { ReferencePage } from "./ReferencePage"
import { LOCAL_REFERENCE_PAGE_ID } from "../../constants"
import { ReferencesEditableList } from "../../styled"
import { ReferencePage as ReferencePageType } from "common/types/providers"
import { ReferencePagesListProps, ValidationError } from "./types"
import { getReferenceDuplicatesValidationErrors } from "./utils"
import { useExhibitBuilderStore } from "exhibit-builder/store"

export const ReferencePagesList = ({
  references,
  setReferences,
  isDisabled,
  provider,
  onUpdate,
}: ReferencePagesListProps) => {
  const hasExhibitBuilderFiles = useExhibitBuilderStore(state => Object.values(state.files).length)
  const hasNoFiles = !provider.filesToUpload?.length && !provider.exhibits?.length && !hasExhibitBuilderFiles
  const [errors, setErrors] = useState<ValidationError[]>([])

  const handlePageNumberChange = useCallback(
    ({ referenceId, value }: { referenceId: ReferencePageType["id"]; value: string }) => {
      // TODO: handle max page limit
      const pageNumber = Math.max(1, parseInt(value, 10) || 1)

      setReferences(prevReferences => {
        const updatedReferencePages = prevReferences.map(prevReference => {
          if (prevReference.id === referenceId) return { ...prevReference, pageNumber }

          return prevReference
        })

        return updatedReferencePages
      })
    },
    [setReferences]
  )

  const handleExhibitChange = useCallback(
    ({ referenceId, value }: { referenceId: ReferencePageType["id"]; value: string }) => {
      const [type, id] = decodeExhibitValue(value)

      setReferences(prevReferences => {
        const updatedReferencePages = prevReferences.map(prevReference => {
          if (prevReference.id === referenceId) {
            return {
              ...prevReference,
              exhibitId: type === EXHIBIT_ID ? Number(id) : null,
              partitionId: type === PARTITION_ID ? Number(id) : null,
              userExhibitId: type === USER_EXHIBIT_ID ? Number(id) : null,
            }
          }

          return prevReference
        })

        return updatedReferencePages
      })
    },
    [setReferences]
  )

  const handleOnBlur = useCallback(() => {
    const newErrors = getReferenceDuplicatesValidationErrors(references)
    setErrors(newErrors)

    if (newErrors.length) return

    const pageReferencesNeedToBeSynced = references
      .filter(
        ({ exhibitId, partitionId, pageNumber, userExhibitId }) =>
          userExhibitId || exhibitId || partitionId || pageNumber
      )
      .map(({ id, exhibitId, partitionId, pageNumber, userExhibitId }) => {
        const object = { exhibitId, partitionId, pageNumber, userExhibitId }

        return id?.startsWith(LOCAL_REFERENCE_PAGE_ID) ? object : { id, ...object }
      })

    onUpdate(pageReferencesNeedToBeSynced)
  }, [onUpdate, references])

  return (
    <ReferencesEditableList>
      {references.map(referencePage => (
        <ReferencePage
          key={referencePage.id}
          referencePage={referencePage}
          isDisabled={isDisabled || hasNoFiles}
          onExhibitChange={handleExhibitChange}
          onPageNumberChange={handlePageNumberChange}
          provider={provider}
          onBlur={handleOnBlur}
          error={errors.find(error => error.referencePageId === referencePage.id)}
        />
      ))}
    </ReferencesEditableList>
  )
}
