import { EB_DOC_TYPE } from "exhibit-builder/types"
import { SetState } from "./types"

export type DocumentSlice = {
  isReadOnly: boolean
  caseId: string
  exhibitBuilderType: EB_DOC_TYPE | null
  document: {
    loading: boolean
    error: string | null
    documentId: string
    isReordering: boolean
  }
  scrollToIndex: number | null
}

export const documentSlice: DocumentSlice = {
  isReadOnly: false,
  caseId: "",
  exhibitBuilderType: null,
  document: {
    loading: false,
    error: null,
    documentId: "",
    isReordering: false,
  },
  scrollToIndex: null,
}

export const documentSelectors = {
  getDemandLoading: (state: DocumentSlice) => state.document.loading,
}

export const documentActions = (set: SetState<DocumentSlice>) => {
  const setIsLoading = (loading: boolean) => {
    set(({ document }) => ({ document: { ...document, loading } }))
  }
  const setDocumentId = (documentId: string) =>
    set(({ document }) => ({ document: { ...document, documentId } }))

  const setIsReordering = (isReordering: boolean) => {
    set(({ document }) => ({ document: { ...document, isReordering } }))
  }

  const setCaseId = (caseId: string) => set({ caseId })

  const setExhibitBuilderType = (exhibitBuilderType: EB_DOC_TYPE | null) => set({ exhibitBuilderType })

  const setReadOnly = (isReadOnly: boolean) => set({ isReadOnly })

  const setScrollToIndex = (scrollToIndex: number | null) => set({ scrollToIndex })

  return {
    setCaseId,
    setIsLoading,
    setDocumentId,
    setIsReordering,
    setExhibitBuilderType,
    setReadOnly,
    setScrollToIndex,
  }
}
