import { UserExhibit } from "exhibit-builder/store/types"
import { PROVIDER_KEY, UNASSIGNED_KEY } from "exhibit-builder/store/constants"

export const checkValidUserExhibit = (exhibit: UserExhibit) => {
  if (exhibit.docType !== PROVIDER_KEY) return { isValid: true }

  const missingPlaintiff = !exhibit.plaintiffId
  const missingSubDocType = !exhibit.subDocType || exhibit.subDocType === UNASSIGNED_KEY
  const missingSortingProvider = !exhibit.sortingProviderId

  const isValid = !missingPlaintiff && !missingSubDocType && !missingSortingProvider

  return { isValid, missingPlaintiff, missingSubDocType, missingSortingProvider }
}
