import { useCallback } from "react"
import { useMutation } from "@tanstack/react-query"
import { Box } from "@mui/material"

import { providerObjectiveTestService } from "api/services/provider-objective-test"
import { useFormContext } from "demand/context"
import { ADD_OBJECTIVE_TEST, Action } from "demand/Providers/store/reducer"
import { Provider } from "demand/Providers/types"
import TextButton from "common/buttons/TextButton"
import { ObjectiveTestForm } from "./ObjectiveTestForm"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { ObjectiveTestItemSkeleton } from "./components"
import { SectionHeading } from "../../styled"
import { ObjectiveTestEditableRowHeader } from "./styled"

interface ObjectiveTestsSectionProps {
  disabled: boolean
  dispatch: React.Dispatch<Action>
  provider: Provider
}

export const ObjectiveTestsSection = ({ disabled, dispatch, provider }: ObjectiveTestsSectionProps) => {
  const { caseId } = useFormContext()
  const providerId = provider.pk

  const { showErrorMessage } = useHandleMessages()

  const showErrorNotification = (error: Error) => {
    showErrorMessage({
      message: "Error adding new Objective Test.",
      error,
    })
  }

  const { mutate: createNewObjectiveTest, isLoading: isCreatingNewObjectiveTest } = useMutation(
    providerObjectiveTestService.createProviderObjectiveTest,
    {
      onSuccess: data => {
        dispatch({
          type: ADD_OBJECTIVE_TEST,
          payload: { providerId, objectiveTest: data },
        })
      },
      onError: showErrorNotification,
    }
  )

  const handleAddObjectiveTest = useCallback(
    () => createNewObjectiveTest({ providerId, caseId }),
    [createNewObjectiveTest, providerId, caseId]
  )

  return (
    <Box mb={3}>
      <SectionHeading>Objective Tests</SectionHeading>

      <ObjectiveTestEditableRowHeader>
        <Box>Description</Box>
        <Box pl={1}>Date</Box>
        <Box pl={1.5}>Finding</Box>
        <Box pl={2}>Reference Exhibit</Box>
        <Box>Page Number</Box>
      </ObjectiveTestEditableRowHeader>

      {provider?.objective_tests?.map(objectiveTest => (
        <ObjectiveTestForm
          key={objectiveTest?.id}
          objectiveTest={objectiveTest}
          dispatch={dispatch}
          provider={provider}
        />
      ))}

      {isCreatingNewObjectiveTest && <ObjectiveTestItemSkeleton />}

      <TextButton onClick={handleAddObjectiveTest} disabled={disabled}>
        + ADD OBJECTIVE TEST
      </TextButton>
    </Box>
  )
}
