import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { GenericErrorForSuspense, Loading } from "common"
import { PdfRenderer } from "common/pdf-renderer"
import { PinControl } from "./PinControl"
import { PDF_PAGE_QUERY_PARAM } from "./constants"
import { exhibitService } from "api/services/exhibit"
import invariant from "invariant"
import { withSuspense } from "common/withSuspense"

export const UserExhibitPreviewPage = withSuspense(
  function UserExhibitPreviewPage(): JSX.Element {
    const { userExhibitId } = useParams()
    const [searchParams] = useSearchParams()
    const page = parseInt(searchParams.get(PDF_PAGE_QUERY_PARAM)?.toLowerCase() || "1") || 1
    const { showErrorMessage } = useHandleMessages()

    const [url, setUrl] = useState<string>("")
    const [filename, setFilename] = useState<string>("")

    invariant(!!userExhibitId, "UserExhibit id should be passed")

    useEffect(() => {
      const fetchUserExhibit = async () => {
        if (!userExhibitId) return
        const userExhibit = await exhibitService.getUserExhibit({ id: userExhibitId })

        if (userExhibit.url) {
          setUrl(userExhibit.url)
          setFilename(userExhibit.name)
        } else {
          showErrorMessage({
            message: `User Exhibit ${userExhibit} is not found or not ready`,
          })
        }
      }

      fetchUserExhibit()
    }, [showErrorMessage, userExhibitId])

    return (
      <>
        <Helmet>
          <title>{filename || "Exhibit preview"}</title>
        </Helmet>
        {url && (
          <PdfRenderer
            key={url}
            userExhibitId={userExhibitId}
            filename={filename}
            currentFileName={filename ?? undefined}
            url={url}
            page={page}
            withCredentials
            rightControls={<PinControl />}
          />
        )}
      </>
    )
  },
  <Loading show label="Loading exhibit data..." />,
  <GenericErrorForSuspense />
)
