import { useState, useCallback, useEffect } from "react"
import { TextField } from "@mui/material"

import { ReferencePage as ReferencePageType } from "common/types/providers"
import { ObjectiveTestFinding } from "api/services/provider-objective-test/types"
import { decodeExhibitValue } from "demand/Providers/Provider/ProviderForm/BillsSection/utils"
import {
  EXHIBIT_ID,
  PARTITION_ID,
  USER_EXHIBIT_ID,
} from "demand/Providers/Provider/ProviderForm/BillsSection/constants"

import { getInitialReferencePageState } from "demand/Providers/Provider/ProviderForm/ObjectiveTests/utils"
import { LOCAL_REFERENCE_PAGE_ID } from "demand/Providers/Provider/ProviderForm/ObjectiveTests/constants"
import { FindingsEditableListProps } from "../FindingsEditableList"
import { ReferencePage } from "./components"
import { ObjectiveTestFindingEditableRow, ObjectiveTestFindingReferencesEditableList } from "../../styled"
import { useExhibitBuilderStore } from "exhibit-builder/store"

export type FindingEditableItemProps = {
  finding: ObjectiveTestFinding
} & Pick<FindingsEditableListProps, "isDisabled" | "disabledTitle" | "updateFinding" | "provider">

const FindingEditableItem = ({
  finding,
  isDisabled,
  disabledTitle,
  updateFinding,
  provider,
}: FindingEditableItemProps) => {
  const findingId = finding.id
  const hasNoFindingDescription = !finding.finding
  const [findingDescription, setFindingDescription] = useState(finding.finding ?? "")
  const [referencePages, setReferencePages] = useState<ReferencePageType[]>(finding.referencePages ?? [])
  const hasExhibitBuilderFiles = useExhibitBuilderStore(state => Object.values(state.files).length)

  const hasNoFiles =
    !provider?.filesToUpload?.length && !provider?.exhibits?.length && !hasExhibitBuilderFiles

  const handleFillingValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFindingDescription(e.target.value)
  }, [])

  const handlePageNumberChange = useCallback(
    ({ referencePageId, value }: { referencePageId: ReferencePageType["id"]; value: string }) => {
      // TODO: handle max page limit
      const pageNumber = Math.max(1, parseInt(value, 10) || 1)

      setReferencePages(prevReferencePages => {
        const updatedReferencePages = prevReferencePages.map(prevReference => {
          if (prevReference.id === referencePageId) return { ...prevReference, pageNumber }

          return prevReference
        })

        return updatedReferencePages
      })
    },
    []
  )

  const handleExhibitChange = useCallback(
    ({ referencePageId, value }: { referencePageId: ReferencePageType["id"]; value: string }) => {
      const [type, id] = decodeExhibitValue(value)

      setReferencePages(prevReferencePages => {
        const updatedReferencePages = prevReferencePages.map(prevReference => {
          if (prevReference.id === referencePageId) {
            return {
              ...prevReference,
              exhibitId: type === EXHIBIT_ID ? Number(id) : null,
              partitionId: type === PARTITION_ID ? Number(id) : null,
              userExhibitId: type === USER_EXHIBIT_ID ? Number(id) : null,
            }
          }

          return prevReference
        })

        return updatedReferencePages
      })
    },
    []
  )

  const handleOnBlur = useCallback(() => {
    const pageReferencesNeedToBeSynced = referencePages
      .filter(
        ({ exhibitId, partitionId, pageNumber, userExhibitId }) =>
          userExhibitId || exhibitId || partitionId || pageNumber
      )
      .map(({ id, exhibitId, partitionId, pageNumber, userExhibitId }) => {
        const object = { exhibitId, partitionId, pageNumber, userExhibitId }

        return id?.startsWith(LOCAL_REFERENCE_PAGE_ID) ? object : { id, ...object }
      })

    updateFinding({ ...finding, finding: findingDescription, referencePages: pageReferencesNeedToBeSynced })
  }, [updateFinding, finding, findingDescription, referencePages])

  useEffect(() => {
    setReferencePages(
      finding.referencePages?.length ? finding.referencePages : [getInitialReferencePageState()]
    )
  }, [finding.referencePages])

  return (
    <ObjectiveTestFindingEditableRow key={findingId}>
      <TextField
        value={findingDescription}
        onChange={handleFillingValueChange}
        onBlur={handleOnBlur}
        disabled={isDisabled}
        title={isDisabled ? disabledTitle : undefined}
      />
      <ObjectiveTestFindingReferencesEditableList>
        {referencePages.map(referencePage => (
          <ReferencePage
            key={referencePage.id}
            referencePage={referencePage}
            isDisabled={hasNoFiles || isDisabled || hasNoFindingDescription}
            onExhibitChange={handleExhibitChange}
            onPageNumberChange={handlePageNumberChange}
            provider={provider}
            onBlur={handleOnBlur}
          />
        ))}
      </ObjectiveTestFindingReferencesEditableList>
    </ObjectiveTestFindingEditableRow>
  )
}

export default FindingEditableItem
