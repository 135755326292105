import { useCallback, useMemo } from "react"
import { DropdownContainer, DropdownLabel } from "./styled"
import { Select, MenuItem, SelectChangeEvent } from "@mui/material"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { UserExhibit } from "exhibit-builder/store/types"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { UNASSIGNED_KEY } from "exhibit-builder/store/constants"
import { red } from "@mui/material/colors"
import { useShallow } from "zustand/react/shallow"

const ItemValue = ({ name, value }: { name?: string; value?: string }) => {
  return !value || value === UNASSIGNED_KEY ? <span style={{ color: red[700] }}>Unassigned</span> : name
}

export function SubDocTypeDropdown({ id }: { id: UserExhibit["id"] }) {
  const userExhibit = useExhibitBuilderStore(filesSelectors.getUserExhibitById(id))
  const docTypes = useExhibitBuilderStore(state => state.docTypes)
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const selectedDocType = userExhibit.docType ? docTypes[userExhibit.docType] : null

  const subDocTypesMap = useMemo(() => {
    const subDocTypes: Record<string, { key: string; name: string }> = {}
    for (const subDocType of selectedDocType?.subDocTypes ?? []) {
      subDocTypes[subDocType.key] = subDocType
    }
    return subDocTypes
  }, [selectedDocType])

  const handleSubDocTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      exhibitBuilderActions.updateUserExhibit({
        id,
        subDocType: event.target.value,
      })
    },
    [id]
  )

  return (
    <DropdownContainer>
      <DropdownLabel>Sub-Doc Type:</DropdownLabel>
      <Select
        value={userExhibit.subDocType ?? ""}
        onChange={handleSubDocTypeChange}
        renderValue={value => (
          <ItemValue name={subDocTypesMap[value]?.name} value={subDocTypesMap[value]?.key} />
        )}
        data-test="user-exhibit-sub-doc-type-dropdown"
        readOnly={isReadOnly}
      >
        {selectedDocType?.subDocTypes?.map((subDocType, index) => (
          <MenuItem key={index} value={subDocType.key}>
            {subDocType.name}
          </MenuItem>
        ))}
      </Select>
    </DropdownContainer>
  )
}
