import { createContext, useContext, useMemo } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "../react-query/constants"
import { loginUser, logoutUser, loginStytchUser } from "../api"
import { EVENUP_LOGIN_ID, LS_LOGGED_IN_KEY } from "common/constants"
import { fullstoryApm, sentryApm } from "infrastructure/apm"
import { AuthorizedUser, NotAuthorizedUser } from "common/models/user"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { LoginAnalyticEvent, LoginAnalyticsEventTypes } from "infrastructure/apm/events/loginEvents"
import { VitallyLib } from "infrastructure/vendors/Vitally"
import * as Sentry from "@sentry/browser"
import { BeamerLib } from "infrastructure/vendors/BeamerLib"
import { resetUserState } from "user/store"

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const bubbleSentryContextError = (): Promise<void> => {
  const error = new Error("useAuth() did not instantiate correctly")
  Sentry.captureException(error)
  return Promise.reject(error)
}

const AuthContext = createContext<Auth>({
  // We want to throw an error if the context is not instantiated correctly
  // while maintaining the original function signature
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: (data: LoginData) => bubbleSentryContextError(),
  logout: () => bubbleSentryContextError(),
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  loginWithStytch: (data: StytchData) => bubbleSentryContextError(),
})

export function ProvideAuth({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

// hook for child components to get the auth object
export const useAuth = (): Auth => {
  return useContext(AuthContext)
}

interface LoginData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

interface StytchData {
  token: string
  oauth: boolean
  stytchTokenType: boolean
  redirectPath?: string
}

interface Auth {
  login: (data: LoginData) => Promise<void>
  logout: () => Promise<void>
  loginWithStytch: (data: StytchData) => Promise<void>
}

// Provider hook that creates auth object
function useProvideAuth(): Auth {
  const queryClient = useQueryClient()

  return useMemo(() => {
    const login = async (data: LoginData) => {
      const user = await loginUser(data).then(response => AuthorizedUser.fromJSON(response?.data?.user))
      queryClient.setQueryData([queryKeys.session], user)
      localStorage.setItem(LS_LOGGED_IN_KEY, String(true))

      fullstoryApm.setUserContext(user)
      amplitudeApm.setUserContext(user)
      sentryApm.setUserContext(user)
      VitallyLib.setUserContext(user)
      BeamerLib.setUserContext(user)
    }

    const loginWithStytch = async ({ token, oauth, stytchTokenType, redirectPath }: StytchData) => {
      const user = await loginStytchUser({ token, oauth, stytchTokenType, redirectPath })
        .then(response => {
          if (!response?.data?.user && !response?.redirect_url) {
            throw new Error("No user or redirect url")
          }

          if (response?.data?.user) {
            const user = AuthorizedUser.fromJSON(response?.data?.user)
            const login_id = localStorage.getItem(EVENUP_LOGIN_ID)
            amplitudeApm.trackEvent(
              new LoginAnalyticEvent(LoginAnalyticsEventTypes.LoginSuccess, {
                user_email: user.email,
                user_id: user.id,
                login_id,
              })
            )
            return user
          }

          // NOTE: The following is a temporary patch to allow the concept of
          // having internal (evenup.ai emails) use Stytch B2B auth on the backend
          // while all other users go through normal Stytch Consumer auth - AndyC 3/15/24
          if (response?.redirect_url) {
            let redirect_url = response.redirect_url
            if (response?.redirect_path) {
              redirect_url += `?path=${response.redirect_path}`
            }
            window.location.href = redirect_url
            return
          }
        })
        .catch(error => {
          Sentry.captureException(error)

          let errMessage = "Default error message for loginWithStytch()"
          // Wrapping in try-catch due to JSON.parse()
          try {
            errMessage = JSON.parse(error.message).error
          } catch (e) {
            Sentry.captureMessage("Failed to JSON parse error.message", {
              level: "warning",
              extra: { originalError: error, jsonParseError: e },
            })
          }

          amplitudeApm.trackEvent(
            new LoginAnalyticEvent(LoginAnalyticsEventTypes.LoginFailed, {
              failure_reason: errMessage,
            })
          )

          throw error
        })

      if (user) {
        // WARNING, setting user here could trigger an unexpected unmount/mount
        // if there is parent component that is conditionally rendering children
        // based on user information being set
        queryClient.setQueryData([queryKeys.session], user)

        localStorage.setItem(LS_LOGGED_IN_KEY, String(true))

        fullstoryApm.setUserContext(user)
        amplitudeApm.setUserContext(user)
        sentryApm.setUserContext(user)
        VitallyLib.setUserContext(user)
        BeamerLib.setUserContext(user)
      }
    }

    const logout = async () => {
      try {
        await logoutUser()
        localStorage.removeItem(LS_LOGGED_IN_KEY)
        resetUserState({ isLoaded: true })

        queryClient.setQueryData([queryKeys.session], new NotAuthorizedUser())
      } catch (err) {
        Sentry.captureException(err)
      }
    }

    return {
      login,
      logout,
      loginWithStytch,
    }
  }, [queryClient])
}
