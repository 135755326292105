import { Alert, AlertTitle, Button, Box, CircularProgress } from "@mui/material"
import styled from "@emotion/styled"

const SyncButton = styled(Button)(({ theme }) => ({
  color: theme.palette.blue.primary,
  borderColor: theme.palette.secondary.main,
  border: "solid 1px",
  borderRadius: "6px",
  padding: theme.spacing(1.25, 2),
}))

interface SyncWithDocumentStructureButtonProps {
  isLoading: boolean
  onSync: () => void
}

export const SyncWithDocumentStructureButton = ({
  isLoading,
  onSync,
}: SyncWithDocumentStructureButtonProps) => {
  return (
    <Alert severity="info">
      <AlertTitle>Updated Document Structure available</AlertTitle>
      <Box my={1}>
        An admin has updated the layout or formatting of this document. Click &lsquo;<strong>Sync</strong>
        &rsquo; to apply the changes. If you choose not to sync, the document will remain unchanged.
      </Box>

      <SyncButton
        startIcon={isLoading ? <CircularProgress size="20px" color="secondary" /> : undefined}
        disabled={isLoading}
        onClick={onSync}
      >
        {isLoading ? "Syncing" : "Sync Document Updates"}
      </SyncButton>
    </Alert>
  )
}
