import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { providersActions } from "./providers"
import { documentActions } from "./document"
import { actionsWithLogging, logger } from "utils"
import { isDevEnvironment, isTestEnvironment } from "infrastructure/env/getEnvironment"
import { filtersActions } from "./filters"
import { filesActions } from "./files/filesActions"
import sentryMiddleware from "sentry-zustand-middleware"
import { createState, ExhibitBuilder, actions } from "./exhibitBuilder"

const createExhibitBuilderStore = () => {
  if (isDevEnvironment() && !isTestEnvironment()) {
    return create<ExhibitBuilder>()(devtools(logger(createState), { name: "ExhibitBuilder" }))
  }

  return create<ExhibitBuilder>()(sentryMiddleware(logger(createState)))
}

export const useExhibitBuilderStore = createExhibitBuilderStore()

export const exhibitBuilderStoreSelectors = {}

export const exhibitBuilderActions = actionsWithLogging({
  ...documentActions(useExhibitBuilderStore.setState),
  ...filesActions(useExhibitBuilderStore.setState, useExhibitBuilderStore.getState),
  ...providersActions(useExhibitBuilderStore.setState, useExhibitBuilderStore.getState),
  ...actions(useExhibitBuilderStore.setState),
  ...filtersActions(useExhibitBuilderStore.setState),
})
