import { v4 } from "uuid"
import { omit } from "lodash"
import { PROVIDER_UPDATE_PARTIAL_SUCCESS, SAVE_IN_BACKGROUND, PROVIDER_UPDATE_SUCCESS } from "./store/reducer"

export const updateProviderActions = {
  partiallyUpdatedAction: (newProvider, caseId) => ({
    type: PROVIDER_UPDATE_PARTIAL_SUCCESS,
    payload: { provider: newProvider, caseId },
  }),
  fullyUpdatedAction: (newProvider, caseId) => ({
    type: PROVIDER_UPDATE_SUCCESS,
    payload: { provider: newProvider, caseId },
  }),
  showSaving: true,
  showMessages: true,
}

export const updateInBackgroundProviderActions = {
  partiallyUpdatedAction: (newProvider, caseId) => ({
    type: SAVE_IN_BACKGROUND,
    payload: { provider: newProvider, caseId },
  }),
  fullyUpdatedAction: (newProvider, caseId) => ({
    type: SAVE_IN_BACKGROUND,
    payload: { provider: newProvider, caseId },
  }),
  showSaving: false,
  showMessages: false,
}

export const updateWhenBlurredProviderActions = {
  partiallyUpdatedAction: (newProvider, caseId) => ({
    type: SAVE_IN_BACKGROUND,
    payload: { provider: newProvider, caseId },
  }),
  fullyUpdatedAction: (newProvider, caseId) => ({
    type: SAVE_IN_BACKGROUND,
    payload: { provider: newProvider, caseId },
  }),
  showSaving: true,
  showMessages: false,
}

export const getNewProviderData = orderIndex => ({
  formId: v4(),
  name: "",
  open: true,
  include_table: true,
  icd_codes: [],
  cpt_codes: [],
  bills: [],
  order_index: orderIndex,
  objective_tests: [],
})

// don't copy over order_index so we don't accidentally send it after resetting
export const providerDataToFormData = provider => omit(provider, ["order_index"])

export const createSetOfCodes = codes => {
  if (!Array.isArray(codes)) return new Set([])

  return new Set(codes.map(({ code }) => code))
}
