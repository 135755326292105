import { exhibitBuilderService } from "exhibit-builder/api/service"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { ExhibitFile } from "exhibit-builder/store/types"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { useCallback } from "react"

export const useGetExhibitUrl = () => {
  const exhibitBuilderType = useExhibitBuilderStore(state => state.exhibitBuilderType)

  const getDownloadUrl = useCallback(
    (data: {
      caseId: string
      fileId: ExhibitFile["id"]
      asAttachment?: boolean
      startPage?: number
      endPage?: number
    }) => {
      if (exhibitBuilderType === EB_DOC_TYPE.MEDCHRON) {
        return exhibitBuilderService.getMedchronExhibitUrl({
          fileId: data.fileId,
          asAttachment: data.asAttachment,
          startPage: data.startPage,
          endPage: data.endPage,
        })
      }

      return exhibitBuilderService.getExhibitUrl({
        caseId: data.caseId,
        fileId: data.fileId,
        asAttachment: data.asAttachment,
        startPage: data.startPage,
        endPage: data.endPage,
      })
    },
    [exhibitBuilderType]
  )

  return {
    getDownloadUrl,
  }
}
