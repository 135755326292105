import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { HEADER_NAV_SCROLLTO_SPACE_MULTIPLIER } from "app/constants"
import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { DndVirtualListData } from "exhibit-builder/store/types"
import { PropsWithChildren, forwardRef, useEffect, useRef } from "react"
import { Components, Virtuoso, VirtuosoHandle } from "react-virtuoso"
import { useShallow } from "zustand/react/shallow"

// remove margin of the top level dnd list items and use padding instead
// react-virtuoso's height calculation doesn't work well with margin
const ListItemWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.25, 0),
  ['& [data-type="list-item"]:not([data-type="list-item-children"] [data-type="list-item"])']: {
    margin: 0,
  },
}))

const ListItem: Components["Item"] = forwardRef(function ListItem(props, ref) {
  return <ListItemWrapper {...props} ref={ref} />
})

export function VirtualList(props: PropsWithChildren<SortableNestedListItemProps<DndVirtualListData>>) {
  const virtuoso = useRef<VirtuosoHandle>(null)
  const scrollToIndex = useExhibitBuilderStore(useShallow(state => state.scrollToIndex))
  const theme = useTheme()
  const navOffset = -Number(theme.spacing(HEADER_NAV_SCROLLTO_SPACE_MULTIPLIER).replace("px", ""))

  useEffect(() => {
    if (scrollToIndex !== null && virtuoso.current) {
      virtuoso.current.scrollToIndex({
        index: scrollToIndex,
        align: "start",
        offset: navOffset,
      })
    }
  }, [scrollToIndex, navOffset])

  if (!Array.isArray(props.children)) {
    return null
  }

  const [items] = props.children

  return (
    <Virtuoso
      useWindowScroll
      ref={virtuoso}
      data={items}
      itemContent={index => items[index]}
      increaseViewportBy={1000}
      components={{ Item: ListItem }}
    />
  )
}
