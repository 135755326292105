import { useCallback, useEffect } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { documentsService } from "api/services/documents"
import { queryKeys } from "react-query/constants"
import { useNavigate } from "react-router-dom"
import { useDocumentContext } from "documents/context"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { ViewRequestSectionsId } from "requests/ViewRequest/ViewRequestRevamp/constants"
import { useDialog } from "hooks/useDialog"
import { StyledGenerateButton } from "../styled"
import { sendAnalytics } from "./utils"
import { DocumentGenerateButtonProps } from "./types"
import { SyncDocumentStructureModal } from "./components"

export function DocumentGenerateButton({
  requestId,
  requestStatus,
  text = "Generate Medical Chronology",
  redirectOnGenerate = true,
  extendedExhibits = false,
  isSyncDocumentStructureRequired,
  isSyncingDocumentStructure,
  disabled,
  onSyncDocumentStructure,
}: DocumentGenerateButtonProps) {
  const { documentId } = useDocumentContext()
  const navigate = useNavigate()
  const { showErrorMessage } = useHandleMessages()
  const queryClient = useQueryClient()

  const {
    isOpen: showSyncDocumentStructureDialog,
    openDialog: openSyncDocumentStructureDialog,
    closeDialog: closeSyncDocumentStructureDialog,
  } = useDialog()

  const {
    mutateAsync: generateDocument,
    data: documentData,
    isLoading: isGeneratingDocument,
  } = useMutation(documentsService.generateDocument)

  const handleDocumentGenerating = useCallback(async () => {
    if (requestId && requestStatus) sendAnalytics(documentId, requestId, requestStatus)

    generateDocument(
      { documentId, extendedExhibits },
      {
        onSuccess: () => {
          // force invalidation of the query to update the status
          queryClient.invalidateQueries([queryKeys.documentGenerations, documentId])
          closeSyncDocumentStructureDialog()
        },
        onError: error => {
          showErrorMessage({
            message: "There was an error generating a document.",
            error,
          })
        },
      }
    )
  }, [
    queryClient,
    documentId,
    generateDocument,
    extendedExhibits,
    requestId,
    requestStatus,
    showErrorMessage,
    closeSyncDocumentStructureDialog,
  ])

  const handleClick = useCallback(() => {
    if (isSyncDocumentStructureRequired) {
      openSyncDocumentStructureDialog()
      return
    }

    handleDocumentGenerating()
  }, [isSyncDocumentStructureRequired, handleDocumentGenerating, openSyncDocumentStructureDialog])

  useEffect(() => {
    if (!redirectOnGenerate) return

    if (documentData?.requestId) {
      navigate(
        `/requests/${documentData.requestId}/?scrollTo=${ViewRequestSectionsId.GeneratedMedicalChronology}`
      )
    }
  }, [documentData, navigate, redirectOnGenerate])

  // close dialog if the document structure is matching
  useEffect(() => {
    if (!isSyncDocumentStructureRequired) closeSyncDocumentStructureDialog()
  }, [isSyncDocumentStructureRequired, closeSyncDocumentStructureDialog])

  return (
    <>
      <StyledGenerateButton loading={isGeneratingDocument} onClick={handleClick} disabled={disabled}>
        {text}
      </StyledGenerateButton>

      <SyncDocumentStructureModal
        show={showSyncDocumentStructureDialog}
        onClose={closeSyncDocumentStructureDialog}
        onConfirm={onSyncDocumentStructure}
        onCancel={handleDocumentGenerating}
        isConfirmationLoading={isSyncingDocumentStructure}
        isCancelationLoading={isGeneratingDocument}
      />
    </>
  )
}
