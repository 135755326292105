import { ExhibitFile, ExhibitPartition, UserExhibit } from "exhibit-builder/store/types"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { usePublish } from "message-broker/usePublish"
import { useCallback } from "react"
import { PreviewUserExhibitPartitionPayload, PreviewUserExhibitPayload, Publish } from "message-broker/types"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { getUserExhibitPartitionPreviewPath, getUserExhibitPreviewPath } from "pdf-preview/utils"
import { openWindow } from "common/windows"

type UseUserExhibitPreviewReturn = (page?: Nullable<number>) => void

export function useUserExhibitPreview(userExhibitId: UserExhibit["id"]): UseUserExhibitPreviewReturn {
  const userExhibit = useExhibitBuilderStore(useShallow(filesSelectors.getUserExhibitById(userExhibitId)))
  const publish = usePublish()

  return useCallback(
    (page?: Nullable<number>) => {
      const payload: PreviewUserExhibitPayload = {
        userExhibitId: userExhibit?.id,
        fileName: userExhibit?.name,
        page: page,
      }

      publish(MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT, payload).catch(() => {
        const path = getUserExhibitPreviewPath(payload)
        if (userExhibit?.id && path) openWindow(`${path.base}/${path.route}`)
      })
    },
    [publish, userExhibit]
  )
}

export function previewExhibitPartition({
  partition,
  exhibitFile,
  page,
  publish,
}: {
  partition: ExhibitPartition
  exhibitFile: ExhibitFile
  page?: number
  publish: Publish
}) {
  const payload: PreviewUserExhibitPartitionPayload = {
    exhibitId: partition.fileId,
    partitionId: partition.id,
    fileName: exhibitFile.name,
    page: page || partition.startPage,
    pageNumberAdjustment: partition.startPage - 1,
  }

  publish(MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT_PARTITION, payload).catch(() => {
    const path = getUserExhibitPartitionPreviewPath(payload)
    if (path) openWindow(`${path.base}/${path.route}`)
  })
}

export function useExhibitPartitionPreview(partitionId: ExhibitPartition["id"], page?: number) {
  const partition = useExhibitBuilderStore(filesSelectors.getExhibitPartitionById(partitionId))
  const exhibitFile = useExhibitBuilderStore(filesSelectors.getExhibitFileById(partition.fileId))

  const publish = usePublish()

  return useCallback(() => {
    previewExhibitPartition({ partition, exhibitFile, page, publish })
  }, [partition, publish, exhibitFile, page])
}
