import { useEffect, useCallback } from "react"

import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"

import { makeStyles } from "tss-react/mui"
import { useNavigate, useSearchParams } from "react-router-dom"

import { useAuth } from "hooks/useAuth"
import { actions as userActions } from "user/store"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { parseError } from "common/errorUtils"

import { useAccountNotFoundStore } from "common/AccountNotFoundDialog"

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: "grid",
    placeItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  contact: {
    marginTop: theme.spacing(1),
  },
  dividerWrapper: {
    padding: theme.spacing(1, 0),
  },
  error: {
    fontFamily: "monospace",
    fontSize: "0.8rem",
    marginTop: theme.spacing(1),
  },
}))

const ErrorMessage = ({ message }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.alertContainer}>
      <Typography variant="h6" component="h1">
        Unable to log in!
      </Typography>
      <Typography variant="subtitle1" component="p">
        Verify that the correct email address was used and you have clicked on the most recent login email.
      </Typography>
      <Typography variant="subtitle2" className={classes.contact} component="p">
        Contact{" "}
        <Link href="mailto:support@evenuplaw.com" rel="noopener noreferrer" target="_top">
          support@evenuplaw.com
        </Link>{" "}
        if you continue to have issues.
      </Typography>
      {message && (
        <Box className={classes.errorContainer}>
          <Box className={classes.dividerWrapper}>
            <Divider />
          </Box>
          <Typography variant="body2">
            Provide the following error message when contacting support:{" "}
            <Box component="span" sx={{ display: "block" }} className={classes.error}>
              {message}
            </Box>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default function Authenticate() {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const auth = useAuth()
  const token = params.get("token")
  const oauth = params.get("oauth")
  const stytchTokenType = params.get("stytch_token_type")
  const path = params.get("path")
  const searchParams = params.get("search") ? window.atob(params.get("search")) : ""
  const { showErrorMessage } = useHandleMessages()
  const showAccountNotFoundDialog = useAccountNotFoundStore(state => state.setOpen)

  const redirectUponLogin = useCallback(() => {
    let redirectPath = "/"
    if (path) {
      redirectPath = path
    }

    if (searchParams) {
      redirectPath += searchParams
    }

    // avoid user from being sent back to change password after changing password
    if (redirectPath === "/settings/profile/change-password") {
      redirectPath = "/"
    }

    if (redirectPath === "/settings/profile/change-email") {
      redirectPath = "/"
    }

    return navigate(redirectPath)
  }, [navigate, path, searchParams])

  useEffect(() => {
    const loginUser = async () => {
      try {
        await auth.loginWithStytch({ token, oauth: !!oauth, stytchTokenType, redirectPath: path })
        await userActions.fetchUser()
        redirectUponLogin()
      } catch (error) {
        const parsedError = parseError(error)
        const errorMessage = parsedError?.userFriendlyMessage
        const statusCode = parsedError?.status

        if (statusCode === 404) {
          showAccountNotFoundDialog(true)
        } else {
          showErrorMessage({
            message: <ErrorMessage message={errorMessage} />,
            error,
          })
        }
        // send them back to login page when an error occurs
        let errRedirectPath = "/login"
        if (path) {
          errRedirectPath += `?redirectTo=${path}`
        }

        if (params.get("search")) {
          errRedirectPath += `&search=${params.get("search")}`
        }
        navigate(errRedirectPath)
      }
    }

    loginUser()
  }, [
    token,
    auth,
    oauth,
    showErrorMessage,
    redirectUponLogin,
    stytchTokenType,
    navigate,
    showAccountNotFoundDialog,
    params,
    path,
  ])

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <Typography>Logging you in...</Typography>
        <CircularProgress />
      </Box>
    </Box>
  )
}
