import { usePermissions } from "permissions/usePermissions"
import { useRequestContext } from "../../../context"

import { SelectDocument } from "requests/RequestForm/SelectDocument"

import { DOCUMENT_TYPES } from "requests/enums"
import { ProductType } from "common/interfaces"
import { useWatch } from "react-hook-form"

export const DocumentSelectStep = () => {
  const { firm } = useRequestContext()
  const { createBasicPlusEnabled, medicalChronologyPremiumEnabled, createSimpleEnabled } = usePermissions()
  const type = useWatch({ name: "type" })

  const availableContractTypes: ProductType[] = firm?.contract_types_available_for_client ?? []
  const documentTypePermissions: Record<DOCUMENT_TYPES, boolean> = {
    [DOCUMENT_TYPES.STANDARD]: true,
    [DOCUMENT_TYPES.BASIC_PLUS]: createBasicPlusEnabled,
    [DOCUMENT_TYPES.SIMPLE]: createSimpleEnabled,
    [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY]: true,
    [DOCUMENT_TYPES.PREMIUM_MEDCHRON]: medicalChronologyPremiumEnabled,
  }

  return (
    <SelectDocument
      selectedDocument={type}
      documentTypePermissions={documentTypePermissions}
      availableContractTypes={availableContractTypes}
    />
  )
}
