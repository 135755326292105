import React, {
  ReactNode,
  useEffect,
  // useMemo
} from "react"
import { useTimeSpendingAnalytic } from "infrastructure/timeSpending/hooks"
// import { useDocumentStore } from "documents/store"
import { documentStateActions } from "documents/store/documentState"
// import { usePageStore } from "common/pages/pageStore"
import { usePermissions } from "permissions/usePermissions"
import { SyncWithDocumentStructureButton } from "common/SyncWithDocumentStructureButton"
import { useParams } from "react-router-dom"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import { documentsService } from "api/services/documents"
import { useQuery } from "@tanstack/react-query"
import { DOCUMENT_TYPES } from "requests/enums"
import { MedchronExhibitBuilder } from "exhibit-builder/ExhibitBuilder"
import { useSyncWithDocumentStructure } from "hooks/useSyncWithDocumentStructure"

export const ExhibitManagement = React.memo(function DocumentExhibits(): ReactNode {
  const { medicalSummaryEnabled } = usePermissions()
  const { id: documentId } = useParams()

  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => {
      if (documentId) {
        return documentsService.getMedchronTile({ documentId: documentId })
      }
    },
    {
      ...SILENT_QUERY_PARAMS,
      enabled: !!documentId,
    }
  )

  useEffect(() => {
    documentStateActions.setTitle("Medical Chronology")
    documentStateActions.setShowExtraButtons(medicalSummaryEnabled)
    // usePageStore.setState({ showBack: true, backTitle: "Back", backHref: `/requests/${questionnaireId}` }) // TODO: Get from medchronTile once available from BE

    if (medchronTile) {
      documentStateActions.setTitleTags([
        medchronTile.questionnaireType === DOCUMENT_TYPES.PREMIUM_MEDCHRON ? "Premium" : "Standard",
      ])
    }

    return () => {
      documentStateActions.unsetTitle()
      documentStateActions.setTitleTags([])
    }
  }, [medicalSummaryEnabled, medchronTile])

  const {
    isSyncDocumentStructureRequired,
    isLoading: isSyncingDocumentStructure,
    handleSyncDocumentStructure,
  } = useSyncWithDocumentStructure({ documentId, fetchOnMount: !!documentId })

  useTimeSpendingAnalytic({ documentId })

  return (
    <>
      {documentId && (
        <>
          {isSyncDocumentStructureRequired && (
            <SyncWithDocumentStructureButton
              isLoading={isSyncingDocumentStructure}
              onSync={handleSyncDocumentStructure}
            />
          )}
          <MedchronExhibitBuilder documentId={documentId} />
        </>
      )}
      {/* <StyledButtonWrapper>
            <SavingIndicator />
            {medchronTile && (
              <DocumentGenerateButton
                requestId={questionnaireId}
                requestStatus={medchronTile.questionnaireStatus}
              />
            )}
          </StyledButtonWrapper> */}
    </>
  )
})
