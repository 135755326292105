import styled from "@emotion/styled"
import { Chip, IconButton, inputBaseClasses, outlinedInputClasses, selectClasses } from "@mui/material"
import { EditableText } from "evenup-ui/EditableText"
import { blue } from "@mui/material/colors"

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 0),
}))

export const ExhibitNameContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  flex: 1,
}))

export const ExhibitName = styled("div")({
  fontWeight: "bold",
})

export const FileName = styled(EditableText)({
  fontWeight: 500,
})

export const ExhibitNumber = styled("div")({
  fontWeight: "bold",
})

export const Dropdowns = styled("div")<{ oneRow: boolean }>(({ theme, oneRow }) => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: oneRow ? "1fr" : "1fr 1fr",
  gridAutoFlow: oneRow ? "column" : "row",
  gridAutoColumns: "1fr",
  alignItems: "center",
  gap: theme.spacing(1, 2),
  padding: theme.spacing(1.5, 16, 0, 0),
  borderTop: `1px solid ${theme.palette.divider}`,
}))

export const DropdownContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  maxWidth: "400px",
  [`& .${selectClasses.select}`]: {
    padding: 0,
    fontSize: "14px",
  },

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: "none",
  },

  [`& .${inputBaseClasses.readOnly}`]: {
    [`.${selectClasses.select}`]: {
      cursor: "default",
    },
    [`.${selectClasses.icon}`]: {
      display: "none",
    },
  },
})

export const DropdownLabel = styled.div(({ theme }) => ({
  fontWeight: 700,
  flexShrink: 0,
  marginRight: theme.spacing(1),
}))

export const ExhibitProviderContainer = styled.div({
  maxWidth: "100%",

  [`& .${outlinedInputClasses.root}`]: {
    height: "24px",
    padding: 0,
    fontSize: "0.875rem",
  },
})

export const ExhibitProviderDisplay = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: theme.typography.fontFamily,
  gap: theme.spacing(0.5),
}))

export const CollapseButton = styled(IconButton)({
  transition: "transform 0.3s",
})

export const Tag = styled(Chip)<{ tagColor: string }>(({ theme, tagColor }) => ({
  position: "absolute",
  right: 0,
  top: theme.spacing(1.5),
  borderRadius: theme.spacing(1),
  height: "24px",
  fontWeight: 500,
  color: tagColor,
  borderColor: tagColor,
}))

export const UserExhibitItemContainer = styled("div")<{ isHighlighted?: boolean }>(
  ({ theme, isHighlighted }) => ({
    "& > div > div > [data-type='list-item']": {
      backgroundColor: "#FBFCFE",
      borderColor: "#CEDAFF",
      borderRadius: theme.spacing(0.5),
      transition: "0.3s",
      boxShadow: isHighlighted ? `0 0 1px 1px ${blue[600]}` : "none",
      padding: theme.spacing(1.5, 3),
    },

    "& [data-type='drag-indicator']": {
      transform: "translateX(-6px)",

      "& svg": {
        height: "32px",
      },
    },
  })
)
