import { useMemo } from "react"
import { useProviderSummariesStore } from "./store"
import { Typography } from "@mui/material"
import { CitationsContainer } from "./styled"

export function CitationsTab({ dateOfService }: { dateOfService: string }) {
  const citations = useProviderSummariesStore(
    state => state.medicalSummaries.get(dateOfService)?.summaryCitations
  )
  const valuableCitations = useMemo(() => {
    if (!citations) return []
    return citations.filter(citation => !!citation.values.length)
  }, [citations])

  if (!valuableCitations.length) return null

  return (
    <CitationsContainer>
      {valuableCitations.map(citation => (
        <div key={citation.header}>
          <Typography variant="helper" sx={{ fontWeight: "bold" }}>
            {citation.header}
          </Typography>
          <ul>
            {citation.values.map((value, idx) => (
              <li key={`${value.text}_${idx}`}>
                <Typography variant="helper">{value.text}</Typography>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </CitationsContainer>
  )
}
