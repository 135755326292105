import { CollapseButton } from "common/CollapseManager/CollapseButton"
import { CollapseButtonContainer } from "../styled"
import { useCallback } from "react"
import { useCollapseStore } from "./collapse"

export function CollapseButtons() {
  const setCollapseStateForAll = useCollapseStore(state => state.setCollapseStateForAll)

  const handleClick = useCallback(
    (collapse: boolean) => {
      setCollapseStateForAll(collapse)
    },
    [setCollapseStateForAll]
  )

  return (
    <CollapseButtonContainer>
      <CollapseButton onClick={handleClick} />
    </CollapseButtonContainer>
  )
}
