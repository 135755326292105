import { TableCell, TableRow } from "@mui/material"
import { getDocumentStatusCell } from "./helpers"
import { DocumentGenerationEntity } from "api/services/documents/types"
import { GeneratedDocumentsDownloadCell } from "./GeneratedDocumentsDownloadCell"

interface GeneratedDocumentsRowProps {
  documentGeneration: DocumentGenerationEntity
  documentId: string
  requestId: PrimaryKey
}

export const GeneratedDocumentsRow = ({
  documentGeneration,
  documentId,
  requestId,
}: GeneratedDocumentsRowProps) => {
  return (
    <TableRow key={documentGeneration.id}>
      <TableCell>{documentGeneration.started}</TableCell>
      <TableCell>{documentGeneration.completed}</TableCell>
      <TableCell>{getDocumentStatusCell(documentGeneration)}</TableCell>
      <TableCell width="10%" align="center">
        <GeneratedDocumentsDownloadCell
          documentId={documentId}
          requestId={requestId}
          documentGeneration={documentGeneration}
        />
      </TableCell>
    </TableRow>
  )
}
