import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { StyledButton } from "../styled"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import { Box, Dialog, Typography } from "@mui/material"
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded"
import Button from "evenup-ui/Button"
import { cloneElement, isValidElement, ReactElement, ReactNode, useState } from "react"
import { useAction, ActionButton } from "../../ActionButton"
import { exhibitBuilderService } from "exhibit-builder/api/service"

export function Reset({ component }: { component?: ReactNode }) {
  const [showDialog, setShowDialog] = useState(false)
  const documentId = useExhibitBuilderStore(state => state.document.documentId)
  const type = useExhibitBuilderStore(state => state.exhibitBuilderType)

  const closeDialog = () => {
    setShowDialog(false)
  }

  const { handleAction: resetChanges, isLoading } = useAction({
    name: "reset all changes",
    action: async () => {
      await exhibitBuilderService.resetChanges({ documentId })
      closeDialog()
      return exhibitBuilderActions.initialize({ documentId, type })
    },
  })

  return (
    <>
      {component && isValidElement(component) ? (
        <>{cloneElement(component as ReactElement, { onClick: () => setShowDialog(true) })}</>
      ) : (
        <StyledButton
          startIcon={<CachedOutlinedIcon />}
          onClick={() => setShowDialog(true)}
          data-test="reset-button"
        >
          Reset All Changes
        </StyledButton>
      )}
      <Dialog open={showDialog} onClose={closeDialog}>
        <Box padding={3} data-test="dialog-content">
          <Box display="flex" alignItems="center" gap={1.5} mb={3.5}>
            <WarningAmberRoundedIcon sx={{ fontSize: "30px" }} color="error" />
            <Typography variant="h2">Reset all Exhibits</Typography>
          </Box>

          <Box>
            <Typography variant="body1">
              Please note, this action will revert all exhibit and file manipulations (extracts, deletes,
              combines) back to its original supporting files. All changes will be permanently lost.
              Annotation data will remain unchanged.
            </Typography>
          </Box>

          <Box display="flex" gap={1} justifyContent="end" mt={3.5}>
            <Button color="secondary" onClick={closeDialog} data-test="cancel-button">
              Cancel
            </Button>
            <ActionButton
              label="Confirm"
              onClick={resetChanges}
              isLoading={isLoading}
              data-test="confirm-button"
            />
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
