import { useCallback, useEffect } from "react"

import { useDialog } from "hooks/useDialog"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { PROVIDER_KEY } from "exhibit-builder/store/constants"

// TODO: get this value from existed constants
export const RECORDS_AND_BILLS_SUB_DOC_TYPE = "records_and_bills"

export const useUnhandledExhibits = () => {
  const {
    isOpen: isOpenUnhandledExhibitsBanner,
    openDialog: openUnhandledExhibitsBanner,
    closeDialog: closeUnhandledExhibitsBanner,
  } = useDialog()
  const {
    isOpen: isFilteringUnhandledExhibits,
    toggleDialog: toggleFilteringUnhandledExhibits,
    closeDialog: closeFilteringUnhandledExhibits,
  } = useDialog()

  const handleCloseUnhandledExhibitsBanner = useCallback(() => {
    closeUnhandledExhibitsBanner()
    closeFilteringUnhandledExhibits()
  }, [closeUnhandledExhibitsBanner, closeFilteringUnhandledExhibits])

  const unhandledExhibitsBannerActionText = isFilteringUnhandledExhibits
    ? "Click here to clear filter"
    : "Click here to filter"

  const unhandledUserExhibits = useExhibitBuilderStore(state => {
    const exhibits = Object.values(state.userExhibitMap)
      .filter(
        ({ docType, subDocType }) => docType === PROVIDER_KEY && subDocType === RECORDS_AND_BILLS_SUB_DOC_TYPE
      )
      .map(({ id }) => id)

    return exhibits
  })

  useEffect(() => {
    if (unhandledUserExhibits.length) {
      openUnhandledExhibitsBanner()
    } else {
      handleCloseUnhandledExhibitsBanner()
    }
  }, [unhandledUserExhibits.length, openUnhandledExhibitsBanner, handleCloseUnhandledExhibitsBanner])

  useEffect(() => {
    if (isFilteringUnhandledExhibits) {
      exhibitBuilderActions.selectSubDocTypes([RECORDS_AND_BILLS_SUB_DOC_TYPE])
    } else {
      exhibitBuilderActions.selectSubDocTypes([])
    }

    return () => {
      exhibitBuilderActions.selectSubDocTypes([])
    }
  }, [isFilteringUnhandledExhibits])

  return {
    isOpenUnhandledExhibitsBanner,
    handleCloseUnhandledExhibitsBanner,
    unhandledUserExhibits,
    handleFilterUnhandledExhibits: toggleFilteringUnhandledExhibits,
    unhandledExhibitsBannerActionText,
  }
}
