import { Alert } from "@mui/material"
import styled from "@emotion/styled"
import TextButton from "common/buttons/TextButton"

export const ActionButton = styled(TextButton)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  marginLeft: theme.spacing(1),
}))

interface UnhandledExhibitsBannerProps {
  exhibitsCount: number
  exhibitsType?: string
  actionText: string
  onAction: () => void
  closeText?: string
  onClose: () => void
}

export const UnhandledExhibitsBanner = ({
  exhibitsCount,
  exhibitsType = "Records & Bills",
  actionText = "Click here to filter",
  onAction,
  closeText,
  onClose,
}: UnhandledExhibitsBannerProps) => {
  return (
    <Alert
      severity="info"
      sx={{ width: "100%" }}
      variant="outlined"
      closeText={closeText}
      onClose={onClose}
      data-test="unhandled-exhibits-banner"
    >
      <div>
        {exhibitsCount} sub-doc type under &#39;{exhibitsType}&#39; found. Please note that these will be
        sorted last in the sequence.
        <ActionButton onClick={onAction}>{actionText}</ActionButton>
      </div>
    </Alert>
  )
}
