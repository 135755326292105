import { Dispatch, SetStateAction } from "react"
import { FILE_CATEGORY_OPTIONS } from "common/constants"
import { FieldValues, UseFieldArrayReturn } from "react-hook-form"
import { ExtendedFirmDto } from "settings/Firm/Firm"
import { STEP_CODES } from "./constants"
import { RequestViewDto } from "requests/ViewRequest/types"
import { DOCUMENT_TYPES } from "requests/enums"

export enum FileStatus {
  "Processing" = "Processing", // We're figuring out what to do with it
  // Unzipping
  "Unzipping" = "Unzipping",
  "UnzippingComplete" = "UnzippingComplete",
  "UnzippingError" = "UnzippingError",

  "ReadyToUpload" = "ReadyToUpload", // Enqueued for Upload
  "Uploading" = "Uploading", // Trying to upload it
  "UploadError" = "UploadError", // There was an error trying to upload it
  "UploadComplete" = "UploadComplete", // Successfully uploaded!
}

export type RequestFileToUpload = {
  file: File
  fileType: FILE_CATEGORY_OPTIONS
  status: FileStatus
  error: string
}

export interface AdjusterAddressProps {
  city: string
  pk: number
  state: string
  street: string
  street_2: string
  zip_code: string
}

export interface RequestFields extends FieldValues, RequestViewDto {}

export interface RequestFormContextValues {
  activeStep: STEP_CODES
  setActiveStep: Dispatch<SetStateAction<STEP_CODES>>
  requestId?: number
  firm: Nullable<ExtendedFirmDto>
  firmsOptions: ValueOptions<number>
  uploadingFiles: boolean
  setUploadingFiles: Dispatch<SetStateAction<boolean>>
  totalPages: number
  canEdit: boolean
  // Note: fields only contains the defaultValues and key
  // https://react-hook-form.com/docs/usefieldarray
  plaintiffsArrayProps: UseFieldArrayReturn<RequestViewDto, "plaintiffs">
  handleDocumentSelect: (documentType: DOCUMENT_TYPES) => void
}

export interface MutationSaveFile {
  requestId: number | undefined
  data: FormData
  name: string
}
