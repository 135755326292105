import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import Tooltip from "@mui/material/Tooltip"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import Box from "@mui/material/Box"
import HelpOutline from "@mui/icons-material/HelpOutline"
import { makeStyles } from "tss-react/mui"
import {
  UPDATE_INCLUDE_TABLE,
  UPDATE_ICD_CODES,
  UPDATE_CPT_CODES,
  Action,
} from "demand/Providers/store/reducer"
import { CaseIcdCode, CaseCptCode, Provider, IcdCodeFields } from "demand/Providers/types"

import { SectionHeading } from "../../styled"
import { useProviderVariables } from "../../useProviderVariables"
import { useCaseVariables } from "demand/Variables"
import { ProviderFormEditor } from "../ProviderFormEditor"

import IcdCodes from "../../Annotation/IcdCodes/IcdCodes"
import { PartitionEntry } from "../../Annotation/types"
import InjuryDetails from "../../Annotation/InjuryDetails/InjuryDetails"
import { UpdatedPassage } from "../../providerUpdatesUtils"
import SectionContainer from "../../SectionContainer"
import { LeftNavContext } from "demand/context"
import { CodesInput } from "./CodesInput"
import { CODE_TYPES } from "./constants"
import SectionGridContainer from "../../SectionGridContainer"
import { INJURY_DETAIL_RECORD_MAX_HEIGHT } from "../../Annotation/InjuryDetails/styled"
import { SLATE_EDITOR_DATA_TYPE_ATTRIBUTE } from "common/form-components/rich-text/constants"
import { Summaries } from "../../Summaries/Summaries"
import { usePermissions } from "permissions/usePermissions"

interface Props {
  disabled: boolean
  provider: Provider
  caseId: PrimaryKey
  dispatch: React.Dispatch<Action>
  annotationDateUpdated: Nullable<string>
  onAnnotationClick: (entry: PartitionEntry) => void
  onBlur: () => void
  updatedPassages: UpdatedPassage[]
}

interface StyleProps {
  leftNavTabIsExpanded: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, { leftNavTabIsExpanded }) => ({
  helpIcon: {
    fontSize: "1rem",
    verticalAlign: "text-bottom",
  },
  codeInputs: {
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    flexDirection: leftNavTabIsExpanded ? "row" : "column",
    width: leftNavTabIsExpanded ? "100%" : "50%",
  },
  inputLabelText: {
    fontSize: "13px",
    fontWeight: 600,
  },
  wordBreakAll: {
    wordBreak: "break-word",
  },
}))

export const InjuryDetailsSection = ({
  provider,
  disabled,
  dispatch,
  caseId,
  annotationDateUpdated = "",
  onAnnotationClick,
  updatedPassages,
  onBlur,
}: Props): JSX.Element => {
  const { leftNavTabIsExpanded } = useContext(LeftNavContext)
  const { classes } = useStyles({ leftNavTabIsExpanded })
  const editorFormRef = useRef<Nullable<HTMLDivElement>>(null)
  const [maxHeight, setMaxHeight] = useState(INJURY_DETAIL_RECORD_MAX_HEIGHT)
  const hasPartitionProvider = !!provider.partition_provider

  const { enableZeroClickSummariesEnabled } = usePermissions()

  const icdCodes = provider.icd_codes ?? []
  const cptCodes = provider.cpt_codes ?? []

  const handleChangeIncludeTable = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, checked: boolean) => {
      dispatch({
        type: UPDATE_INCLUDE_TABLE,
        payload: { providerId: provider.pk, includeTable: checked },
      })
    },
    [provider, dispatch]
  )

  const handleChangeIcdCodes = useCallback(
    (value: CaseIcdCode[]) => {
      dispatch({
        type: UPDATE_ICD_CODES,
        payload: { providerId: provider.pk, icdCodes: value },
      })
    },
    [provider, dispatch]
  )

  const handleChangeCptCodes = useCallback(
    (value: CaseCptCode[]) => {
      dispatch({
        type: UPDATE_CPT_CODES,
        payload: { providerId: provider.pk, cptCodes: value },
      })
    },
    [provider, dispatch]
  )

  const label = useMemo(
    () => (
      <>
        Include provider details table{" "}
        <Tooltip placement="top" arrow title="Adds a table with all provider details in the generated demand">
          <HelpOutline className={classes.helpIcon} />
        </Tooltip>
      </>
    ),
    [classes.helpIcon]
  )

  const providerVariables = useProviderVariables(provider)
  const { variables } = useCaseVariables(providerVariables)

  useEffect(() => {
    if (editorFormRef.current) {
      const editoWrapper = editorFormRef.current.querySelector(
        `[data-type=${SLATE_EDITOR_DATA_TYPE_ATTRIBUTE}]`
      )

      if (editoWrapper) {
        setMaxHeight(editoWrapper.clientHeight)
      }
    }
  }, [provider.details_json])

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <SectionHeading>Injury Details from Provider</SectionHeading>
        <Box justifyContent="right">
          <FormControlLabel
            control={<Switch color="secondary" />}
            label={label}
            checked={provider.include_table}
            onChange={handleChangeIncludeTable}
            disabled={disabled}
          />
        </Box>
      </Box>
      <SectionContainer>
        <Box className={classes.codeInputs}>
          <CodesInput
            codes={icdCodes}
            type={CODE_TYPES.ICD}
            onChange={handleChangeIcdCodes}
            disabled={disabled}
          />
          <CodesInput
            codes={cptCodes}
            type={CODE_TYPES.CPT}
            onChange={handleChangeCptCodes}
            disabled={disabled}
          />
        </Box>
        {hasPartitionProvider && (
          <Box>
            <IcdCodes
              caseId={caseId}
              providerId={provider.pk}
              currentIcdCodes={icdCodes as IcdCodeFields[]}
              onEntryClick={onAnnotationClick}
              dateUpdated={annotationDateUpdated ?? ""}
              dispatch={dispatch}
              allowImport
            />
          </Box>
        )}
      </SectionContainer>

      <SectionGridContainer>
        <Box className={classes.wordBreakAll}>
          <div ref={editorFormRef}>
            <ProviderFormEditor
              disabled={disabled}
              caseId={caseId}
              onBlur={onBlur}
              variables={variables}
              dispatch={dispatch}
              provider={provider}
            />
          </div>
        </Box>

        {hasPartitionProvider && enableZeroClickSummariesEnabled && (
          <Box width="100%" minWidth="470px">
            <Summaries />
          </Box>
        )}
        {hasPartitionProvider && !enableZeroClickSummariesEnabled && (
          <Box className={classes.wordBreakAll}>
            <InjuryDetails
              maxHeight={maxHeight}
              caseId={caseId}
              providerId={provider.pk}
              providerName={provider.name}
              onEntryClick={onAnnotationClick}
              updatedPassages={updatedPassages}
              generatorKeys={provider.templated_sections?.[0]?.template?.possible_generator_keys}
            />
          </Box>
        )}
      </SectionGridContainer>
      <Box mb={3} />
    </>
  )
}
