import { create } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"
import { formatDate } from "utils"
import { ExtractState } from "common/types/zustand"
import {
  Appointment,
  Exhibit,
  IcdCode,
  Provider,
  IcdCodeRelation,
  Partition,
  APPOINTMENT_TAG_ACTIONS,
} from "documents/types"
import { documentDataActions, documentDataSlice } from "./document"
import { exhibitActions, exhibitSelectors, exhibitsSlice } from "./exhibits"
import { providersActions, providersSelectors, providersSlice } from "./providers"
import { appointmentsActions, appointmentsSelectors, appointmentsSlice } from "./appointments"
import { partitionsSelectors, partitionsSlice } from "./partitions"
import { filtersDataSlice, filtersDataActions } from "./filters"
import { flagsDataSlice, flagsDataActions, flagsDataSelectors } from "./flags"
import { icdCodeActions, icdCodesSlice } from "./icdCodes"
import { userActions, userSlice, userSelectors } from "./user"
import { omit, without, isEmpty, isNil, pick, groupBy, flatMap } from "lodash"
import {
  DocumentData,
  GetState,
  MED_SUMMARY_REVIEW_ACTIONS,
  MED_SUMMARY_SORTING,
  MedicronCalendarData,
  SetState,
  StoreICDCode,
  SummaryItemKey,
} from "./types"
import { documentStateActions } from "./documentState"
import { documentsService } from "api/services/documents"
import { getRanges } from "common/utils"
import { TreatmentTimelineEntity } from "documents/view-medical-chronology/components/treatment-timeline/types"
import { transformAppointments } from "documents/exhibits/MedicronCalendar/medicronCalendarUtilities"
import {
  MAX_APPOINTMENTS_LIMIT,
  MAX_MONTH_LIMIT,
  MAX_YEARS_LIMIT,
} from "documents/view-medical-chronology/components/treatment-timeline/constants"
import {
  getTreatmentTimelineEntitiesForDays,
  getTreatmentTimelineEntitiesForMonths,
  getTreatmentTimelineEntitiesForYears,
} from "documents/view-medical-chronology/components/treatment-timeline/utils"

const sortAppointmentsByDOS = (a: Appointment, b: Appointment) => {
  const compareDates = new Date(a.dateOfService).valueOf() - new Date(b.dateOfService).valueOf()

  if (compareDates !== 0) return compareDates

  if (isNil(a.timeOfService) && b.timeOfService) return -1
  if (isNil(b.timeOfService) && a.timeOfService) return 1
  if (isNil(b.timeOfService) && isNil(a.timeOfService)) return 0

  return new Date(`1 Jan ${a.timeOfService}`).valueOf() - new Date(`1 Jan ${b.timeOfService}`).valueOf()
}

export const useDocumentStore = create(
  subscribeWithSelector(() => ({
    ...documentDataSlice,
    ...exhibitsSlice,
    ...providersSlice,
    ...appointmentsSlice,
    ...partitionsSlice,
    ...icdCodesSlice,
    ...filtersDataSlice,
    ...flagsDataSlice,
    ...userSlice,
  }))
)

type DocumentStore = ExtractState<typeof useDocumentStore>

export const setDocumentData = (data: DocumentData) => useDocumentStore.setState(data)
export const resetDocumentData = () => useDocumentStore.setState(useDocumentStore.getInitialState(), true)

const reviewActionFilterHandler = ({
  reviewActionFilter,
  state,
  userId,
}: {
  reviewActionFilter: MED_SUMMARY_REVIEW_ACTIONS
  state: DocumentStore
  userId?: number
}) => {
  return (appointmentId: string) => {
    // Get tags for display because that will ensure there are single entities
    // of complete/incomplete tags per user so that logic does not need to be repeated here
    const tags = appointmentsSelectors.getAppointmentTagsForDisplay(appointmentId)(state)
    // incomplete (not complete)
    if (reviewActionFilter === MED_SUMMARY_REVIEW_ACTIONS.INCOMPLETE) {
      // if there are no tags that are marked_completed OR marked_reviewed
      return !tags.some(
        tag =>
          tag.action === APPOINTMENT_TAG_ACTIONS.MARK_COMPLETED ||
          tag.action === APPOINTMENT_TAG_ACTIONS.MARK_REVIEWED
      )
      // completed - ie: needs review
    } else if (reviewActionFilter === MED_SUMMARY_REVIEW_ACTIONS.COMPLETE) {
      const reviewedTags = tags.filter(tag => tag.action === APPOINTMENT_TAG_ACTIONS.MARK_REVIEWED)
      const completedTags = tags.filter(tag => tag.action === APPOINTMENT_TAG_ACTIONS.MARK_COMPLETED)
      return reviewedTags.length === 0 && completedTags.length > 0
      // reviewed
    } else if (reviewActionFilter === MED_SUMMARY_REVIEW_ACTIONS.REVIEWED) {
      return tags.some(tag => tag.action === APPOINTMENT_TAG_ACTIONS.MARK_REVIEWED)
      // pending final review (formally known as "Not Reviewed By Me")
    } else if (reviewActionFilter === MED_SUMMARY_REVIEW_ACTIONS.PENDING_FINAL_REVIEW) {
      const reviewedTags = tags.filter(tag => tag.action === APPOINTMENT_TAG_ACTIONS.MARK_REVIEWED)
      const userTag = reviewedTags.find(tag => tag.createdBy.pk === userId)

      // return true:
      // if there are review tags however, none of them are for the current user
      // or if there are no review tags
      return (reviewedTags.length && !userTag) || !reviewedTags.length
    }
  }
}

export const documentStoreSelectors = {
  exhibitAppointments: (id: Exhibit["id"]) => (state: DocumentStore) => {
    const exhibit = exhibitSelectors.getExhibitById(id)(state)
    const partitions = exhibit.partitionIds.map(partitionId =>
      partitionsSelectors.getPartitionById(partitionId)(state)
    )
    const appointmentIds = partitions.map(partition => partition.appointmentIds).flat()
    const appointments = appointmentIds.map(appointmentId =>
      appointmentsSelectors.getAppointmentById(appointmentId)(state)
    )

    return appointments
  },
  exhibitAppointmentsCount: (id: Exhibit["id"]) => (state: DocumentStore) => {
    return documentStoreSelectors.exhibitAppointments(id)(state).length
  },
  exhibitDates: (id: Exhibit["id"]) => (state: DocumentStore) => {
    const appointments = documentStoreSelectors.exhibitAppointments(id)(state)

    if (!appointments.length) return null

    const dates = appointments
      .map(appointment => new Date(appointment.dateOfService))
      .sort((a, b) => a.valueOf() - b.valueOf())

    const firstDate = formatDate(dates[0], "MM/dd/yyyy", true)
    const lastDate = formatDate(dates[dates.length - 1], "MM/dd/yyyy", true)

    return [firstDate, lastDate]
  },
  appointmentIdsByProvider: (id: Provider["id"]) => (state: DocumentStore) => {
    const appointmentIds = Object.values(state.appointments)
      .filter(appointment => appointment.providerId === id)
      .sort((a, b) => new Date(a.dateOfService).valueOf() - new Date(b.dateOfService).valueOf())
      .map(({ id }) => id)

    return appointmentIds
  },
  providerByAppointmentId: (id: Appointment["id"]) => (state: DocumentStore) => {
    const appointment = state.appointments[id]

    if (!appointment) return null

    const providerId = appointment.providerId

    if (!providerId) return null

    return providersSelectors.getProviderById(providerId)(state)
  },
  providersDataByAppointmentIds: (ids: Appointment["id"][]) => (state: DocumentStore) => {
    const prodiversData: (Provider & {
      appointmentId: Appointment["id"]
      timeOfService: Appointment["timeOfService"]
    })[] = []

    ids.forEach(id => {
      const provider = documentStoreSelectors.providerByAppointmentId(id)(state)
      const appointment = state.appointments[id]

      if (provider)
        prodiversData.push({ ...provider, appointmentId: id, timeOfService: appointment.timeOfService })
    })

    const result = groupBy(prodiversData, entity => entity.id)

    Object.values(result).forEach(data =>
      data.sort((a, b) => {
        if (isNil(a.timeOfService) && b.timeOfService) return -1
        if (isNil(b.timeOfService) && a.timeOfService) return 1
        if (isNil(b.timeOfService) && isNil(a.timeOfService)) return 0

        return new Date(`1 Jan ${a.timeOfService}`).valueOf() - new Date(`1 Jan ${b.timeOfService}`).valueOf()
      })
    )

    return flatMap(result)
  },
  isFiltersEmpty: (state: DocumentStore) => {
    return (
      isNil(state.filtersEndDate) &&
      isNil(state.filtersStartDate) &&
      isEmpty(state.filtersProviderIds) &&
      isNil(state.filtersReviewAction)
    )
  },
  numberOfAppointments: (id: Provider["id"]) => (state: DocumentStore) => {
    const totalNumber = documentStoreSelectors.appointmentIdsByProvider(id)(state).length

    if (documentStoreSelectors.isFiltersEmpty(state)) {
      return String(totalNumber)
    }

    const filteredNumber = documentStoreSelectors.filteredAppointmentIdsByProvider(id)(state).length

    return `${filteredNumber}/${totalNumber}`
  },
  getProviders:
    (filterEmptyProviders = false) =>
    (state: DocumentStore) => {
      const providerIds = documentStoreSelectors.getSortedProviderIdsByDOS(state)
      const providers = providerIds.map(id => state.providers[id])

      if (filterEmptyProviders) {
        return providers.filter(
          provider => documentStoreSelectors.appointmentIdsByProvider(provider.id)(state).length
        )
      }

      return providers
    },
  filteredAppointmentIdsByProvider: (id: Provider["id"]) => (state: DocumentStore) => {
    const startDate = state.filtersStartDate && new Date(state.filtersStartDate)
    const endDate = state.filtersEndDate && new Date(state.filtersEndDate)
    const reviewActionFilter = state.filtersReviewAction
    const userId = userSelectors.getUser()(state)?.id

    let appointmentIds = documentStoreSelectors.appointmentIdsByProvider(id)(state)

    if (startDate) {
      appointmentIds = appointmentIds.filter(appointmentId => {
        const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(state)

        return new Date(appointment.dateOfService) >= startDate
      })
    }

    if (endDate) {
      appointmentIds = appointmentIds.filter(appointmentId => {
        const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(state)

        return new Date(appointment.dateOfService) <= endDate
      })
    }

    if (reviewActionFilter) {
      appointmentIds = appointmentIds.filter(reviewActionFilterHandler({ reviewActionFilter, state, userId }))
    }

    return appointmentIds
  },
  providerDates: (id: Provider["id"]) => (state: DocumentStore) => {
    const appointmentIds = documentStoreSelectors.appointmentIdsByProvider(id)(state)

    if (!appointmentIds.length) return null

    const appointments = appointmentIds.map(appointmentId =>
      appointmentsSelectors.getAppointmentById(appointmentId)(state)
    )
    const dates = appointments
      .map(appointment => new Date(appointment.dateOfService))
      .sort((a, b) => a.valueOf() - b.valueOf())

    const firstDate = formatDate(dates[0], "MM/dd/yyyy", true)
    const lastDate = formatDate(dates[dates.length - 1], "MM/dd/yyyy", true)

    return [firstDate, lastDate]
  },
  getSortedProviderIdsByDOS: (state: DocumentStore): string[] => {
    const providerIds = state.providersOrder

    return providerIds
      .map(
        providerId =>
          documentStoreSelectors
            .appointmentIdsByProvider(providerId)(state)
            .map(appointmentId => appointmentsSelectors.getAppointmentById(appointmentId)(state))
            .sort(sortAppointmentsByDOS)[0]
      )
      .filter(Boolean)
      .sort(sortAppointmentsByDOS)
      .map(({ providerId }) => providerId)
      .filter(Boolean) as string[]
  },
  getFilteredProviderIds: (state: DocumentStore): string[] => {
    const providerIds = documentStoreSelectors.getSortedProviderIdsByDOS(state)

    if (state.filtersProviderIds?.length) {
      return providerIds.filter(id => state.filtersProviderIds?.includes(id))
    }

    return providerIds
  },
  getAppointmentMedicronData: (state: DocumentStore): MedicronCalendarData => {
    const result: MedicronCalendarData = {
      appointments: {},
      providers: {},
      dayOfIncident: new Date(state.dateOfIncident),
      plaintiff: `${state.plaintiff}`,
    }
    const providerIds = documentStoreSelectors.getSortedProviderIdsByDOS(state)

    if (state.filtersProviderIds?.length) {
      providerIds
        .filter(id => state.filtersProviderIds?.includes(id))
        .forEach(id => {
          const totalNumber = documentStoreSelectors.appointmentIdsByProvider(id)(state).length

          if (totalNumber) {
            result.providers[id] = state.providers[id]
          }
        })
    } else {
      providerIds.forEach(id => {
        const totalNumber = documentStoreSelectors.appointmentIdsByProvider(id)(state).length

        if (totalNumber) {
          result.providers[id] = state.providers[id]
        }
      })
    }

    Object.keys(result.providers).forEach(id => {
      const filteredAppointmentIds = documentStoreSelectors.filteredAppointmentIdsByProvider(id)(state)

      filteredAppointmentIds.forEach(appointmentId => {
        result.appointments[appointmentId] = state.appointments[appointmentId]
      })
    })

    return result
  },
  getTreatmentTimelineEntities: (state: DocumentStore): TreatmentTimelineEntity[] => {
    const medicronData = documentStoreSelectors.getAppointmentMedicronData(state)
    const groupedAppointments = transformAppointments(medicronData.appointments, medicronData.providers)
    const numberOfAppointments = Object.keys(medicronData.appointments).length

    if (!numberOfAppointments) return []

    const numberOfMonth = Object.values(groupedAppointments).reduce(
      (acc, cur) => acc + Object.values(cur).filter(Boolean).length,
      0
    )
    const numberOfYears = Object.values(groupedAppointments).length

    if (numberOfYears <= MAX_YEARS_LIMIT && numberOfAppointments <= MAX_APPOINTMENTS_LIMIT) {
      return getTreatmentTimelineEntitiesForDays(groupedAppointments, medicronData)
    }

    if (numberOfMonth > MAX_MONTH_LIMIT) {
      return getTreatmentTimelineEntitiesForYears(groupedAppointments, medicronData)
    }

    return getTreatmentTimelineEntitiesForMonths(groupedAppointments, medicronData)
  },
  getFilteredProvider: (state: DocumentStore): Provider[] => {
    const providerIds = documentStoreSelectors.getSortedProviderIdsByDOS(state)

    if (state.filtersProviderIds?.length) {
      return providerIds
        .filter(id => state.filtersProviderIds?.includes(id))
        .map(providerId => state.providers[providerId])
    }

    return providerIds
      .map(providerId => state.providers[providerId])
      .filter(provider => documentStoreSelectors.appointmentIdsByProvider(provider.id)(state).length)
  },
  getSummaryItemKeys:
    (removeEmptyProviders: boolean = true) =>
    (state: DocumentStore): SummaryItemKey[] => {
      switch (state.sorting) {
        case MED_SUMMARY_SORTING.DATE: {
          let filteredAppointments = Object.values(state.appointments)

          const startDate = state.filtersStartDate && new Date(state.filtersStartDate)
          const endDate = state.filtersEndDate && new Date(state.filtersEndDate)
          const reviewActionFilter = state.filtersReviewAction
          const userId = userSelectors.getUser()(state)?.id

          if (startDate) {
            filteredAppointments = filteredAppointments.filter(
              appointment => new Date(appointment.dateOfService) >= startDate
            )
          }

          if (endDate) {
            filteredAppointments = filteredAppointments.filter(
              appointment => new Date(appointment.dateOfService) <= endDate
            )
          }

          if (reviewActionFilter) {
            filteredAppointments = filteredAppointments.filter(appointment =>
              reviewActionFilterHandler({ reviewActionFilter, state, userId })(appointment.id)
            )
          }

          filteredAppointments.sort(sortAppointmentsByDOS)

          const result: SummaryItemKey[] = []

          filteredAppointments.forEach(appointment => {
            const provider = documentStoreSelectors.providerByAppointmentId(appointment.id)(state)
            const filteredProviderIds = documentStoreSelectors.getFilteredProviderIds(state)

            if (provider && filteredProviderIds.includes(provider.id))
              result.push(`appointment:${provider.id}:${appointment.id}` as const)
          })

          return result
        }
        default: {
          const providerIds = documentStoreSelectors.getFilteredProviderIds(state)
          const filteredProviderIds = removeEmptyProviders
            ? providerIds.filter(id => documentStoreSelectors.appointmentIdsByProvider(id)(state).length)
            : providerIds

          return filteredProviderIds.flatMap(providerId => {
            const appointmentIds = documentStoreSelectors.filteredAppointmentIdsByProvider(providerId)(state)
            const providerKey = `provider:${providerId}` as const

            return [
              providerKey,
              ...appointmentIds.map(appointmentId => `appointment:${providerId}:${appointmentId}` as const),
            ]
          })
        }
      }
    },
  icdCodes: (state: DocumentStore) => {
    const icdCodes: StoreICDCode = {}

    for (const { code, appointmentId, relations, description } of Object.values(state.icdCodes)) {
      const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(state)

      if (
        new Date(icdCodes[code]?.dateOfService) < new Date(appointment.dateOfService) ||
        relations.length === 0
      ) {
        continue
      }

      const relation = relations[0]
      const partition = partitionsSelectors.getPartitionById(relation.partitionId)(state)
      const exhibitId = partition.exhibitId
      const exhibitIndex = state.exhibitsOrder.findIndex(id => id === exhibitId)

      const reference = {
        exhibitId,
        exhibitIndex,
        page: relation.page ?? 1,
      }

      icdCodes[code] = {
        code,
        dateOfService: appointment.dateOfService,
        description,
        reference,
        appointmentId: appointment.id,
      }
    }

    return Object.values(icdCodes).sort((a, b) => a.code.localeCompare(b.code))
  },
  filteredPastMedicalVisits: (state: DocumentStore) => {
    const providerIds = documentStoreSelectors.getFilteredProviderIds(state)

    return providerIds
      .map(providerId => {
        const providerName = state.providers[providerId]?.name
        const filteredAppointmentIds =
          documentStoreSelectors.filteredAppointmentIdsByProvider(providerId)(state)

        const pastAppointmentIds = filteredAppointmentIds.filter(
          appointmentId =>
            new Date(appointmentsSelectors.getAppointmentById(appointmentId)(state).dateOfService).valueOf() <
            Date.now()
        )

        let dateOfService = ""

        if (pastAppointmentIds.length === 1) {
          dateOfService = formatDate(
            appointmentsSelectors.getAppointmentById(pastAppointmentIds[0])(state).dateOfService,
            "MM/dd/yyyy",
            true
          )
        }

        if (pastAppointmentIds.length > 1) {
          dateOfService = `${formatDate(
            appointmentsSelectors.getAppointmentById(pastAppointmentIds[0])(state).dateOfService,
            "MM/dd/yyyy",
            true
          )} - ${formatDate(
            appointmentsSelectors.getAppointmentById(pastAppointmentIds[pastAppointmentIds.length - 1])(state)
              .dateOfService,
            "MM/dd/yyyy",
            true
          )}`
        }

        const appointment =
          pastAppointmentIds && appointmentsSelectors.getAppointmentById(pastAppointmentIds[0])(state)

        return {
          id: providerId,
          providerName,
          visits: pastAppointmentIds.length,
          dateOfService,
          reference: { partitionId: appointment?.partitions[0], page: null },
        }
      })
      .filter(({ visits }) => !!visits)
  },
  filteredIcdCodes: (state: DocumentStore) => {
    const icdCodes: StoreICDCode = {}
    const providerIds = documentStoreSelectors.getFilteredProviderIds(state)
    const allowedAppointmentIds = providerIds
      .map(providerId => documentStoreSelectors.filteredAppointmentIdsByProvider(providerId)(state))
      .flat()

    for (const { code, appointmentId, relations, description } of Object.values(state.icdCodes)) {
      const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(state)

      if (
        !allowedAppointmentIds.includes(appointment.id) ||
        new Date(icdCodes[code]?.dateOfService) < new Date(appointment.dateOfService) ||
        relations.length === 0
      ) {
        continue
      }

      const relation = relations[0]
      const partition = partitionsSelectors.getPartitionById(relation.partitionId)(state)
      const exhibitId = partition.exhibitId
      const exhibitIndex = state.exhibitsOrder.findIndex(id => id === exhibitId)

      const reference = {
        exhibitId,
        exhibitIndex,
        page: relation.page ?? 1,
      }

      icdCodes[code] = {
        code,
        dateOfService: appointment.dateOfService,
        description,
        reference,
        appointmentId: appointment.id,
      }
    }

    return Object.values(icdCodes).sort((a, b) => a.code.localeCompare(b.code))
  },
  flagsWithProviderAndDOS: (state: DocumentStore) => {
    const flags = flagsDataSelectors.getFlags(state)

    const result = Object.values(flags).map(flag => ({
      quote: flag.quote,
      reason: flag.reason,
      id: flag.id,
      relations: flag.relations,
      category: flag.category,
      appointment: flag.appointment,
      providerName: documentStoreSelectors.providerByAppointmentId(flag.appointment)(state)?.name,
      dateOfService: appointmentsSelectors.getAppointmentById(flag.appointment)(state).dateOfService,
    }))

    result.sort((a, b) => new Date(a.dateOfService).valueOf() - new Date(b.dateOfService).valueOf())

    return result
  },
  filteredFlags: (state: DocumentStore) => {
    const flagsWithProviderAndDOS = documentStoreSelectors.flagsWithProviderAndDOS(state)
    const providerIds = documentStoreSelectors.getFilteredProviderIds(state)
    const allowedAppointmentIds = providerIds
      .map(providerId => documentStoreSelectors.filteredAppointmentIdsByProvider(providerId)(state))
      .flat()

    return flagsWithProviderAndDOS.filter(flag => allowedAppointmentIds.includes(flag.appointment))
  },
}

const actions = (set: SetState<DocumentStore>, get: GetState<DocumentStore>) => {
  const deleteAppointment = (appointmentId: Appointment["id"]) => {
    const state = get()
    const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(state)
    const appointmentPartitions = Object.values(state.partitions).filter(partition =>
      partition.appointmentIds.includes(appointmentId)
    )

    const partitions = {
      ...state.partitions,
      ...Object.fromEntries(
        Object.entries(appointmentPartitions).map(([partitionId, partition]) => [
          partitionId,
          { ...partition, appointmentIds: without(partition.appointmentIds, appointmentId) },
        ])
      ),
    }
    const icdCodes = omit(state.icdCodes, ...appointment.icdCodes)
    const appointments = omit(state.appointments, appointmentId)

    set({ appointments, icdCodes, partitions })

    return documentStateActions.save(
      documentsService
        .deleteAppointment({ appointmentId })
        .catch(() => set(pick(state, ["appointments", "icdCodes", "partitions"]))),
      "There was an error deleting appointment."
    )
  }

  const createAppointment = async (
    data: RequiredNonNullableObj<Pick<Appointment, "dateOfService" | "providerId">> &
      Pick<Appointment, "summary"> & {
        icdCodes: Omit<IcdCode, "id" | "appointmentId">[]
        relations: IcdCodeRelation[]
      }
  ) => {
    const { documentId, partitions } = get()
    const { relations, ...restData } = data

    const partitionsByExhibit = new Map<number, number[]>()
    for (const relation of relations) {
      const exhibitId = partitions[relation.partitionId].exhibitId
      const exhibitPartitions = partitionsByExhibit.get(exhibitId) ?? []

      exhibitPartitions.push(relation.page ?? 1)
      partitionsByExhibit.set(exhibitId, exhibitPartitions)
    }

    const appointmentPartitions: Pick<Partition, "exhibitId" | "startPage" | "endPage">[] = []
    for (const [exhibitId, pages] of partitionsByExhibit.entries()) {
      const ranges = getRanges(pages)
      for (const range of ranges) {
        appointmentPartitions.push({
          exhibitId,
          startPage: range[0],
          endPage: range.at(-1) || range[0],
        })
      }
    }

    return documentStateActions.save(
      documentsService
        .createAppointment({
          data: {
            ...restData,
            partitions: appointmentPartitions,
          },
          documentId,
        })
        .then(({ appointment, icdCodes, partitions: appointmentPartitions }) => {
          const { setAppointment, setCurrentAppointment } = appointmentsActions(set, get)

          const newPartitions: DocumentStore["partitions"] = {}
          const newExhibits: DocumentStore["exhibits"] = {}

          for (const partition of appointmentPartitions) {
            if (!newPartitions[partition.id]) {
              newPartitions[partition.id] = partition

              newExhibits[partition.exhibitId] ??= get().exhibits[partition.exhibitId]
              newExhibits[partition.exhibitId].partitionIds.push(partition.id)
            }

            newPartitions[partition.id].appointmentIds.push(appointment.id)
          }

          setAppointment(appointment)
          set({
            icdCodes: {
              ...get().icdCodes,
              ...Object.fromEntries(icdCodes.map(icdCode => [icdCode.id, icdCode])),
            },
            partitions: { ...get().partitions, ...newPartitions },
            exhibits: { ...get().exhibits, ...newExhibits },
          })
          setCurrentAppointment(appointment.id)
        }),
      "There was an error creating new appointment."
    )
  }

  const fetchAppointment = ({ appointmentId }: { appointmentId: Appointment["id"] }) => {
    const { documentId, ...state } = get()
    const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(state)
    const { setAppointment } = appointmentsActions(set, get)

    documentsService.getAppointment({ appointmentId, documentId }).then(data => {
      setAppointment({ ...data, icdCodes: appointment.icdCodes })
    })
  }

  const addAppointmentIcdCode = ({
    appointmentId,
    icdCode,
  }: {
    appointmentId: Appointment["id"]
    icdCode: Pick<IcdCode, "code" | "relations">
  }) => {
    return documentStateActions.save(
      documentsService.addIcdCode({ appointmentId, data: icdCode }).then(icdCode => {
        const state = get()
        const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(state)
        const icdCodes = state.icdCodes

        const nextAppointment = appointment.icdCodes.includes(icdCode.id)
          ? appointment
          : { ...appointment, icdCodes: [...appointment.icdCodes, icdCode.id] }

        set({
          appointments: { ...state.appointments, [appointmentId]: nextAppointment },
          icdCodes: { ...icdCodes, [icdCode.id]: icdCode },
        })
      }),
      "There was an error adding icd code."
    )
  }

  const updateAppointmentIcdCode = ({
    appointmentId,
    icdCode,
  }: {
    appointmentId: Appointment["id"]
    icdCode: Pick<IcdCode, "code" | "relations" | "id">
  }) => {
    return documentStateActions.save(
      documentsService.updateIcdCode({ appointmentId, data: icdCode }).then(icdCode => {
        const state = get()
        const icdCodes = state.icdCodes

        set({
          icdCodes: { ...icdCodes, [icdCode.id]: icdCode },
        })
      }),
      "There was an error updating icd code."
    )
  }

  const deleteAppointmentIcdCode = ({
    appointmentId,
    icdCodeId,
  }: {
    appointmentId: Appointment["id"]
    icdCodeId: IcdCode["id"]
  }) => {
    const state = get()
    const appointments = state.appointments
    const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(state)
    const icdCodes = state.icdCodes
    const icdCode = icdCodes[icdCodeId]

    const nextAppointment = { ...appointment, icdCodes: appointment.icdCodes.filter(id => id !== icdCodeId) }
    const nextIcdCodes = { ...icdCodes }
    delete nextIcdCodes[icdCodeId]

    set({
      appointments: { ...state.appointments, [appointmentId]: nextAppointment },
      icdCodes: nextIcdCodes,
    })

    const request = documentsService.deleteIcdCode({ appointmentId, data: { code: icdCode.code } })
    request.catch(() => set({ appointments, icdCodes }))

    return documentStateActions.save(request, "There was an error updating icd code.")
  }

  return {
    deleteAppointment,
    createAppointment,
    fetchAppointment,
    addAppointmentIcdCode,
    updateAppointmentIcdCode,
    deleteAppointmentIcdCode,
  }
}

export const documentActions = {
  ...documentDataActions(useDocumentStore.setState, useDocumentStore.getState),
  ...exhibitActions(useDocumentStore.setState, useDocumentStore.getState),
  ...appointmentsActions(useDocumentStore.setState, useDocumentStore.getState),
  ...providersActions(useDocumentStore.setState, useDocumentStore.getState),
  ...icdCodeActions(useDocumentStore.setState, useDocumentStore.getState),
  ...filtersDataActions(useDocumentStore.setState, useDocumentStore.getState),
  ...flagsDataActions(useDocumentStore.setState, useDocumentStore.getState),
  ...userActions(useDocumentStore.setState, useDocumentStore.getState),
  ...actions(useDocumentStore.setState, useDocumentStore.getState),
}
