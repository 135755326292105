import { exhibitBuilderService } from "./api/service"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { Box, Dialog, FormControlLabel, Switch, Typography } from "@mui/material"
import { useState } from "react"
import { useTheme } from "@emotion/react"
import Button from "evenup-ui/Button"
import useCase from "hooks/useCase"

interface ExhibitBuilderToggleProps {
  caseId: string
  useExhibitBuilder: boolean
  onToggle: (useExhibitBuilder: boolean) => void
}

export function ExhibitBuilderToggle({ caseId, onToggle, useExhibitBuilder }: ExhibitBuilderToggleProps) {
  const { showErrorMessage } = useHandleMessages()
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()

  const { updateCache: updateCaseCache } = useCase(caseId)

  const toggleExhibitBuilder = async () => {
    try {
      setIsLoading(true)
      await exhibitBuilderService.useExhibitBuilder({
        caseId,
        useExhibitBuilder: !useExhibitBuilder,
      })
      onToggle(!useExhibitBuilder)

      updateCaseCache({ use_exhibit_builder: !useExhibitBuilder })
    } catch (err) {
      showErrorMessage({
        message: "Could not toggle exhibit builder. Please refresh and try again.",
        error: err,
      })
    } finally {
      setIsLoading(false)
      setShowModal(false)
    }
  }

  const actionText = useExhibitBuilder ? "Turning off exhibit builder" : "Turning on exhibit builder"

  return (
    <Box>
      <FormControlLabel
        label="Exhibit Builder"
        control={
          <Switch
            data-test="exhibit-builder-toggle"
            color="secondary"
            checked={useExhibitBuilder}
            onChange={() => setShowModal(true)}
          />
        }
      />
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        fullWidth
        data-test="exhibit-builder-toggle-confirm"
      >
        <Box p={4}>
          <Box mb={2}>
            <Typography variant="dialogHeader">{actionText}</Typography>
          </Box>
          <Typography variant="body1" color={theme.palette.error.main}>
            {actionText} will reset all providers, do you want to continue?
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2} gap={1}>
            <Button onClick={toggleExhibitBuilder} disabled={isLoading}>
              Continue
            </Button>
            <Button color="secondary" onClick={() => setShowModal(false)} disabled={isLoading}>
              Cancel
            </Button>
          </Box>
          {isLoading && <Typography variant="caption">Loading...</Typography>}
        </Box>
      </Dialog>
    </Box>
  )
}
