import { useCallback, useState } from "react"

interface Response {
  isOpen: boolean
  openDialog: () => void
  closeDialog: () => void
  toggleDialog: () => void
}

export function useDialog(initialState: boolean = false): Response {
  const [isOpen, setIsOpen] = useState(initialState)
  const openDialog = useCallback(() => setIsOpen(true), [])
  const closeDialog = useCallback(() => setIsOpen(false), [])
  const toggleDialog = useCallback(() => setIsOpen(!isOpen), [isOpen])

  return {
    isOpen,
    openDialog,
    closeDialog,
    toggleDialog,
  }
}
