import { Box } from "@mui/material"

import { formatDate } from "utils"
import { InterventionalTreatment } from "api/services/provider-interventional-treatment/types"
import { Provider } from "demand/Providers/types"
import { NoItems } from "../../styled"
import { DescriptionCell, InterventionalTreatmentReadonlyRow } from "./styled"
import { getExhibitReferencesSummary } from "demand/Providers/Provider/ProviderForm/ObjectiveTests/utils"
import { useExhibitBuilderStore } from "exhibit-builder/store"

interface InterventionalTreatmentsProps {
  interventionalTreatments: InterventionalTreatment[]
  providerExhibits: Provider["exhibits"]
  providerExhibitPartitions: Provider["exhibit_partitions"]
}

export const InterventionalTreatments = ({
  interventionalTreatments,
  providerExhibits,
  providerExhibitPartitions,
}: InterventionalTreatmentsProps) => {
  const userExhibits = useExhibitBuilderStore(state => state.userExhibitMap)

  if (!interventionalTreatments.length)
    return (
      <NoItems variant="outlined">No Interventional Treatments added. Edit provider to add some.</NoItems>
    )

  return (
    <Box mb={3}>
      <InterventionalTreatmentReadonlyRow fontWeight={700}>
        <Box>Date</Box>
        <Box>Treatment</Box>
        <Box>References</Box>
      </InterventionalTreatmentReadonlyRow>

      {interventionalTreatments.map(({ id, treatment, date, referencePages }) => {
        const exhibitReference = getExhibitReferencesSummary({
          referencePages,
          providerExhibits,
          providerExhibitPartitions,
          userExhibits,
        })

        return (
          <InterventionalTreatmentReadonlyRow key={id}>
            <Box>{date ? formatDate(new Date(date), "MM/dd/yyyy", true) : ""}</Box>
            <DescriptionCell>{treatment}</DescriptionCell>
            <Box>{exhibitReference}</Box>
          </InterventionalTreatmentReadonlyRow>
        )
      })}
    </Box>
  )
}
