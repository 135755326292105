import { Helmet } from "react-helmet"
import { useQuery } from "@tanstack/react-query"
import { useWatch } from "react-hook-form"

import { useRequestContext } from "../../context"
import { STEP_CODES } from "../constants"
import { isCreditTotalOverTrigger } from "../credits/creditUtils"

import { StyledRootBox } from "./components/styled"
import { RequestFormHeader } from "./components/RequestFormHeader"
import { RequestStep } from "./RequestStep"
import { ExpensiveRequestNotification } from "./components/ExpensiveRequestNotification"
import { queryKeys } from "react-query/constants"
import { calculateCredits } from "api"
import useUser from "hooks/useUser"
import { SaveAndSubmitButtons } from "./components/SaveAndSubmitButtons"
import { Box } from "@mui/material"

const FormActions = () => {
  const { activeStep, requestId, firm, totalPages } = useRequestContext()
  const { user } = useUser()

  const plaintiffs = useWatch({ name: "plaintiffs" })
  const type = useWatch({ name: "type" })

  const totalPlaintiffs = plaintiffs.length

  const { data: creditBreakdown } = useQuery(
    [queryKeys.creditBreakdown, requestId, totalPages, totalPlaintiffs, type],
    () =>
      calculateCredits({
        requestId,
        data: { page_count: totalPages, number_of_plaintiffs: totalPlaintiffs, questionnaire_type: type },
      }),
    { enabled: !!requestId, retry: false, meta: { disableLoader: true } }
  )

  const showExpensiveRequestNotification =
    user.isExternal &&
    requestId &&
    firm?.current_contract &&
    isCreditTotalOverTrigger(firm.current_contract, type, creditBreakdown?.total_credits ?? 0)

  const isClientInformationStep = activeStep === STEP_CODES.CLIENT_INFORMATION
  const isDocumentTypeStep = activeStep === STEP_CODES.DOCUMENT_TYPE

  if (showExpensiveRequestNotification && isClientInformationStep)
    return <ExpensiveRequestNotification creditBreakdown={creditBreakdown} />

  if (!isDocumentTypeStep) {
    return (
      <Box display="flex" justifyContent="flex-end">
        <SaveAndSubmitButtons />
      </Box>
    )
  }

  return <></>
}

const HelmetHeader = () => {
  const firstName = useWatch({ name: "plaintiffs.0.first_name" })
  const lastName = useWatch({ name: "plaintiffs.0.last_name" })

  const plaintiffName = `${firstName} ${lastName}`
  return (
    <Helmet>
      <title>Request: {plaintiffName}</title>
    </Helmet>
  )
}

export const SinglePageRequestForm = () => {
  const { activeStep } = useRequestContext()

  return (
    <>
      <HelmetHeader />
      {activeStep !== STEP_CODES.DOCUMENT_TYPE && <RequestFormHeader />}
      <StyledRootBox>
        <RequestStep />
      </StyledRootBox>
      <FormActions />
    </>
  )
}
