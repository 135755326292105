import { LinkBanner } from "common/banner/LinkBanner"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const ViewCaseButton = ({
  caseId,
  availableInsights,
}: {
  caseId: string
  availableInsights: boolean
}): JSX.Element => {
  const navigate = useNavigate()

  if (availableInsights) {
    return (
      <LinkBanner
        to={`/cases/${caseId}`}
        text="Case Insights Available"
        buttonText="View here"
        allowClose={false}
      />
    )
  }

  return (
    <Button variant="outlined" color="secondary" onClick={() => navigate(`/cases/${caseId}`)}>
      View Case
    </Button>
  )
}
