import { isUndefined } from "lodash"
import { handleEmptyResponse } from "api/utils"
import { withResponseSerializer } from "api/withSerializers"

import { ApiServiceType } from "../types"
import { apiService } from "../ApiService"
import { ProviderMedicalSummariesDeserializer } from "./serializers"
import { ProviderMedicalProfessional } from "./types"

enum SUMMARIES_API_PATHS {
  MEDICAL_SUMMARIES = "medical-summaries",
  REGENERATE = "regenerate",
}

interface ProviderSummariesServiceOptions {
  caseId: PrimaryKey
  providerId: PrimaryKey
}

class ProviderMedicalSummariesService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(
    paths: Array<SUMMARIES_API_PATHS | string | number>,
    options: ProviderSummariesServiceOptions
  ): string {
    const pathParts = ["", "case", options.caseId, "provider", options.providerId, ...paths]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getMedicalSummaries = withResponseSerializer(
    ProviderMedicalSummariesDeserializer.fromListJSON,
    (options: ProviderSummariesServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath([SUMMARIES_API_PATHS.MEDICAL_SUMMARIES], options))
      )
    }
  )

  updateSummary = ({
    summaryId,
    medicalProfessional,
    ...options
  }: ProviderSummariesServiceOptions & {
    summaryId: PrimaryKey
    medicalProfessional: ProviderMedicalProfessional
  }) => {
    return handleEmptyResponse(
      this.apiService.update(
        {
          selected_medical_professional_data: {
            first_name: medicalProfessional.firstName,
            last_name: medicalProfessional.lastName,
            designation: medicalProfessional.designation,
            title: medicalProfessional.title,
          },
        },
        this.getPath([SUMMARIES_API_PATHS.MEDICAL_SUMMARIES, summaryId], options)
      )
    )
  }

  regenerateSummaries = ({ dates, ...options }: ProviderSummariesServiceOptions & { dates?: string[] }) => {
    return handleEmptyResponse(
      this.apiService.create(
        dates ? { dates } : null,
        this.getPath([SUMMARIES_API_PATHS.MEDICAL_SUMMARIES, SUMMARIES_API_PATHS.REGENERATE], options)
      )
    )
  }
}

export const providerMedicalSummariesService = new ProviderMedicalSummariesService(apiService)
