import { Plaintiff, Provider, UserExhibit } from "../store/types"
import { SetState } from "./types"

export type FiltersSlice = {
  filters: {
    selectedProviders: Provider["id"][]
    selectedPlaintiffs: Plaintiff["id"][]
    selectedDocTypes: string[]
    selectedSubDocTypes: string[]
    selectedStartDate: Nullable<string>
    selectedEndDate: Nullable<string>
    selectedExhibitTags: UserExhibit["tag"][]
    selectedFiles: string[]
    exactProviderMatch: boolean
    onlyInvalidExhibits: boolean
  }
}

export const filtersSlice: FiltersSlice = {
  filters: {
    selectedProviders: [],
    selectedPlaintiffs: [],
    selectedDocTypes: [],
    selectedSubDocTypes: [],
    selectedStartDate: null,
    selectedEndDate: null,
    selectedExhibitTags: [],
    selectedFiles: [],
    exactProviderMatch: false,
    onlyInvalidExhibits: false,
  },
}

export const filtersSelectors = {
  hasFiltersApplied: ({ filters }: FiltersSlice) => {
    return Boolean(
      filters.selectedDocTypes.length ||
        filters.selectedSubDocTypes.length ||
        filters.selectedStartDate ||
        filters.selectedEndDate ||
        filters.selectedExhibitTags.length ||
        filters.selectedProviders.length ||
        filters.selectedPlaintiffs.length ||
        filters.selectedFiles.length
    )
  },
}

export const filtersActions = (set: SetState<FiltersSlice>) => {
  const selectProviders = (providerIds: Provider["id"][]) => {
    set(state => ({ filters: { ...state.filters, selectedProviders: providerIds } }))
  }
  const selectPlaintiffs = (plaintiffIds: Plaintiff["id"][]) => {
    set(state => ({ filters: { ...state.filters, selectedPlaintiffs: plaintiffIds } }))
  }
  const selectDocTypes = (docTypes: string[]) => {
    set(state => ({ filters: { ...state.filters, selectedDocTypes: docTypes } }))
  }
  const selectSubDocTypes = (subDocTypes: string[]) => {
    set(state => ({ filters: { ...state.filters, selectedSubDocTypes: subDocTypes } }))
  }
  const selectStartDate = (startDate: Nullable<string>) => {
    set(state => ({ filters: { ...state.filters, selectedStartDate: startDate } }))
  }
  const selectEndDate = (endDate: Nullable<string>) => {
    set(state => ({ filters: { ...state.filters, selectedEndDate: endDate } }))
  }
  const selectTags = (tags: UserExhibit["tag"][]) => {
    set(state => ({ filters: { ...state.filters, selectedExhibitTags: tags } }))
  }

  const setSelectedFiles = (fileIds: string[]) => {
    set(state => ({ filters: { ...state.filters, selectedFiles: fileIds } }))
  }

  const setExactProviderMatch = (exactProviderMatch: boolean) => {
    set(state => ({ filters: { ...state.filters, exactProviderMatch } }))
  }

  const setOnlyInvalidExhibits = (onlyInvalidExhibits: boolean) => {
    set(state => ({ filters: { ...state.filters, onlyInvalidExhibits } }))
  }

  const clearFilters = () => {
    set(() => filtersSlice)
  }

  return {
    clearFilters,
    selectProviders,
    selectPlaintiffs,
    selectDocTypes,
    selectSubDocTypes,
    selectStartDate,
    selectEndDate,
    selectTags,
    setSelectedFiles,
    setExactProviderMatch,
    setOnlyInvalidExhibits,
  }
}
