import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTimeSpendingAnalytic } from "infrastructure/timeSpending/hooks"
import { DndProps, SortableNestedList } from "common/nested-list/SortableNestedList"
import { ExhibitItem } from "documents/types"
import { ExhibitContent } from "./ExhibitContent"
import { ExhibitListItem } from "./ExhibitListItem"
import { DocumentGenerateButton } from "./DocumentGenerateButton"
import { CollapseManager } from "common/CollapseManager/CollapseManager"
import { CollapseButton } from "common/CollapseManager/CollapseButton"
import { ExhibitListSorting } from "./ExhibitListSorting"
import { ExhibitList, ExhibitListContainer, ExhibitListControls, StyledButtonWrapper } from "./styled"
import { SavingIndicator } from "documents/SavingIndicator"
import { documentActions, useDocumentStore } from "documents/store"
import { documentStateActions } from "documents/store/documentState"
import { usePageStore } from "common/pages/pageStore"
import { usePermissions } from "permissions/usePermissions"
import { SyncWithDocumentStructureButton } from "common/SyncWithDocumentStructureButton"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import { documentsService } from "api/services/documents"
import { useQuery } from "@tanstack/react-query"
import { DOCUMENT_TYPES } from "requests/enums"
import { useSyncWithDocumentStructure } from "hooks/useSyncWithDocumentStructure"
import { ExhibitManagement } from "documents/exhibits/ExhibitManagement"
import { MedChronExhibitBuilderToggle } from "./MedChronExhibitBuilderToggle"
import { Document } from "documents/Document"
import { useParams } from "react-router-dom"
import { INTAKE_STATUSES } from "requests/constants"

type MedchronTile = {
  questionnaireType: string
  questionnaireStatus: INTAKE_STATUSES
}

const LegacyExhibitManagement: React.FC<{
  documentId: string
  medchronTile: MedchronTile
}> = ({ documentId, medchronTile }) => {
  const { medicalSummaryEnabled } = usePermissions()
  const questionnaireId = useDocumentStore(state => state.questionnaireId)
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const exhibits = useMemo(() => exhibitsOrder.map(id => ({ id })), [exhibitsOrder])
  const handleReorder = useCallback<DndProps<ExhibitItem>["onUpdate"]>(async (items, operation) => {
    if (!operation) return

    documentActions.markExhibit({ id: operation.item.id, state: "reordered" })
    await documentActions.reorderExhibit({ exhibits: items, id: operation.item.id, index: operation.index })
  }, [])

  useEffect(() => {
    documentStateActions.setTitle("Medical Chronology")
    documentStateActions.setShowExtraButtons(medicalSummaryEnabled)
    usePageStore.setState({ showBack: true, backTitle: "Back", backHref: `/requests/${questionnaireId}` })

    if (medchronTile) {
      documentStateActions.setTitleTags([
        medchronTile.questionnaireType === DOCUMENT_TYPES.PREMIUM_MEDCHRON ? "Premium" : "Standard",
      ])
    }

    return () => {
      documentStateActions.unsetTitle()
      documentStateActions.setTitleTags([])
    }
  }, [medicalSummaryEnabled, questionnaireId, medchronTile])

  const {
    isSyncDocumentStructureRequired,
    isLoading: isSyncingDocumentStructure,
    handleSyncDocumentStructure,
  } = useSyncWithDocumentStructure({ documentId, fetchOnMount: !!documentId })

  useTimeSpendingAnalytic({ documentId })

  return (
    <CollapseManager>
      {isSyncDocumentStructureRequired && (
        <SyncWithDocumentStructureButton
          isLoading={isSyncingDocumentStructure}
          onSync={handleSyncDocumentStructure}
        />
      )}
      <ExhibitListContainer>
        <ExhibitListSorting />
        <ExhibitListControls>
          <CollapseButton />
        </ExhibitListControls>
        <ExhibitList>
          <SortableNestedList
            items={exhibits as ExhibitItem[]}
            uniqueKey="id"
            onUpdate={handleReorder}
            canDropAsChild={() => false}
            contentProps={null}
            ItemContentComponent={ExhibitContent}
            PlaceholderComponent={() => null}
            ItemComponent={ExhibitListItem}
          />
        </ExhibitList>
      </ExhibitListContainer>
      <StyledButtonWrapper>
        <SavingIndicator />
        {medchronTile && (
          <DocumentGenerateButton
            requestId={questionnaireId}
            requestStatus={medchronTile.questionnaireStatus}
          />
        )}
      </StyledButtonWrapper>
    </CollapseManager>
  )
}

export const DocumentExhibits = React.memo(function DocumentExhibits(): JSX.Element {
  const { medchronExhibitBuilderToggleEnabled } = usePermissions()
  const { id: documentId } = useParams<{ id: string }>()
  const [exhibitBuilderToggleOn, setExhibitBuilderToggleOn] = useState(false)

  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    async () => {
      if (documentId) {
        const medchronTile = await documentsService.getMedchronTile({ documentId })
        setExhibitBuilderToggleOn(medchronTile?.useExhibitBuilder)
        return medchronTile
      }
    },
    {
      ...SILENT_QUERY_PARAMS,
      enabled: !!documentId,
    }
  )

  const handleToggleExhibitBuilder = async (useExhibitBuilder: boolean) => {
    setExhibitBuilderToggleOn(useExhibitBuilder)
  }

  return (
    <>
      {medchronExhibitBuilderToggleEnabled && documentId && (
        <MedChronExhibitBuilderToggle
          documentId={documentId}
          useExhibitBuilder={exhibitBuilderToggleOn}
          onToggle={handleToggleExhibitBuilder}
        />
      )}
      {exhibitBuilderToggleOn ? (
        <ExhibitManagement />
      ) : (
        <Document>
          {documentId && medchronTile ? (
            <LegacyExhibitManagement medchronTile={medchronTile} documentId={documentId} />
          ) : null}
        </Document>
      )}
    </>
  )
})
