import React, { HTMLAttributes, useCallback, useMemo } from "react"
import TextField from "@mui/material/TextField"
import FormHelperText from "@mui/material/FormHelperText"
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete"
import { StyledFormControl } from "./styled"
import { NONE_VALUE } from "./utils"

interface AttributeSelectProps<TValue> {
  value: TValue | typeof NONE_VALUE
  label: string
  options: ValueOptions<TValue | typeof NONE_VALUE>
  onChange: (value: TValue | typeof NONE_VALUE) => void
  required?: boolean
  error?: boolean
  disabled?: boolean
  helperText?: string
  dataTest?: string
}

// React.forwardRef type does not support of returning Generic components
// So we need to cast to "custom" type that is compatible with React.forwardRef and supports generics
export const AttributeSelect = React.forwardRef(AttributeSelectComponent) as unknown as <
  TValue extends string | number,
>(
  props: AttributeSelectProps<TValue> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof AttributeSelectComponent>

function AttributeSelectComponent<TValue extends string | number>(
  {
    value,
    options,
    label,
    onChange,
    error,
    disabled,
    helperText,
    dataTest,
    ...rest
  }: AttributeSelectProps<TValue>,
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element {
  const handleChange = useCallback(
    (_: React.SyntheticEvent, e: Nullable<ValueOption<TValue | typeof NONE_VALUE>>) =>
      onChange(e ? e.key : NONE_VALUE),
    [onChange]
  )

  const currentValue = useMemo(
    () => options.find(({ key }) => (value ?? NONE_VALUE) === key),
    [options, value]
  )

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return <TextField {...params} variant="outlined" label={label} error={error} />
    },
    [label, error]
  )
  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, data: ValueOption<TValue | typeof NONE_VALUE>) => {
      return (
        <li {...props}>
          <span data-test={data.display}>{data.display}</span>
        </li>
      )
    },
    []
  )
  const getOptionLabel = useCallback((option: { display: string }) => option.display, [])

  return (
    <StyledFormControl {...rest} ref={ref} error={error}>
      <Autocomplete
        size="small"
        blurOnSelect
        clearOnBlur
        data-test={dataTest}
        style={{ minWidth: 210, marginLeft: 8 }}
        value={currentValue}
        options={options}
        renderInput={renderInput}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        disabled={disabled}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  )
}
