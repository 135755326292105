import {
  makeApiCall,
  JsonCallProps,
  REQUEST_METHODS,
  EmptyResponse,
  FormDataCallProps,
  BlobCallProps,
} from "../apiHelper"
import { QueryFunctionContext } from "@tanstack/react-query"
import { handleEmptyResponse } from "api/utils"
import * as Sentry from "@sentry/browser"
export interface AnnotationFile {
  pk: number
  filename: string
  /**
   * pathfor result of annotation request
   */
  result_file: string
  /**
   * pathfor CSV result of annotation request
   */
  result_csv_file: string
  /**
   * path for kili annotated file
   */
  kili_result_file: string
  /**
   * path for file with annotation summary
   */
  json_annotations: string
  /**
   * URL for Status of annotation request
   */
  status_file: string

  order_number: number
  /**
   * UUID identifying a given file. Mostly used for back-end purposes but included here so we can display for debugging.
   */
  file_id: string
  status: AnnotationStatus

  display_name: string
  processing_requests: AnnotationProcessingRequest[]
}

export interface AnnotationProcessingRequest {
  pk: number
  annotation_file: number
  creator: null
  event_key: string
  processing_types_requested: string[]
  project_id: string
}
export interface AnnotationStatus {
  pk: number
  annotation_file: number
  last_updated: string // string representing a date
  json: AnnotationJsonStatus
}

export interface AnnotationFileInformation {
  pk: number
  annotation_file: number
  provider_name: string
  date_of_service: Date
  document_type: string
}

export interface AnnotationJsonStatus {
  status: string
  kili_assets: { [key: string]: KiliAsset }
}
export interface KiliAsset {
  labels: string[]
  content: string
  createdAt: string
  updatedAt: string
  externalId: string
  id: string
  isHoneypot: boolean
  jsonMetadata: { [key: string]: string }
  skipped: boolean
  status: string
}
export interface Paginated<T> {
  count: number
  next: string
  previous: string
  results: T[]
}

export interface AnnotationRequest {
  pk: number
  name: string
  create_date: string // represents a date
  annotation_files: [AnnotationFile]
  json_annotations: string // json file of summary annotations
}

export interface GetAnnotationRequestProps {
  page?: number
  pageSize?: number
}

export async function getAnnotationRequests(
  props: GetAnnotationRequestProps
): Promise<Paginated<AnnotationRequest> | EmptyResponse> {
  const options: JsonCallProps = {
    path: `/annotation/?page=${props.page || 0}&page_size=${props.pageSize || 10}`,
    method: REQUEST_METHODS.GET,
  }
  return makeApiCall(options)
}

export async function getAnnotationRequest(
  context: QueryFunctionContext
): Promise<AnnotationRequest | EmptyResponse> {
  const [, requestId] = context.queryKey
  const options: JsonCallProps = {
    path: `/annotation/${requestId}/`,
    method: REQUEST_METHODS.GET,
  }
  return makeApiCall(options)
}

export interface CreateAnnotationData {
  name: string
  uploads: { uploadId: string; filename: string }[]
}

export interface AnnotationStatsData {
  start_date: string
  end_date: string
}

export async function createAnnotationRequest(request: CreateAnnotationData): Promise<Response> {
  const data = new FormData()
  data.append("name", request.name)
  for (const [index, { uploadId, filename }] of request.uploads.entries()) {
    data.append(`uploads[${index}]upload_id`, uploadId)
    data.append(`uploads[${index}]name`, filename)
  }
  const options: FormDataCallProps = {
    path: "/annotation/",
    method: REQUEST_METHODS.POST,
    isFormData: true,
    json: false,
    data: data,
  }
  return makeApiCall(options)
}

export async function downloadAnnotationStats(request: AnnotationStatsData): Promise<void> {
  const options: BlobCallProps = {
    path: "/annotation/stats/",
    query: {
      start_date: request.start_date,
      end_date: request.end_date,
    },
    method: REQUEST_METHODS.GET,
    json: false,
    blob: true,
  }
  const file_name = `${request.start_date}-${request.end_date}_annotation_stats.csv`
  await download(options, file_name)
}

export async function download(props: BlobCallProps, filename: Nullable<string>): Promise<void> {
  try {
    const blobResponse = await handleEmptyResponse(makeApiCall(props))
    const blob = await blobResponse.blob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", filename ? filename : blobResponse.filename)
    link.setAttribute("style", "display: none")
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

export async function downloadAll(requestId: number): Promise<void> {
  const options: BlobCallProps = {
    path: `/annotation/${requestId}/download_zip`,
    method: REQUEST_METHODS.GET,
    json: false,
    blob: true,
  }
  await download(options, `${requestId}_all.zip`)
}

export async function downloadOriginalFile(
  filename: string,
  requestId: number,
  fileId: number
): Promise<void> {
  const options: BlobCallProps = {
    path: `/annotation/${requestId}/file/${fileId}/original/`,
    method: REQUEST_METHODS.GET,
    json: false,
    blob: true,
  }
  await download(options, filename)
}

export async function downloadFileCSV(filename: string, requestId: number, fileId: number): Promise<void> {
  const slice = filename.split("/")
  const fname = slice[slice.length - 1]
  await download(
    {
      path: `/annotation/${requestId}/file/${fileId}/csv/`,
      method: REQUEST_METHODS.GET,
      json: false,
      blob: true,
    },
    fname
  )
}

export async function downloadAutoAnnotation(
  filename: string,
  requestId: number,
  fileId: number
): Promise<void> {
  const slice = filename.split("/")
  const fname = slice[slice.length - 1]
  await download(
    {
      path: `/annotation/${requestId}/file/${fileId}/automated_annotation/`,
      method: REQUEST_METHODS.GET,
      json: false,
      blob: true,
    },
    fname
  )
}

export async function downloadKiliAnnotation(
  filename: string,
  requestId: number,
  fileId: number
): Promise<void> {
  const slice = filename.split("/")
  const fname = slice[slice.length - 1]
  await download(
    {
      path: `/annotation/${requestId}/file/${fileId}/kili_annotation/`,
      method: REQUEST_METHODS.GET,
      json: false,
      blob: true,
    },
    fname
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getStatus(requestId: number, fileId: number): Promise<any> {
  return await makeApiCall({
    path: `/annotation/${requestId}/file/${fileId}/status`,
    method: REQUEST_METHODS.GET,
    json: true,
  })
}

export async function getFileName(requestId: number, fileId: number): Promise<{ filename?: string }> {
  try {
    return await makeApiCall({
      path: `/annotation/${requestId}/file-metadata/${fileId}/file_name`,
      method: REQUEST_METHODS.GET,
      json: true,
    })
  } catch (error) {
    Sentry.captureException(error)
    return { filename: "" }
  }
}
