import { memo, useCallback, useEffect, useMemo, useRef } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import LinearProgress from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Sort from "@mui/icons-material/Sort"
import { makeStyles } from "tss-react/mui"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import {
  createProviderExhibit,
  deleteProvider,
  fetchProvidersByCase,
  getCase,
  getProviderUpdatesForCase,
  getSectionMissingExhibits,
  importProviders,
  saveProvider,
  updateCptCodesForProvider,
  updateIcdCodesForProvider,
  updateProvider,
  updateProviderOrder,
} from "api"
import { reorderImmutable } from "utils"
import { queryKeys } from "react-query/constants"
import {
  ADD_PROVIDER,
  DELETE_PROVIDER,
  SET_INITIAL_PROVIDERS,
  SET_VALIDATION_ERRORS,
  SORT_PROVIDERS,
  TOGGLE_DELETING,
  TOGGLE_SAVING,
  REORDER_PROVIDERS,
} from "./store/reducer"
import { formSectionsToRoutes, STEP_STATUSES } from "../constants"
import { useFormContext } from "../context"
import { ApiError } from "apiHelper"
import { validate } from "./validation"
import Summary from "./Summary"
import { ProviderListItem } from "./Provider/ProviderListItem"
import { GenericError } from "common"
import { SECTIONS } from "missing-docs/constants"
import useUser from "hooks/useUser"
import { isNotNotSetupRole } from "common/permission"
import { useHandleMessages } from "common/messages/useHandleMessages"
import TextButton from "common/buttons/TextButton"
import { getFilteredCodes } from "./Provider/utils"
import {
  updateProviderActions,
  updateInBackgroundProviderActions,
  updateWhenBlurredProviderActions,
  getNewProviderData,
  providerDataToFormData,
  createSetOfCodes,
} from "./utils"
import {
  PROVIDER_CONFIRMATION_MESSAGES,
  PROVIDER_ERRORS_MESSAGES,
  PROVIDER_SUCCESS_MESSAGES,
  EMPTY_VALIDATION_ERRORS,
} from "./constants"
import { queryClient } from "react-query/queryClient"
import { useFileUploader, withFileUploadProvider } from "common/file-uploader"
import { isEmpty } from "lodash"
import { useOutletContext } from "react-router-dom"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"
import { documentsService } from "api/services/documents"
import useCase from "hooks/useCase"
import { ReviewAlert } from "review/ReviewAlert"
import { createProvidersStore } from "./store"
import { useShallow } from "zustand/react/shallow"
import { exhibitBuilderActions } from "exhibit-builder/store"
import { ProvidersStoreContext } from "./store/context"

const useStyles = makeStyles()(theme => ({
  summaryWrapper: {
    display: "flex",
    margin: theme.spacing(7, 0, 5, 0),
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: theme.spacing(2),
      alignItems: "flex-start",
    },
  },
}))

export const ProviderList = withFileUploadProvider(
  memo(function ProviderList({ lastVisited }) {
    const useProvidersStore = useMemo(() => createProvidersStore(), [])
    const { dispatch } = useProvidersStore
    const activeProviderId = useProvidersStore(state => state.activeProviderId)
    const validationErrors = useProvidersStore(state => state.validationErrors)

    const { currentPlaintiff } = useOutletContext()
    const { uploadFiles, assignUploaded, unassignUploaded } = useFileUploader()
    const activeProviderCodes = useRef(null)
    const { caseId, handleUpdateStepStatus, request } = useFormContext()
    const { classes } = useStyles()
    const { showMessage } = useHandleMessages()
    const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)
    const { caseObj } = useCase(caseId)

    const providerList = useProvidersStore(
      useShallow(state => {
        if (!multiPlaintiffEnabled) return state.providersOrder
        if (!currentPlaintiff) return []

        return state.providersOrder.filter(providerId => {
          const provider = state.providers.get(providerId)
          return provider?.plaintiff?.pk === currentPlaintiff.id || !provider?.plaintiff?.pk
        })
      })
    )

    const { isLoading: importingProviders } = useQuery(["import-providers"], () => importProviders(caseId), {
      cacheTime: 0,
      onError: error => {
        showMessage({ type: "error", message: PROVIDER_ERRORS_MESSAGES.PROVIDER_IMPORTING_ERROR, error })
      },
      meta: {
        disableLoader: true,
      },
    })

    const { isFetching: providersLoading, isError: providersError } = useQuery(
      [queryKeys.providers, caseId],
      fetchProvidersByCase,
      {
        onSuccess: data => {
          // only set the data once on initial load
          // this guard is here since calling queryClient.setQueryData triggers this onSuccess
          // TODO: verify this check is still needed. With latest react-query onSuccess is not called when using setQueryData
          if (!data.length) handleUpdateStepStatus({ status: STEP_STATUSES.started })

          const providersFormData = data.map(providerDataToFormData)

          for (const providerFormData of providersFormData) {
            queryClient.setQueryData([queryKeys.providers, caseId, providerFormData.pk], providerFormData)
          }

          dispatch({
            type: SET_INITIAL_PROVIDERS,
            payload: { providers: providersFormData, caseId },
          })
        },
        enabled: !importingProviders,
      }
    )

    const { data: providerUpdates } = useQuery(
      [queryKeys.providerUpdates, caseId],
      async () => {
        return getProviderUpdatesForCase(caseId)
      },
      {
        // temporarily turning this call for big query prod issue
        enabled: false,
        meta: {
          disableLoader: true,
        },
      }
    )

    const { data: caseData } = useQuery([queryKeys.case, caseId], getCase)
    const hasCollateralSourceRule = !!caseData?.firm?.has_collateral_source_rule
    const { user } = useUser()

    const { data: missingExhibits } = useQuery(
      [queryKeys.missingExhibits, caseId],
      async () => {
        return await getSectionMissingExhibits({ caseId: caseId, section: SECTIONS.PROVIDERS })
      },
      {
        enabled: isNotNotSetupRole(user.role) && !!request?.pk,
      }
    )

    const { data: documentStructureContentComponents } = useQuery(
      [queryKeys.documentStructureContentComponents, caseObj?.document_id],
      async () => {
        return await documentsService.getDocumentStructureOrder({ documentId: caseObj?.document_id })
      },
      {
        enabled: !!caseObj?.document_id,
      }
    )

    const saveMutation = useMutation(saveProvider)
    const updateMutation = useMutation(updateProvider)
    const deleteProviderMutation = useMutation(deleteProvider)
    const updateIcdCodesMutation = useMutation(updateIcdCodesForProvider)
    const updateCptCodesMutation = useMutation(updateCptCodesForProvider)
    const orderProvidersMutation = useMutation(updateProviderOrder)
    const createExhibitMutation = useMutation(createProviderExhibit)

    const handleSortProviders = () => {
      orderProvidersMutation.mutate({ data: { by: "first_contact" }, caseId })
      dispatch({ type: SORT_PROVIDERS })
    }

    const createExhibitFromQuestionnaireFile = useCallback(
      async (name, type, providerId, questionnaireFileId) => {
        let data = new FormData()
        data.append("name", name)
        data.append("type", type)
        data.append("section", "providers")
        data.append("section_index", 0)
        data.append("file", new Blob())
        data.append("questionnaire_file_id", questionnaireFileId)

        const exhibit = await createExhibitMutation.mutateAsync({
          data,
          caseId,
          providerId,
          isFormData: true,
        })

        return await exhibit.json()
      },
      [caseId, createExhibitMutation]
    )

    const uploadExhibit = useCallback(
      async ({ file, providerId, index }) => {
        const { file: fileObject, type, name } = file
        const { questionnaireFileId = null } = fileObject

        if (questionnaireFileId) {
          return await createExhibitFromQuestionnaireFile(name, type, providerId, questionnaireFileId)
        }

        const uploads = await uploadFiles([fileObject])
        const [{ uploadId }] = uploads.items

        if (uploads.hasFailures || !uploadId) {
          throw new Error(PROVIDER_ERRORS_MESSAGES.UPLOADNIG_FILE_FAIL)
        }

        const data = {
          name,
          type,
          upload_id: uploadId,
          section: "providers",
          section_index: index,
        }

        const resultData = await createExhibitMutation.mutateAsync({
          data,
          providerId,
          caseId,
        })

        assignUploaded(uploadId, resultData.pk)

        return resultData
      },
      [caseId, createExhibitMutation, createExhibitFromQuestionnaireFile, uploadFiles, assignUploaded]
    )

    const handleExhibitDelete = useCallback(exhibitId => unassignUploaded(exhibitId), [unassignUploaded])

    const createProviderUpdater = ({
      partiallyUpdatedAction,
      fullyUpdatedAction,
      showSaving,
      showMessages,
    }) => {
      return async provider => {
        if (!activeProviderCodes.current) return

        const [updateCPT, updateICD] = [
          activeProviderCodes.current?.cptCodes.size != provider.cpt_codes.length ||
            provider.cpt_codes.some(({ code }) => !activeProviderCodes.current?.cptCodes.has(code)),
          activeProviderCodes.current?.icdCodes.size != provider.icd_codes.length ||
            provider.icd_codes.some(({ code }) => !activeProviderCodes.current?.icdCodes.has(code)),
        ]

        if (updateCPT || updateICD) {
          activeProviderCodes.current.cptCodes = createSetOfCodes(provider.cpt_codes)
          activeProviderCodes.current.icdCodes = createSetOfCodes(provider.icd_codes)
        }

        dispatch({ type: SET_VALIDATION_ERRORS, payload: { validationErrors: EMPTY_VALIDATION_ERRORS } })

        const validation = validate(provider)
        if (validation) {
          dispatch({ type: SET_VALIDATION_ERRORS, payload: { validationErrors: validation } })
          showMessage({ type: "error", message: PROVIDER_ERRORS_MESSAGES.INPUT_ERROR })
          return
        }

        if (showSaving) {
          // set provider as saving to disable form
          dispatch({ type: TOGGLE_SAVING, payload: { id: provider.pk } })
        }

        const errors = []

        let icdCodesResult = null
        let cptCodesResult = null

        if (updateICD) {
          icdCodesResult = await updateIcdCodesMutation.mutateAsync({
            providerId: provider.pk,
            caseId,
            data: {
              // prefer the icd_code_id
              // when data is loaded from the server the pk is the id of the row of the relationship
              // not the actual icd code. The pk returned when selecting icd codes is the correct
              // id of the icd code itself.
              codes: getFilteredCodes(provider?.icd_codes).map(code => code.icd_code_id ?? code.pk),
            },
          })
        }

        if (updateCPT) {
          cptCodesResult = updateCptCodesMutation.mutateAsync({
            providerId: provider.pk,
            caseId,
            data: {
              codes: getFilteredCodes(provider?.cpt_codes).map(code => code.code),
            },
          })
        }

        const [...fileUploadResults] = await Promise.allSettled(
          provider?.filesToUpload?.map(file => uploadExhibit({ file, providerId: provider.pk })) ?? []
        )

        if (icdCodesResult?.status === "rejected") {
          errors.push(PROVIDER_ERRORS_MESSAGES.ICD_SAVING_ERROR)
        }

        if (cptCodesResult?.status === "rejected") {
          errors.push(PROVIDER_ERRORS_MESSAGES.CPT_SAVING_ERROR)
        }

        let updateProvider = {}
        const fileFormIdsToExhibitPks = {}
        const failedToUploadFormIds = {}
        fileUploadResults?.forEach((result, index) => {
          const formId = provider.filesToUpload[index].formId
          if (result.status === "rejected") {
            errors.push(PROVIDER_ERRORS_MESSAGES.UPLOADNIG_FILE_PROBLEM(provider.filesToUpload[index].name))
            failedToUploadFormIds[formId] = true
          } else {
            const newExhibit = result.value
            if (!updateProvider.exhibits) {
              updateProvider.exhibits = []
            }
            updateProvider.exhibits.push(newExhibit)
            fileFormIdsToExhibitPks[formId] = newExhibit.pk
          }
        })

        const bills = provider.bills.map(bill => {
          if (bill.file_to_upload_id) {
            return {
              ...bill,
              file_to_upload_id: null,
              exhibit_id: failedToUploadFormIds[bill.file_to_upload_id]
                ? null
                : fileFormIdsToExhibitPks[bill.file_to_upload_id],
            }
          }
          return bill
        })

        let providerResult = null
        try {
          providerResult = await updateMutation.mutateAsync({
            caseId,
            providerId: provider.pk,
            data: { ...provider, bills },
          })
        } catch (error) {
          if (error instanceof ApiError && error.validationErrors) {
            dispatch({ type: SET_VALIDATION_ERRORS, payload: { validationErrors: error.validationErrors } })
            showMessage({ type: "error", message: PROVIDER_ERRORS_MESSAGES.INPUT_ERROR, error })
          } else {
            showMessage({ type: "error", message: PROVIDER_ERRORS_MESSAGES.PROVIDER_UPDATING_ERROR, error })
          }
          if (showSaving) {
            dispatch({ type: TOGGLE_SAVING, payload: { id: provider.pk } })
          }
          return
        }

        if (errors.length) {
          showMessage({
            type: "warning",
            message: PROVIDER_ERRORS_MESSAGES.PROVIDER_SAVING_WITH_ERRORS(provider.name, errors),
          })
        }

        if (showSaving && provider) {
          const newFilesToUpload = provider.filesToUpload?.filter(
            ({ formId }) => failedToUploadFormIds[formId]
          )

          updateProvider = { ...updateProvider, ...providerResult, filesToUpload: newFilesToUpload }

          const formProvider = providerDataToFormData(updateProvider)

          dispatch({ type: TOGGLE_SAVING, payload: { id: provider.pk } })

          if (errors.length) {
            // there were errors with previous api calls but we were still able to update the provider
            dispatch(partiallyUpdatedAction(formProvider, caseId))
          } else {
            dispatch(fullyUpdatedAction(formProvider, caseId))
            if (showMessages) {
              showMessage({
                type: "success",
                message: PROVIDER_SUCCESS_MESSAGES.PROVIDER_SAVED(provider.name),
              })
            }
          }
        }
      }
    }

    const handleUpdate = createProviderUpdater(updateProviderActions)
    const handleUpdateInBackground = createProviderUpdater(updateInBackgroundProviderActions)
    const handleUpdateWhenBlurred = createProviderUpdater(updateWhenBlurredProviderActions)

    const handleDelete = useCallback(
      async provider => {
        if (confirm(PROVIDER_CONFIRMATION_MESSAGES.DELETE_PROVIDER(provider.name))) {
          dispatch({
            type: TOGGLE_DELETING,
            payload: {
              id: provider.pk,
              request_id: request.pk,
              request_type: request.type,
              demand_id: caseId,
              provider_name: provider.name,
            },
          })
          try {
            await deleteProviderMutation.mutateAsync({ caseId, providerId: provider.pk })
          } catch (error) {
            showMessage({
              type: "error",
              message: PROVIDER_ERRORS_MESSAGES.DELETING_PROVIDER_PROBLEM(provider.name),
              error,
            })
            dispatch({ type: TOGGLE_DELETING, payload: { id: provider.pk } })
            return
          }

          showMessage({ type: "success", message: PROVIDER_SUCCESS_MESSAGES.PROVIDER_DELETED(provider.name) })
          dispatch({ type: DELETE_PROVIDER, payload: { pk: provider.pk, caseId } })
          queryClient.invalidateQueries([queryKeys.steps, caseId])
        }
      },
      [caseId, deleteProviderMutation, request, showMessage, dispatch]
    )

    const handleDragEnd = useCallback(
      ({ destination, source }) => {
        // dropped outside the list or moved to same position
        if (!destination || destination.index === source.index) return

        const newProvidersOrder = reorderImmutable(providerList, source.index, destination.index)
        const plaintiffId = currentPlaintiff?.id
        dispatch({
          type: REORDER_PROVIDERS,
          payload: { providersOrder: newProvidersOrder, plaintiffId: plaintiffId },
        })
        const order = {}
        newProvidersOrder.forEach((providerId, index) => {
          // only set order for existing providers
          if (!providerId) return
          order[providerId] = index
        })
        orderProvidersMutation.mutate({ data: { provider_order: order, plaintiff_id: plaintiffId }, caseId })
      },
      [caseId, orderProvidersMutation, providerList, currentPlaintiff?.id, dispatch]
    )

    const handleCreateNewProvider = useCallback(async () => {
      let provider = null

      try {
        provider = await saveMutation.mutateAsync({
          caseId,
          data: { ...getNewProviderData(providerList.length), plaintiff_id: currentPlaintiff?.id },
        })
      } catch (error) {
        return showMessage({
          type: "error",
          message: PROVIDER_ERRORS_MESSAGES.PROVIDER_CREATING_ERROR,
          error,
        })
      }

      dispatch({ type: ADD_PROVIDER, payload: { provider, caseId } })
    }, [saveMutation, showMessage, caseId, providerList.length, currentPlaintiff?.id, dispatch])

    useEffect(() => {
      lastVisited.current = formSectionsToRoutes.providers
    })

    useEffect(() => {
      const currentProvider = useProvidersStore.getState().providers.get(activeProviderId)

      if (currentProvider) {
        activeProviderCodes.current = {
          icdCodes: createSetOfCodes(currentProvider.icd_codes),
          cptCodes: createSetOfCodes(currentProvider.cpt_codes),
        }
      }

      return () => {
        activeProviderCodes.current = null
      }
    }, [activeProviderId, providerList.length, useProvidersStore])

    useEffect(() => {
      exhibitBuilderActions.initialize({ documentId: caseObj?.document_id, caseId })
    }, [caseObj?.document_id, caseId])

    if (importingProviders || providersLoading) {
      return (
        <Box maxWidth="600px" margin="auto">
          <Typography>{importingProviders ? "Importing" : "Loading"} Providers ... </Typography>
          <Box mt={2}>
            <LinearProgress />
          </Box>
        </Box>
      )
    }

    return (
      <ProvidersStoreContext.Provider value={useProvidersStore}>
        <Box>
          <ReviewAlert
            isPageView={true}
            templateType={SECTIONS.PROVIDERS}
            plaintiffId={currentPlaintiff?.id}
          />
          <Box className={classes.summaryWrapper}>
            <Summary hasCollateralSourceRule={hasCollateralSourceRule} providerIds={providerList} />
            <Button variant="contained" startIcon={<Sort />} onClick={handleSortProviders} disableElevation>
              Sort by First Contact
            </Button>
          </Box>

          {providersError ? (
            <GenericError error={providersError} />
          ) : (
            !providersLoading && (
              <>
                <Box mb={2}>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="providerList">
                      {(droppableProvided, droppableSnapshot) => (
                        <Box {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                          {providerList.map((providerId, index) => (
                            <ProviderListItem
                              key={String(providerId)}
                              providerId={providerId}
                              active={activeProviderId === providerId}
                              index={index}
                              anyProviderBeingDragged={Boolean(droppableSnapshot.draggingFromThisWith)}
                              validationErrors={
                                isEmpty(validationErrors) ? EMPTY_VALIDATION_ERRORS : validationErrors
                              }
                              uploadExhibit={uploadExhibit}
                              onUpdate={handleUpdate}
                              onUpdateInBackground={handleUpdateInBackground}
                              onUpdateWhenBlurred={handleUpdateWhenBlurred}
                              onDelete={handleDelete}
                              onDeleteExhibit={handleExhibitDelete}
                              providerDataToFormData={providerDataToFormData}
                              hasCollateralSourceRule={hasCollateralSourceRule}
                              missingExhibits={missingExhibits}
                              updates={providerUpdates?.[providerId] ?? null}
                              documentStructureContentComponents={documentStructureContentComponents}
                            />
                          ))}
                          {droppableProvided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>
                <TextButton
                  onClick={handleCreateNewProvider}
                  disabled={Boolean(activeProviderId)}
                  data-test="add-provider"
                >
                  + Add Provider
                </TextButton>
                {Boolean(activeProviderId) && (
                  <Box ml={2}>
                    <Typography variant="caption" color="textSecondary">
                      Finish editing current provider to add a new one
                    </Typography>
                  </Box>
                )}
              </>
            )
          )}
        </Box>
      </ProvidersStoreContext.Provider>
    )
  })
)
