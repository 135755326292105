import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import MultiSelectAutoComplete, { MultiSelectAutoCompleteProps } from "evenup-ui/MultiSelectAutoComplete"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"
import { TAG_MAP } from "exhibit-builder/constants"

export function ExhibitTag() {
  const selectedTags = useExhibitBuilderStore(useShallow(state => state.filters.selectedExhibitTags))
  const tags = useExhibitBuilderStore(useShallow(filesSelectors.getExhibitTags))
  const options = useMemo(
    () =>
      Object.values(tags).map(tag => ({
        label: TAG_MAP[tag].label,
        value: tag,
      })),
    [tags]
  )

  const value = useMemo(
    () => options.filter(option => selectedTags?.includes(option.value)),
    [selectedTags, options]
  )

  const handleChange: MultiSelectAutoCompleteProps<(typeof options)[number]>["onChangeValue"] = options => {
    exhibitBuilderActions.selectTags(options.map(option => option.value))
  }

  return (
    <MultiSelectAutoComplete
      size="small"
      label="Exhibit Tags"
      value={value}
      options={options}
      onChangeValue={handleChange}
    />
  )
}
