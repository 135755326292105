import { useCallback, useEffect, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { Box, TextField, CircularProgress } from "@mui/material"
import { Cancel } from "@mui/icons-material"

import { providerInterventionalTreatmentService } from "api/services/provider-interventional-treatment"
import { InterventionalTreatment } from "api/services/provider-interventional-treatment/types"
import { useFormContext } from "demand/context"
import {
  Action,
  DELETE_INTERVENTIONAL_TREATMENT,
  UPDATE_INTERVENTIONAL_TREATMENT,
} from "demand/Providers/store/reducer"
import { Provider } from "demand/Providers/types"
import { DateField } from "common/form-components"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { ReferencePagesList, AddNewReference } from "./components"
import { InterventionalTreatmentEditableRow, DeleteInterventionalTreatmentButton } from "./styled"
import { NO_DESCRIPTION_INFO } from "./constants"
import { getInitialReferencePageState } from "./utils"
import { ReferencePage } from "common/types/providers"

export type InterventionalTreatmentFormProps = {
  interventionalTreatment: InterventionalTreatment
  dispatch: React.Dispatch<Action>
  provider: Provider
}

export const InterventionalTreatmentForm = ({
  interventionalTreatment,
  dispatch,
  provider,
}: InterventionalTreatmentFormProps) => {
  const providerId = provider.pk
  const [currentInterventionalTreatment, setCurrentInterventionalTreatment] =
    useState<InterventionalTreatment>(interventionalTreatment)
  const [references, setReferences] = useState<ReferencePage[]>(interventionalTreatment.referencePages)

  const { caseId } = useFormContext()
  const interventionalTreatmentId = interventionalTreatment.id
  const hasNoDescription = !currentInterventionalTreatment.treatment

  const { showErrorMessage } = useHandleMessages()

  const { mutate: updateInterventionalTreatment } = useMutation(
    providerInterventionalTreatmentService.updateProviderInterventionalTreatment,
    {
      onSuccess: data => {
        dispatch({
          type: UPDATE_INTERVENTIONAL_TREATMENT,
          payload: { providerId, interventionalTreatmentId, updates: data },
        })
      },
      onError: error => showErrorMessage({ error, message: "Error updating Objective Test." }),
    }
  )

  const { mutate: deleteInterventionalTreatment, isLoading: isDeletingInterventionalTreatment } = useMutation(
    providerInterventionalTreatmentService.deleteProviderInterventionalTreatment,
    {
      onSuccess: () => {
        dispatch({
          type: DELETE_INTERVENTIONAL_TREATMENT,
          payload: { providerId, interventionalTreatmentId },
        })
      },
      onError: error => showErrorMessage({ error, message: "Error deleting Objective Test." }),
    }
  )

  const handleBlur = useCallback(() => {
    updateInterventionalTreatment({
      data: currentInterventionalTreatment,
      options: { caseId, providerId, interventionalTreatmentId },
    })
  }, [
    updateInterventionalTreatment,
    currentInterventionalTreatment,
    caseId,
    providerId,
    interventionalTreatmentId,
  ])

  const handleDeleteInterventionalTreatment = useCallback(
    () => deleteInterventionalTreatment({ providerId, caseId, interventionalTreatmentId }),
    [deleteInterventionalTreatment, providerId, caseId, interventionalTreatmentId]
  )

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInterventionalTreatment(prevState => ({ ...prevState, treatment: e.target.value }))
  }

  const handleDateChange = (date: Nullable<string>) => {
    const updatedInterventionalTreatment = { ...currentInterventionalTreatment, date }
    setCurrentInterventionalTreatment(updatedInterventionalTreatment)

    // onBlur is not triggered when the date is changed in some scenarios, so we need to manually update
    updateInterventionalTreatment({
      data: updatedInterventionalTreatment,
      options: { caseId, providerId, interventionalTreatmentId },
    })
  }

  const handleUpdateReferencePages = useCallback(
    (newReferencePages: ReferencePage[]) => {
      updateInterventionalTreatment({
        data: { ...currentInterventionalTreatment, referencePages: newReferencePages },
        options: { caseId, providerId, interventionalTreatmentId },
      })
    },
    [
      updateInterventionalTreatment,
      caseId,
      providerId,
      interventionalTreatmentId,
      currentInterventionalTreatment,
    ]
  )

  const handleAddReference = useCallback(() => {
    dispatch({
      type: UPDATE_INTERVENTIONAL_TREATMENT,
      payload: {
        providerId,
        interventionalTreatmentId,
        updates: {
          ...interventionalTreatment,
          referencePages: [...interventionalTreatment.referencePages, getInitialReferencePageState()],
        },
      },
    })
  }, [providerId, interventionalTreatmentId, interventionalTreatment, dispatch])

  useEffect(() => {
    setReferences(
      interventionalTreatment.referencePages.length
        ? interventionalTreatment.referencePages
        : [getInitialReferencePageState()]
    )
  }, [interventionalTreatment])

  return (
    <InterventionalTreatmentEditableRow>
      <TextField
        onChange={handleDescriptionChange}
        value={currentInterventionalTreatment.treatment ?? ""}
        onBlur={handleBlur}
        disabled={isDeletingInterventionalTreatment}
      />
      <DateField
        onChange={handleDateChange}
        initialValue={currentInterventionalTreatment.date}
        disabled={isDeletingInterventionalTreatment}
      />

      <ReferencePagesList
        references={references}
        setReferences={setReferences}
        isDisabled={hasNoDescription}
        onUpdate={handleUpdateReferencePages}
        provider={provider}
      />

      <AddNewReference
        onAddReference={handleAddReference}
        isDisabled={hasNoDescription || isDeletingInterventionalTreatment}
        disabledTitle={NO_DESCRIPTION_INFO}
      />

      <Box>
        <DeleteInterventionalTreatmentButton
          disabled={isDeletingInterventionalTreatment}
          title={isDeletingInterventionalTreatment ? "Deleting..." : undefined}
          onClick={handleDeleteInterventionalTreatment}
        >
          {isDeletingInterventionalTreatment ? <CircularProgress color="inherit" size={20} /> : <Cancel />}
        </DeleteInterventionalTreatmentButton>
      </Box>
    </InterventionalTreatmentEditableRow>
  )
}
