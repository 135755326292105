import { USER_EXHIBIT_TAG } from "exhibit-builder/constants"
import { ExhibitFile, ExhibitPartition, MedicalBill, MedicalRecord, UserExhibit } from "../types"
import { FilesSlice } from "./filesSlice"

export const filesSelectors = {
  getRecordOrBillById: (id: (MedicalRecord | MedicalBill)["id"]) => (state: FilesSlice) =>
    state.recordsAndBillsMap[id],
  getUserExhibitById: (id: UserExhibit["id"]) => (state: FilesSlice) => state.userExhibitMap[id],
  getExhibitPartitionById: (id: ExhibitPartition["id"]) => (state: FilesSlice) =>
    state.exhibitPartitionMap[id],
  getExhibitFileById: (id: ExhibitFile["id"]) => (state: FilesSlice) => state.files[id],
  getExhibitTags: (state: FilesSlice): USER_EXHIBIT_TAG[] => {
    const tags = new Set<USER_EXHIBIT_TAG>()
    Object.values(state.userExhibitMap).forEach(userExhibit => {
      if (userExhibit.tag) {
        tags.add(userExhibit.tag)
      }
    })

    return [...tags]
  },
  getRecordsAndBillsByUserExhibitId: (id: UserExhibit["id"]) => (state: FilesSlice) => {
    const partitionIds = state.exhibitPartitionOrder[id]
    const recordsAndBills = [...(partitionIds ?? [])].flatMap(partitionId => [
      ...(state.recordsAndBillsOrder[partitionId] ?? []),
    ])
    return recordsAndBills
  },
  getUserExhibitFiles: (id: UserExhibit["id"]) => (state: FilesSlice) => {
    const partitionIds = state.exhibitPartitionOrder[id]
    const fileIds = new Set(
      [...(partitionIds ?? [])].flatMap(partitionId => [state.exhibitPartitionMap[partitionId].fileId])
    )
    return [...fileIds].map(fileId => state.files[fileId])
  },
  getUserExhibitPartitions: (id: UserExhibit["id"]) => (state: FilesSlice) => {
    const partitionIds = state.exhibitPartitionOrder[id]
    return [...(partitionIds ?? [])].map(partitionId => state.exhibitPartitionMap[partitionId])
  },

  getExhibitPartitionOrder: (exhibitPartitions: FilesSlice["exhibitPartitionMap"]) => {
    const exhibitPartitionOrder: FilesSlice["exhibitPartitionOrder"] = {}
    Object.values(exhibitPartitions).forEach(partition => {
      exhibitPartitionOrder[partition.userExhibitId] ??= []
      exhibitPartitionOrder[partition.userExhibitId]?.push(partition.id)
    })

    Object.values(exhibitPartitionOrder).forEach(partitionOrder => {
      partitionOrder?.sort((a, b) => exhibitPartitions[a].index - exhibitPartitions[b].index)
    })

    return exhibitPartitionOrder
  },
  getRecordUserExhibitPages:
    ({
      recordIds,
      partitionId,
      userExhibitId,
    }: {
      recordIds: MedicalRecord["id"][]
      partitionId: ExhibitPartition["id"]
      userExhibitId: UserExhibit["id"]
    }) =>
    (state: FilesSlice) => {
      const partition = state.exhibitPartitionMap[partitionId]
      const recordPagesMap = recordIds.reduce<{
        [key: string]: { id: string; startPage: number; endPage: number }
      }>((acc, recordId) => {
        const record = state.recordsAndBillsMap[recordId] as MedicalRecord
        if (!record) {
          return acc
        }
        const pageRange = record.userExhibitPages?.find(
          pageRange =>
            Number(pageRange.id) === Number(userExhibitId) &&
            Number(pageRange.partitionId) === Number(partitionId)
        )
        if (pageRange) {
          acc[recordId] = pageRange
        }
        return acc
      }, {})

      return {
        partition,
        recordPagesMap,
      }
    },
  getSortedRecordsAndBills: ({
    exhibitPartition,
    recordsAndBillsMap,
  }: {
    exhibitPartition: ExhibitPartition
    recordsAndBillsMap: FilesSlice["recordsAndBillsMap"]
  }) => {
    return [...exhibitPartition.medicalRecords, ...exhibitPartition.medicalBills].sort((a, b) => {
      const recordA = recordsAndBillsMap[a]
      const recordB = recordsAndBillsMap[b]

      if (!recordA || !recordB) {
        return 0
      }

      const startPageA = recordA.startPage
      const startPageB = recordB.startPage
      const endPageA = recordA?.endPage ?? startPageA
      const endPageB = recordB?.endPage ?? startPageB

      return startPageA - startPageB || endPageA - endPageB
    })
  },
}
