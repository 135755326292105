import React from "react"

import partition from "lodash/partition"

import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"

import { useDropzone, FileError, FileRejection } from "react-dropzone"
import { useDrop } from "react-dnd"

import clsx from "clsx"

import FileDropper from "./FileDropper"
import { FILE_TYPE } from "../../constants"
import { FileToUploadType } from "./interfaces"
import { ACCEPT_ALL_TYPE, ACCEPT_TYPE } from "./constants"
import { getFileTypeRestrictionText } from "./utils"

import { ValidateFileAlert, VALIDATION_MESSAGE_MAP } from "./ValidateFileAlert"
import { FILE_DROPPER } from "./constants"

export interface FileDropzoneProps {
  onDrop: (files: FileToUploadType[] | File[]) => void | Promise<void>
  children?: React.ReactNode
  acceptedFileTypes?: ACCEPT_TYPE
  disabled?: boolean
  className?: string
  validator?: (file: File) => FileError | Array<FileError> | null
  additionalFileRejections?: FileRejection[]
  clearAdditionalFileRejections?: () => void
  showHelperText?: boolean
}

const DEFAULT_VALIDATOR = () => null

const FileDropzone = ({
  onDrop,
  children,
  acceptedFileTypes = ACCEPT_ALL_TYPE,
  showHelperText = true,
  disabled = false,
  className,
  validator = DEFAULT_VALIDATOR,
  additionalFileRejections = [],
  clearAdditionalFileRejections = () => {}, // Consistent behavior with the file/folder flow for non-zipped files
}: FileDropzoneProps): JSX.Element => {
  // TODO, acceptedFileTypes is misleading cause you can only designate ONE type
  const accept = acceptedFileTypes === ACCEPT_ALL_TYPE ? undefined : { [acceptedFileTypes]: [] }

  const { getRootProps, getInputProps, open, isDragActive, isFocused, fileRejections, inputRef } =
    useDropzone({
      onDrop,
      disabled,
      useFsAccessApi: false,
      noClick: true,
      accept,
      validator,
    })

  const { ref: dropzoneRef } = getRootProps()
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: FILE_TYPE,
      drop: () => ({
        onDrop: (fileId: number, fileName: string, url: string): void => {
          onDrop([
            {
              questionnaireFileId: fileId,
              downloadUrl: url,
              file: null,
              name: fileName,
            },
          ])
        },
      }),
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  )

  const rootProps = getRootProps()
  const inputProps = getInputProps()
  const onChooseFileClick = () => {
    clearAdditionalFileRejections()

    inputRef.current?.removeAttribute("webkitdirectory")
    inputRef.current?.removeAttribute("directory")

    open && open()
  }

  const onChooseFolderClick = () => {
    clearAdditionalFileRejections()

    inputRef.current?.setAttribute("webkitDirectory", "")
    inputRef.current?.setAttribute("directory", "")

    open && open()
  }

  const allFileRejections = [...fileRejections, ...additionalFileRejections]

  const [alertBoxRejections, groupedRejections] = partition(allFileRejections, rejection => {
    const rejectionMessage = rejection.errors?.[0]?.message
    return Boolean(VALIDATION_MESSAGE_MAP[rejectionMessage])
  })

  return (
    <Box>
      <div
        {...rootProps}
        className={clsx(rootProps.className, className)}
        ref={el => {
          dropzoneRef.current = el
          drop(el)
        }}
      >
        <input {...inputProps} accept={acceptedFileTypes} data-test={FILE_DROPPER} />
        <FileDropper
          isDragActive={isDragActive || (isOver && canDrop)}
          isFocused={isFocused}
          disabled={disabled}
          fileTypes={acceptedFileTypes}
          onChooseFileClick={onChooseFileClick}
          onChooseFolderClick={onChooseFolderClick}
          showHelperText={showHelperText}
        >
          {!!children && (
            <>
              <Divider />
              {children}
            </>
          )}
        </FileDropper>
      </div>

      {alertBoxRejections.map((rejection, index) => (
        <ValidateFileAlert {...rejection} key={rejection.file.name + rejection.file.lastModified + index} />
      ))}

      {!!groupedRejections.length && (
        <Box mt={2}>
          <Alert severity="warning">
            <AlertTitle>
              {showHelperText && `${getFileTypeRestrictionText(acceptedFileTypes)} `}These files will be
              ignored:
            </AlertTitle>
            <ul>
              {groupedRejections.map(({ file }) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
          </Alert>
        </Box>
      )}
    </Box>
  )
}

export default FileDropzone
