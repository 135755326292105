import React, { useCallback, useEffect, useMemo, useState } from "react"
import Tooltip from "@mui/material/Tooltip"
import Box from "@mui/material/Box"

import { useMutation } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { getAnnotatedProviderIcdCodes, syncProviderIcdCodes } from "api"

import { Header } from "../styled"
import { IcdCodeFields } from "../../../types"
import { IcdCode } from "./types"
import { Content, IcdCodeContainer, SmallCodeChip } from "./styled"
import { Container } from "../Container"
import ErrorState from "../ErrorState"
import ContainerLabel from "../ContainerLabel"
import { PartitionEntry } from "../types"
import LoadingState from "./LoadingState"
import { Button } from "@mui/material"
import { Action } from "demand/Providers/store/reducer"
import { withSuspense } from "common/withSuspense"
import { useSuspenseQuery } from "@suspensive/react-query"

interface IcdCodesProps {
  caseId: number
  providerId: number
  currentIcdCodes: IcdCodeFields[]
  onEntryClick: (entry: PartitionEntry) => void
  dateUpdated?: string
  dispatch: React.Dispatch<Action>
  allowImport?: boolean
}

const IcdCodes = withSuspense(
  function IcdCodesComponent({
    providerId,
    caseId,
    currentIcdCodes,
    onEntryClick,
    dateUpdated,
    dispatch,
    allowImport = false,
  }: IcdCodesProps): JSX.Element {
    const [isDeviated, setIsDeviated] = useState<boolean>(false)

    const { data: icdCodes } = useSuspenseQuery<IcdCode[]>(
      [queryKeys.annotatedIcdCodes, caseId, providerId],
      async () => getAnnotatedProviderIcdCodes(caseId, providerId),
      { meta: SILENT_QUERY_PARAMS.meta }
    )

    const handleOnIcdClick = useCallback(
      (icdCode: IcdCode) => {
        onEntryClick({
          exhibitId: icdCode.entry.exhibit_id,
          partitionId: icdCode.entry.partition_id,
          page: icdCode.entry.page,
        })
      },
      [onEntryClick]
    )

    const checkForDeviation = useCallback(
      (annotatedIcdCodes: IcdCode[]) => {
        let isDeviated = false
        if (currentIcdCodes.length !== annotatedIcdCodes.length) {
          isDeviated = true
        }

        currentIcdCodes.forEach(({ code }) => {
          if (!annotatedIcdCodes.find(icdCode => icdCode.code === code)) {
            isDeviated = true
          }
        })

        setIsDeviated(isDeviated)
      },
      [currentIcdCodes]
    )

    const { isLoading: isImporting, mutateAsync: importICDs } = useMutation<IcdCode>({
      mutationFn: () => syncProviderIcdCodes(caseId, providerId),
      onSuccess: codes => {
        dispatch({
          type: "UPDATE_PROVIDER_FIELD",
          payload: {
            providerId,
            field: "icd_codes",
            value: codes,
          },
        })
      },
    })

    const handleImportAll = useCallback(async () => {
      importICDs()
    }, [importICDs])

    const areAnnotatedCodesInAllCurrentCodes = useMemo(
      () => annotatedCodesAllInCurrentCodes(icdCodes, currentIcdCodes),
      [icdCodes, currentIcdCodes]
    )

    useEffect(() => {
      checkForDeviation(icdCodes)
    }, [checkForDeviation, icdCodes, currentIcdCodes])

    if (!icdCodes.length) {
      return (
        <IcdCodeContainer>
          <Box display="flex" m={"auto"}>
            <Box>No Annotated ICD Codes</Box>
          </Box>
        </IcdCodeContainer>
      )
    }

    return (
      <Container>
        <Header>
          <Box>ICD Codes&#x3a;</Box>
          <ContainerLabel
            includeDeviation={isDeviated}
            includeAnnotationUpdated={!!dateUpdated}
            dateUpdated={dateUpdated}
          />
        </Header>
        <Content>
          {icdCodes?.map((icdCode: IcdCode, index) => {
            return (
              <Tooltip
                placement="bottom"
                arrow
                title={`${icdCode.code} - ${icdCode.description}`}
                key={index}
              >
                <SmallCodeChip
                  label={`${icdCode.code}`}
                  onClick={() => {
                    handleOnIcdClick(icdCode)
                  }}
                />
              </Tooltip>
            )
          })}
        </Content>
        {allowImport && (
          <Box sx={{ textAlign: "right", pt: 1 }}>
            <Button onClick={handleImportAll} disabled={isImporting || areAnnotatedCodesInAllCurrentCodes}>
              {isImporting ? "IMPORTING..." : "IMPORT CODES"}
            </Button>
          </Box>
        )}
      </Container>
    )
  },
  <LoadingState />,
  <IcdCodeContainer>
    <ErrorState message="There was an error retrieving annotated ICD Codes" />
  </IcdCodeContainer>
)

const annotatedCodesAllInCurrentCodes = (annotatedIcdCodes: IcdCode[], currentIcdCodes: IcdCodeFields[]) => {
  return annotatedIcdCodes.every(({ code }) => {
    return !!currentIcdCodes.find(icdCode => icdCode.code === code)
  })
}

export { IcdCodes as default }
