import { useCallback } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { queryClient } from "react-query/queryClient"

import { useHandleMessages } from "common/messages/useHandleMessages"
import { documentsService } from "api/services/documents"

type UseSyncWithDocumentStructureProps = {
  documentId?: string
  fetchOnMount?: boolean
}

export const useSyncWithDocumentStructure = ({
  documentId,
  fetchOnMount = true,
}: UseSyncWithDocumentStructureProps) => {
  const { showErrorMessage } = useHandleMessages()

  const {
    data,
    refetch: refetchMatchesDocumentStructure,
    isLoading: isDocumentMatchesDocumentStructureLoading,
  } = useQuery(
    [queryKeys.documentMatchesDocumentStructure, documentId],
    async () => {
      if (documentId) {
        return await documentsService.getMatchesDocumentStructure({ documentId })
      }
    },
    { enabled: fetchOnMount }
  )

  const {
    mutateAsync: syncWithDocumentStructureMutation,
    isLoading,
    error,
  } = useMutation(documentsService.syncWithDocumentStructure, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.case])
      refetchMatchesDocumentStructure()
    },
    onError: error => {
      showErrorMessage({
        message: "There was an error syncing with the document structure.",
        error,
      })
    },
  })

  const handleSyncDocumentStructure = useCallback(() => {
    if (documentId) {
      syncWithDocumentStructureMutation({ documentId })
    }
  }, [syncWithDocumentStructureMutation, documentId])

  return {
    isDocumentMatchesDocumentStructureLoading,
    isSyncDocumentStructureRequired:
      !data?.matchingDocumentStructure && !isDocumentMatchesDocumentStructureLoading,
    isLoading,
    error,
    handleSyncDocumentStructure,
  }
}
