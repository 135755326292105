import { v4 } from "uuid"

import { Provider } from "demand/Providers/types"
import { ReferencePage } from "common/types/providers"
import { LOCAL_REFERENCE_PAGE_ID } from "./constants"
import { ExhibitBuilderData } from "exhibit-builder/store/types"

export const getInitialReferencePageState = (): ReferencePage => ({
  id: `${LOCAL_REFERENCE_PAGE_ID}${v4()}`,
  exhibitId: null,
  partitionId: null,
  pageNumber: 0,
  userExhibitId: null,
})

export const getExhibitReferencesSummary = ({
  referencePages,
  providerExhibits,
  providerExhibitPartitions,
  userExhibits,
}: {
  referencePages: ReferencePage[]
  providerExhibits?: Provider["exhibits"]
  providerExhibitPartitions?: Provider["exhibit_partitions"]
  userExhibits: ExhibitBuilderData["userExhibitMap"]
}) => {
  return (
    referencePages
      .map(reference => {
        if (reference.exhibitId) {
          const exhibit = providerExhibits?.find(exhibit => exhibit.pk === reference.exhibitId)
          return `${exhibit?.name}: p.\u00A0${reference.pageNumber || "N/A"}`
        }

        if (reference.partitionId) {
          const exhibitPartition = providerExhibitPartitions?.find(
            partition => partition.pk === reference.partitionId
          )
          return `${exhibitPartition?.name} - p.\u00A0${reference.pageNumber || "N/A"}`
        }

        if (reference.userExhibitId) {
          const userExhibit = userExhibits?.[reference.userExhibitId]
          return `${userExhibit?.name} - p.\u00A0${reference.pageNumber || "N/A"}`
        }

        return ""
      })
      .join(", ") ?? "None"
  )
}
