import { memo, useCallback, useMemo } from "react"
import TextButton from "common/buttons/TextButton"
import { makeStyles } from "tss-react/mui"
import Tooltip from "@mui/material/Tooltip"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import HelpOutline from "@mui/icons-material/HelpOutline"
import { useFormContext } from "demand/context"
import { FixedSizeList } from "react-window"
import { ADD_BILL, BILL_EXHIBIT_CHANGE } from "demand/Providers/store/reducer"

import { decodeExhibitValue, getBillExhibitValue } from "./utils"
import { SectionHeading } from "../../styled"
import { ExhibitSelect } from "./ExhibitSelect"
import { BillRow } from "./BillRow"
import Bills from "../../Annotation/Bills/Bills"
import SectionContainer from "../../SectionContainer"
import { BillSelectionOptions } from "demand/Providers/Provider/ProviderForm/BillsSection/BillSelectionOptions"
import { useExhibitBuilderStore } from "exhibit-builder/store"

const MAX_BILLS_QTY_ON_PAGE = 10
const MAX_BILLS_HEIGHT = 540
const BILL_ROW_HEIGHT = 64

const useStyles = makeStyles()(theme => ({
  helpIcon: {
    fontSize: "1rem",
    verticalAlign: "text-bottom",
  },
  billsGrid: {
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
  },
  inputLabelText: {
    fontSize: "13px",
    fontWeight: 600,
  },
  billsSelect: {
    alignSelf: "flex-start",
    width: "30%",
  },
}))

const EMPTY_VALIDATION_ERRORS = {}

const BillRenderer = memo(function BillRenderer({
  data: {
    validationErrors,
    disabled,
    dispatch,
    provider,
    hasCollateralSourceRule,
    handleExhibitChange,
    classes,
    hasExhibitBuilderFiles,
  },
  index,
  style,
}) {
  const bill = provider.bills?.[index]
  const billId = bill.pk ?? bill.formId

  const section = useMemo(
    () =>
      billId ? (
        <ExhibitSelect
          value={getBillExhibitValue(bill)}
          provider={provider}
          onChange={handleExhibitChange(bill, index)}
          disabled={
            disabled ||
            (!provider?.filesToUpload?.length && !provider?.exhibits?.length && !hasExhibitBuilderFiles)
          }
          error={!!validationErrors["bills"]?.[index]?.exhibit_id}
          helperText={validationErrors["bills"]?.[index]?.exhibit_id}
          className={classes.billsSelect}
        />
      ) : null,
    [
      billId,
      provider,
      handleExhibitChange,
      validationErrors,
      bill,
      disabled,
      index,
      classes.billsSelect,
      hasExhibitBuilderFiles,
    ]
  )

  if (!billId) return null

  return (
    <Box className={classes.billsGrid} style={style}>
      <BillRow
        key={billId}
        bill={bill}
        validationErrors={validationErrors["bills"]?.[index] || EMPTY_VALIDATION_ERRORS}
        disabled={disabled}
        dispatch={dispatch}
        providerId={provider.pk}
        hasCollateralSourceRule={hasCollateralSourceRule}
        exhibitSection={section}
      />
    </Box>
  )
})

export const BillsSection = ({
  validationErrors,
  dispatch,
  disabled,
  provider,
  hasCollateralSourceRule,
  onAnnotationClick,
  annotationDateUpdated = "",
}) => {
  const { classes } = useStyles()
  const { caseId } = useFormContext()
  const hasPartitionProvider = !!provider.partition_provider
  const hasExhibitBuilderFiles = useExhibitBuilderStore(state => Object.values(state.files).length)

  const handleExhibitChange = useCallback(
    (bill, index) => event => {
      const [type, id] = decodeExhibitValue(event.target.value)

      dispatch({
        type: BILL_EXHIBIT_CHANGE,
        payload: { bill, index, type, id, providerId: provider.pk },
      })
    },
    [dispatch, provider.pk]
  )

  const handleBillAdd = useCallback(() => {
    dispatch({
      type: ADD_BILL,
      payload: { caseId, providerId: provider.pk },
    })
  }, [dispatch, caseId, provider.pk])

  const itemData = useMemo(
    () => ({
      validationErrors,
      disabled,
      dispatch,
      provider,
      hasCollateralSourceRule,
      handleExhibitChange,
      classes,
      hasExhibitBuilderFiles,
    }),
    [
      validationErrors,
      disabled,
      dispatch,
      provider,
      hasCollateralSourceRule,
      handleExhibitChange,
      classes,
      hasExhibitBuilderFiles,
    ]
  )

  return (
    <>
      <SectionContainer>
        <Box width="100%">
          <SectionHeading mb={1}>Bills</SectionHeading>
          {Boolean(provider.bills?.length) && (
            <>
              <BillSelectionOptions bills={provider.bills} dispatch={dispatch} providerId={provider.pk} />
              <Box className={classes.billsGrid}>
                <Box width={45}></Box>
                <Box flexGrow={1} className={classes.inputLabelText}>
                  Bill description
                </Box>
                <Box width={150} className={classes.inputLabelText}>
                  Amount billed
                </Box>
                {!hasCollateralSourceRule && (
                  <Box width={150} className={classes.inputLabelText}>
                    Adjustment amount
                  </Box>
                )}
                <Box width="30%" className={classes.inputLabelText}>
                  Related file{" "}
                  <Tooltip
                    placement="top"
                    arrow
                    title="Upload documents in the section above in order to select from this dropdown."
                  >
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Box>
                <Box width={40} />
              </Box>
              <FixedSizeList
                height={
                  provider.bills.length > MAX_BILLS_QTY_ON_PAGE
                    ? MAX_BILLS_HEIGHT
                    : provider.bills.length * BILL_ROW_HEIGHT
                }
                itemCount={provider.bills.length}
                itemSize={64}
                itemData={itemData}
              >
                {BillRenderer}
              </FixedSizeList>
            </>
          )}
          <TextButton onClick={handleBillAdd} disabled={disabled} data-test="add-new-bill">
            + Add New Bill
          </TextButton>
        </Box>
        {hasPartitionProvider && (
          <Box>
            <Bills
              caseId={caseId}
              providerId={provider.pk}
              currentBills={provider.bills ?? []}
              onEntryClick={onAnnotationClick}
              dateUpdated={annotationDateUpdated}
              allowImport
              dispatch={dispatch}
            />
          </Box>
        )}
      </SectionContainer>
      <Box my={6}>
        <Divider />
      </Box>
    </>
  )
}
