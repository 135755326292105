import React from "react"
import { DOCUMENT_GENERATION_STATUS, DocumentGenerationEntity } from "api/services/documents/types"
import { theme } from "app/theme"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { ErrorMessageCell, StyledStatusCell } from "./styled"
import { Tooltip } from "@mui/material"
import { DEFAULT_ERROR_MESSAGE, UNKNOWN_STATUS_MESSAGE } from "./constants"

export const getDocumentStatusCell = (generation: DocumentGenerationEntity): React.ReactNode => {
  const { status } = generation

  if (status === DOCUMENT_GENERATION_STATUS.NOT_STARTED) {
    return (
      <StyledStatusCell>
        <InfoOutlinedIcon htmlColor={theme.palette.blue.info} />
        Document generation in process.
      </StyledStatusCell>
    )
  }

  if (status === DOCUMENT_GENERATION_STATUS.COMPLETE) {
    return (
      <StyledStatusCell>
        <CheckCircleOutlineIcon htmlColor={theme.palette.success.light} />
        Success. Ready for download.
      </StyledStatusCell>
    )
  }

  if (status === DOCUMENT_GENERATION_STATUS.IN_PROGRESS) {
    return (
      <StyledStatusCell>
        <InfoOutlinedIcon htmlColor={theme.palette.blue.info} />
        Document generation in process.
      </StyledStatusCell>
    )
  }

  if (status === DOCUMENT_GENERATION_STATUS.ERROR) {
    const errorMessage = generation.errorMessage || DEFAULT_ERROR_MESSAGE
    return (
      <StyledStatusCell>
        <ErrorOutlineIcon htmlColor={theme.palette.red.warning} />
        <Tooltip title={errorMessage}>
          <ErrorMessageCell>{errorMessage}</ErrorMessageCell>
        </Tooltip>
      </StyledStatusCell>
    )
  }

  return <div>{UNKNOWN_STATUS_MESSAGE}</div>
}
