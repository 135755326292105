import React, { useState, useCallback, useEffect, memo, CSSProperties, useMemo } from "react"
import Box from "@mui/material/Box"
import { isEqual } from "date-fns"

import { useMutation, useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { getAnnotatedProviderAppointments, syncProviderAppointments } from "api"

import Cell from "./Cell"
import { Header, useStyles } from "../styled"
import { Appointment } from "./types"
import { Content, ContentHeader, Row } from "./styled"
import { Container } from "../Container"
import ErrorState from "../ErrorState"

import ContainerLabel from "../ContainerLabel"
import { PartitionEntry } from "../types"
import LoadingState from "./LoadingState"
import { FixedSizeList } from "react-window"
import {
  Action,
  UPDATE_FIRST_CONTACT,
  UPDATE_LAST_CONTACT,
  UPDATE_VISIT_COUNT,
} from "demand/Providers/store/reducer"
import { useDialog } from "hooks/useDialog"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { Close } from "@mui/icons-material"
import { Provider } from "demand/Providers/types"

interface AppointmentsProps {
  caseId: number
  providerId: number
  currentFirstAppointment: string
  currentLastAppointment: string
  currentNumberOfVisits: number
  onEntryClick: (entry: PartitionEntry) => void
  dateUpdated?: string
  allowImport?: boolean
  dispatch?: React.Dispatch<Action>
}

interface AppointmentRendererDataProps {
  appointments: Appointment[]
  onEntryClick: (entry: PartitionEntry) => void
}

interface AppointmentRendererProps {
  data: Required<AppointmentRendererDataProps>
  index: number
  style: CSSProperties
}

const AppointmentRenderer = memo(function AppointmentRenderer({
  data: { appointments, onEntryClick },
  index,
  style,
}: AppointmentRendererProps) {
  const { classes, cx } = useStyles()
  const { bill, record } = appointments[index]

  return (
    <Row
      style={style}
      key={`appointment_${index}`}
      className={cx(index % 2 ? classes.lightRow : classes.darkRow)}
    >
      <Cell date={bill.date} entries={bill.entries} onEntryClick={onEntryClick}></Cell>
      <Cell date={record.date} entries={record.entries} onEntryClick={onEntryClick}></Cell>
    </Row>
  )
})

const Appointments = ({
  providerId,
  caseId,
  currentFirstAppointment,
  currentLastAppointment,
  currentNumberOfVisits,
  onEntryClick,
  dateUpdated,
  allowImport,
  dispatch,
}: AppointmentsProps): JSX.Element => {
  const [appointments, setAppointments] = useState<Appointment[]>([])
  const [isDeviated, setIsDeviated] = useState<boolean>(false)
  const { isOpen: isConfirmOpen, closeDialog: closeConfirm, openDialog: openConfirm } = useDialog()

  const itemData = useMemo(
    () => ({
      appointments,
      onEntryClick,
    }),
    [appointments, onEntryClick]
  )

  const checkForDeviation = useCallback(
    (annotatedAppointments: Appointment[]) => {
      if (annotatedAppointments.length !== currentNumberOfVisits) {
        return setIsDeviated(true)
      }

      if (!annotatedAppointments?.length) {
        return setIsDeviated(false)
      }

      const firstDate = annotatedAppointments[0].bill.date || annotatedAppointments[0].record.date || ""
      if (currentNumberOfVisits === 1) {
        if (
          currentLastAppointment ||
          !isEqual(new Date(firstDate), new Date(currentFirstAppointment ?? ""))
        ) {
          return setIsDeviated(true)
        }

        return setIsDeviated(false)
      }

      const lastIndex = annotatedAppointments.length - 1
      const lastDate =
        annotatedAppointments[lastIndex].bill.date || annotatedAppointments[lastIndex].record.date || ""

      if (!isEqual(new Date(lastDate), new Date(currentLastAppointment ?? ""))) {
        return setIsDeviated(true)
      }

      setIsDeviated(false)
    },
    [currentFirstAppointment, currentLastAppointment, currentNumberOfVisits]
  )

  const { isFetching, isError } = useQuery(
    [queryKeys.annotatedAppointments, caseId, providerId],
    async () => {
      return await getAnnotatedProviderAppointments(caseId, providerId)
    },
    {
      meta: {
        disableLoader: true,
      },
      onSuccess: response => {
        setAppointments(response)
      },
    }
  )

  const { isLoading: isImporting, mutateAsync: importAppointments } = useMutation<Provider>({
    mutationFn: () => syncProviderAppointments(caseId, providerId),
    onSuccess: provider => {
      if (dispatch) {
        // react should batch these so it should be fine to dispatch multiple times
        dispatch({
          type: UPDATE_FIRST_CONTACT,
          payload: { providerId, firstContact: provider.first_contact ?? null },
        })
        dispatch({
          type: UPDATE_LAST_CONTACT,
          payload: { providerId, lastContact: provider.last_contact ?? null },
        })
        dispatch({
          type: UPDATE_VISIT_COUNT,
          payload: { providerId, visitCount: provider.visit_count ?? null },
        })
      }
    },
  })

  const handleImportAll = useCallback(async () => {
    if (currentFirstAppointment || currentLastAppointment || currentNumberOfVisits) {
      openConfirm()
      return
    }
    importAppointments()
  }, [
    currentFirstAppointment,
    currentLastAppointment,
    currentNumberOfVisits,
    openConfirm,
    importAppointments,
  ])

  const handleConfirm = useCallback(async () => {
    await importAppointments()
    closeConfirm()
  }, [closeConfirm, importAppointments])

  useEffect(() => {
    checkForDeviation(appointments)
  }, [
    currentFirstAppointment,
    currentLastAppointment,
    currentNumberOfVisits,
    checkForDeviation,
    appointments,
  ])

  if (isFetching) {
    return <LoadingState />
  }

  if (isError) {
    return (
      <Container>
        <ErrorState message="There was an error retrieving annotated appointments" />
      </Container>
    )
  }

  if (!isFetching && !appointments.length) {
    return (
      <Container>
        <Box display="flex" m={"auto"}>
          <Box>No Annotated Appointments</Box>
        </Box>
      </Container>
    )
  }

  return (
    <Container>
      <Header>
        <Box>Appointments - No. of Visits {appointments.length}</Box>
        <ContainerLabel
          includeDeviation={isDeviated}
          includeAnnotationUpdated={!!dateUpdated}
          dateUpdated={dateUpdated}
        />
      </Header>
      <Content>
        <ContentHeader>
          <Box>Bills</Box>
          <Box>Records</Box>
        </ContentHeader>

        <FixedSizeList
          height={appointments.length > 10 ? 230 : appointments.length * 22}
          width="100%"
          itemCount={appointments.length}
          itemSize={22}
          itemData={itemData}
        >
          {AppointmentRenderer}
        </FixedSizeList>
      </Content>
      {allowImport && (
        <Box sx={{ textAlign: "right", pt: 1 }}>
          <Button onClick={handleImportAll} disabled={isImporting || !isDeviated}>
            {isImporting ? "IMPORTING..." : "IMPORT APPOINTMENTS"}
          </Button>
          <AppointmentsConfirmDialog
            isOpen={isConfirmOpen}
            onClose={closeConfirm}
            onConfirm={handleConfirm}
            disabled={isImporting}
          />
        </Box>
      )}
    </Container>
  )
}

const AppointmentsConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  disabled,
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  disabled: boolean
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          <Box>Overwrite Appointment Data?</Box>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>This will overwrite current appointment dates and number of visits.</DialogContent>
      <DialogActions>
        <Button disabled={disabled} onClick={onClose} variant="contained" color="inherit">
          Cancel
        </Button>
        <Button disabled={disabled} onClick={onConfirm} variant="contained" color="secondary">
          {disabled ? "Overwriting..." : "Overwrite"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export { Appointments as default }
