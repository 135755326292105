import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import useUser from "../../hooks/useUser"
import { makeStyles } from "tss-react/mui"
import { Link, useLocation } from "react-router-dom"
import { USER_ROLES } from "../../common/models/roles"
import { useState, useEffect } from "react"
import { EmptyResponse, REQUEST_METHODS, makeApiCall } from "apiHelper"
import { useMutation } from "@tanstack/react-query"
import { parseError } from "common/errorUtils"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { usePermissions } from "permissions/usePermissions"

const useStyles = makeStyles()(theme => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  user: {
    padding: theme.spacing(2, 0),
    width: theme.spacing(60),
  },
  actions: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
    marginTop: theme.spacing(2),
  },
}))

interface MicrosoftSSOResponse {
  redirect_url: string
}

export const linkMicrosoftSSO = async () => {
  const options = {
    path: "/users/microsoft_mapping/",
    method: REQUEST_METHODS.PUT,
  }

  return makeApiCall<MicrosoftSSOResponse>(options)
}

export function Profile() {
  const { user } = useUser()
  const { classes } = useStyles()
  const [isLinking, setIsLinking] = useState(false)
  const { showErrorMessage } = useHandleMessages()
  const location = useLocation()
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const { canMapStytchMicrosoftAccountEnabled } = usePermissions({
    suspense: false,
    enabled: user.isAuthorized,
    firmId: user.firmId,
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get("successMicrosoftSSO") !== null) {
      setShowSuccessMessage(true)

      searchParams.delete("successMicrosoftSSO")
      const remainingParams = searchParams.toString()
      const newPath = remainingParams ? `${location.pathname}?${remainingParams}` : location.pathname
      window.history.replaceState({}, "", newPath)
    }
  }, [location])

  const linkMicrosoftSSOMutation = useMutation(linkMicrosoftSSO, {
    onSuccess: (data: MicrosoftSSOResponse | EmptyResponse) => {
      if ("redirect_url" in data && data.redirect_url) {
        window.location.href = data.redirect_url
      }
    },
    onError: (error: unknown) => {
      const parsedError = parseError(error)
      let message = "Failed to link with Microsoft SSO. "
      if (parsedError && parsedError.validationErrors) {
        message += `Error: ${JSON.stringify(parsedError.validationErrors)}`
      }
      showErrorMessage(message)
    },
    onSettled: () => {
      setIsLinking(false)
    },
  })

  const handleMicrosoftSSO = () => {
    setIsLinking(true)
    linkMicrosoftSSOMutation.mutate()
  }

  return (
    <>
      {showSuccessMessage && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setShowSuccessMessage(false)}>
          Microsoft SSO linked successfully!
        </Alert>
      )}
      <Box className={classes.user}>
        <Box className={classes.header}>
          <Box>
            <Typography variant="h5" component="h2">
              {user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : "(No Name)"}
            </Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>
          <Chip label={USER_ROLES[user.role].display} />
        </Box>
        <Box className={classes.actions}>
          <Button color="primary" variant="outlined" component={Link} to="/settings/profile/change-email">
            Change Email
          </Button>
          {canMapStytchMicrosoftAccountEnabled && (
            <Button color="primary" variant="outlined" onClick={handleMicrosoftSSO} disabled={isLinking}>
              {isLinking ? "Linking..." : "Link with Microsoft SSO"}
            </Button>
          )}
        </Box>
      </Box>
    </>
  )
}
