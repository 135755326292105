import { FileToUploadType } from "common/form-components/files/interfaces"
import { FileStatus, RequestFileToUpload } from "../types"
import { FILE_CATEGORY_OPTIONS, FILE_TYPES } from "common/constants"
import { UseFileUploaderReturn } from "common/file-uploader"
import { SaveFileMutation } from "./types"
import {
  UNSUPPORTED_FILE_TYPE_ERROR,
  VALIDATION_MESSAGE_MAP,
} from "common/form-components/files/ValidateFileAlert"

// Lifted from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export function humanFileSize(bytes: number, si = false, dp = 1): string {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + " " + units[u]
}

function getDocumentType(file: FileToUploadType) {
  if (file.documentType && file.documentType in FILE_CATEGORY_OPTIONS) {
    return file.documentType as FILE_CATEGORY_OPTIONS
  }

  return FILE_CATEGORY_OPTIONS.OTHER
}

export function convertDroppedFileToRequestFile(newFile: FileToUploadType | File): RequestFileToUpload {
  const defaultValues = { status: FileStatus.Processing, error: "" }
  if ("file" in newFile) {
    if (!newFile.file) {
      throw new Error(
        "Error converting dropped file to a request file.  new file has no file or document type"
      )
    }

    return {
      ...defaultValues,
      file: newFile.file,
      fileType: getDocumentType(newFile),
    }
  }

  return {
    ...defaultValues,
    file: newFile,
    fileType: FILE_CATEGORY_OPTIONS.OTHER,
  }
}

export function isZipFile(file: File) {
  return FILE_TYPES.ZIP.split(", ").includes(file.type) || file.name.endsWith(".zip")
}

export async function uploadFiles(
  filesToUpload: RequestFileToUpload[],
  saveFileMutation: SaveFileMutation,
  fileUploader: UseFileUploaderReturn,
  requestId: string,
  handleUpdateFiles: (files: RequestFileToUpload[]) => void,
  setUploadingFiles: (value: boolean) => void
) {
  setUploadingFiles(true)

  try {
    const { hasFailures, items: uploadedFiles } = await fileUploader.uploadFiles(
      filesToUpload.map(item => item.file),
      { skipInfoMessages: true }
    )

    if (hasFailures) {
      const failedFiles = uploadedFiles.filter(file => !file.success)

      const unhandledFailedFiles = failedFiles.filter(
        file => file.reason && !VALIDATION_MESSAGE_MAP[file.reason.toString()]
      )

      fileUploader.showUploadErrors(unhandledFailedFiles)
    }
    const errorFiles = filesToUpload
      .map((fileToUpload, idx) => ({ fileToUpload: fileToUpload, uploadedFile: uploadedFiles[idx] }))
      .filter(checkedFile => !checkedFile.uploadedFile.success)

    handleUpdateFiles(
      errorFiles.map(errorFile => ({
        ...errorFile.fileToUpload,
        status: FileStatus.UploadError,
        error: errorFile.uploadedFile.reason?.toString() || "",
      }))
    )

    const mutationResults = await Promise.allSettled(
      filesToUpload.map(async (fileToUpload, idx) => {
        const uploadResult = uploadedFiles[idx]

        if (!uploadResult.success) {
          return
        }

        const { file, fileType } = fileToUpload
        const formData = new FormData()
        const uploadId = uploadResult.uploadId

        formData.append("upload_id", uploadId)
        formData.append("name", file.name)
        formData.append("type", fileType)

        const response = await saveFileMutation.mutateAsync({
          requestId: requestId,
          data: formData,
          name: file.name,
        })
        const savedFileResult = await response.json()
        fileUploader.assignUploaded(uploadId, savedFileResult.pk)
      })
    )

    // These are the files that failed to upload - most likely from the saveFileMutation call
    const failedFiles = filesToUpload.filter((_, idx) => mutationResults[idx]?.status === "rejected")

    const successfulFiles = filesToUpload.filter(
      (_, idx) => uploadedFiles[idx]?.success && mutationResults[idx]?.status === "fulfilled"
    )

    const updatedFiles = [
      ...failedFiles.map(fileToUpload => {
        const idx = filesToUpload.indexOf(fileToUpload)
        const result = mutationResults[idx] as PromiseRejectedResult

        const error = result.reason?.message.includes("Unsupported File Format")
          ? UNSUPPORTED_FILE_TYPE_ERROR
          : "An unknown error occured"

        return {
          ...fileToUpload,
          status: FileStatus.UploadError,
          error,
        }
      }),
      ...successfulFiles.map(fileToUpload => ({
        ...fileToUpload,
        status: FileStatus.UploadComplete,
      })),
    ]

    handleUpdateFiles(updatedFiles)
  } catch {
    filesToUpload.forEach(
      fileToUpload =>
        fileToUpload.status === FileStatus.Uploading &&
        handleUpdateFiles([
          {
            ...fileToUpload,
            status: FileStatus.UploadError,
            error: "An unknown error occured while uploading this file",
          },
        ])
    )
  } finally {
    setUploadingFiles(false)
  }
}
