import { useEffect, useMemo, useState } from "react"
import MuiTabs from "@mui/material/Tabs"
import MuiTab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { TabContext, TabPanel as MuiTabPanel } from "@mui/lab"
import styled from "@emotion/styled"

import { useQuery } from "@tanstack/react-query"
import { Navigate, useNavigate, useParams } from "react-router-dom"

import { Request } from "./Request"
import MissingDocumentClientView from "./MissingDocumentClientView"

import {
  getCaseByRequestId,
  getMissingExhibitEvents,
  getCurrentMissingExhibits,
  getMissingExhibits,
} from "../../api"
import { SILENT_QUERY_PARAMS, queryKeys } from "../../react-query/constants"
import useUser from "../../hooks/useUser"
import {
  canMissingDocumentsBeEdited,
  canUserViewMissingDocs,
  canUserViewRevisions,
} from "../permissions/requestAction"
import { MissingExhibit, MissingExhibitEvent } from "../../missing-docs/interfaces"
import { formatTimeSinceNow } from "../../utils"
import { useHandleMessages } from "../../common/messages/useHandleMessages"
import { Helmet } from "react-helmet"
import { REQUEST_VIEW_TABS } from "./enums"
import { REQUEST_VIEW_HISTORY, REQUEST_VIEW_URL_PREFIX } from "./constants"
import { RequestViewDto } from "./types"
import { RevisionRequestView } from "./Revision/RevisionRequestView"
import { revisionEventService } from "api/services/revision-event"
import { canUserViewRevisionsTab, isRequestDemandType, isRequestMedChronType } from "./utils"
import { INSTRUCTIONS } from "missing-docs/constants"
import { RequestTypeBadge } from "./RequestTypeBadge"
import { withErrorBoundary } from "react-error-boundary"
import { Case as CaseDemand } from "case/types"
import { useSuspenseQueries } from "@suspensive/react-query"
import invariant from "invariant"
import { isNotOSF } from "common/permission"
import { isNil } from "lodash"
import { ApiError } from "apiHelper"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { CaseAnalyticEvent, CaseAnalyticsEventTypes } from "infrastructure/apm/events/caseEvents"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { getFirstPlaintiffName } from "requests/plaintiffUtils"
import { usePermissions } from "permissions/usePermissions"
import { Button, Typography } from "@mui/material"
import { ViewCaseButton } from "requests/ViewRequest/components/ViewCaseButton"
import { DOCUMENT_TYPES } from "requests/enums"
import { fetchCaseFlags } from "cases/api/caseView"
import { CaseFlagDto } from "cases/types/caseFlags"
import EditIcon from "@mui/icons-material/Edit"
import { parseError } from "common/errorUtils"
import { ViewRequestFallback } from "./ViewRequestFallback"
import { fetchRequest } from "api/services/request/api"
import { permissionService } from "api/services/permissions"
import { withSuspense } from "common/withSuspense"
import { RequestSkeleton } from "requests/RequestSkeleton"
import { theme } from "app/theme"

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}))

const TabPanel = styled(MuiTabPanel)(() => ({
  padding: 0,
}))

interface Props {
  tab?: REQUEST_VIEW_TABS
}

function getRequestViewUrl(tab: REQUEST_VIEW_TABS, useRelativeUrl = false) {
  const url = useRelativeUrl ? window.location.pathname : window.location.href
  const baseUrl = url
    .replace(`/${REQUEST_VIEW_URL_PREFIX.MISSING_DOCS}`, "")
    .replace(`/${REQUEST_VIEW_URL_PREFIX.REVISION}`, "")
  const urlPath = REQUEST_VIEW_URL_PREFIX[tab] ? `/${REQUEST_VIEW_URL_PREFIX[tab]}` : ""

  return `${baseUrl}${urlPath}`
}

export function getPrefetchQueries(requestId: string, firmId: string, userId: number) {
  return [
    {
      queryKey: [queryKeys.case, requestId],
      queryFn: getCaseByRequestId,
    },
    {
      queryKey: [queryKeys.request, requestId],
      queryFn: fetchRequest,
    },
    {
      queryKey: [queryKeys.allPermissions, firmId],
      queryFn: () => permissionService.checkPermissions({ userId: userId, firmId: firmId }),
    },
  ]
}

export const ViewRequest = withSuspense(
  withErrorBoundary(
    ({ tab = REQUEST_VIEW_TABS.REQUEST }: Props): JSX.Element => {
      const { id: requestId } = useParams()
      const { user } = useUser()
      const navigate = useNavigate()

      invariant(!!requestId, "requestId should be defined")

      // Request and case data, dependencies for other queries
      // It is worth showing skeleton while waiting for data
      const [{ data: caseDemandData }, { data: request }] = useSuspenseQueries({
        queries: [
          {
            queryKey: [queryKeys.case, requestId],
            queryFn: getCaseByRequestId as () => Promise<Nullable<CaseDemand>>,
            ...SILENT_QUERY_PARAMS,
            retry: false,
          },
          {
            queryKey: [queryKeys.request, requestId],
            queryFn: fetchRequest as () => Promise<RequestViewDto>,
            ...SILENT_QUERY_PARAMS,
            retry: false,
          },
        ],
      })

      useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const { fullName: plaintiffName } = getFirstPlaintiffName(request)
        amplitudeApm.trackEvent(
          new RequestAnalyticEvent(RequestAnalyticsEventTypes.ViewedRequest, {
            plaintiff_name: plaintiffName,
            request_id: requestId,
            request_type: request.type,
            utm_campaign: params.get("utm_campaign") ?? undefined,
            utm_medium: params.get("utm_medium") ?? undefined,
          })
        )
      }, [request, requestId])

      const caseId = request?.matter_id
      const caseDemandId = caseDemandData?.pk
      const hasCaseDemandId = !isNil(caseDemandId)
      const canViewRevisions = canUserViewRevisions(user.role)
      const canUserViewMissingDocsPermission: boolean = canUserViewMissingDocs(user.role)

      // Depends on request query
      const { data: revisionRequestEvents, isFetching: areRevisionsLoading } = useQuery(
        [queryKeys.requestRevisionEvents, requestId],
        () => revisionEventService.getRevisionRequestEvents({ requestId }),
        {
          enabled: canViewRevisions && Boolean(request.revised),
        }
      )

      const showRevisionsTab = canUserViewRevisionsTab(
        request,
        user,
        revisionRequestEvents,
        areRevisionsLoading
      )

      const permissions: Record<REQUEST_VIEW_TABS, boolean> = useMemo(
        () => ({
          [REQUEST_VIEW_TABS.REQUEST]: true,
          [REQUEST_VIEW_TABS.MISSING_DOCS]: canUserViewMissingDocsPermission,
          [REQUEST_VIEW_TABS.REVISION]: showRevisionsTab,
        }),
        [canUserViewMissingDocsPermission, showRevisionsTab]
      )

      const [currentTab, setCurrentTab] = useState<REQUEST_VIEW_TABS>(tab)

      useEffect(() => {
        setCurrentTab(permissions[tab] ? tab : REQUEST_VIEW_TABS.REQUEST)
      }, [tab, permissions])

      const canEditMissingDocuments = canMissingDocumentsBeEdited(request)
      // Depends on case query
      const { data: communicationEvents } = useQuery<MissingExhibitEvent[]>(
        [queryKeys.missingExhibitEvents, caseDemandId],
        () => getMissingExhibitEvents({ caseId: caseDemandId }),
        { enabled: canUserViewMissingDocsPermission && hasCaseDemandId }
      )

      // Depends on case query
      const { data: missingExhibits } = useQuery<MissingExhibit[]>(
        [queryKeys.missingExhibits, caseDemandId, !canEditMissingDocuments],
        () =>
          canEditMissingDocuments
            ? getCurrentMissingExhibits({ caseId: caseDemandId })
            : getMissingExhibits({ caseId: caseDemandId, onlyUnresolved: false }),
        {
          enabled: isNotOSF(user.role) && !!caseDemandId,
        }
      )

      const { data: caseFlags } = useQuery<CaseFlagDto[]>(
        [queryKeys.caseFlagsPublished, caseDemandId],
        () => fetchCaseFlags({ demandId: caseDemandId!, published: true }),
        { enabled: request.type == DOCUMENT_TYPES.STANDARD && hasCaseDemandId }
      )
      let lastModifiedMessage = ""
      if (request.updated_at) {
        lastModifiedMessage = formatTimeSinceNow(request.updated_at)
      }

      const requestTypeTitle: string = useMemo(() => {
        if (!request.type || isRequestDemandType(request.type)) return "Demand"
        if (isRequestMedChronType(request.type)) return "MedChron"
        return "Demand"
      }, [request.type])

      const tabs = [
        <MuiTab
          key={REQUEST_VIEW_TABS.REQUEST}
          label={requestTypeTitle}
          value={REQUEST_VIEW_TABS.REQUEST}
          data-test="request-details-tab"
        />,
      ]
      const tabPanels = [
        <TabPanel key={REQUEST_VIEW_TABS.REQUEST} value={REQUEST_VIEW_TABS.REQUEST}>
          <Request
            request={request}
            revisionRequest={revisionRequestEvents?.activeEvent?.revisionRequest}
            hasMissingDocs={!!communicationEvents?.length}
          />
        </TabPanel>,
      ]

      if (canUserViewMissingDocsPermission) {
        const canOpenMissingDocs = communicationEvents?.length
        const allMissignExhibits = missingExhibits || []
        const notUploaded = allMissignExhibits.filter(
          missingExhibit => missingExhibit.instructions !== INSTRUCTIONS.UPLOAD
        )
        const missingDocsLabel =
          notUploaded.length && canOpenMissingDocs
            ? `Missing Documents (${notUploaded.length})`
            : `Missing Documents`

        tabs.push(
          <MuiTab
            key={REQUEST_VIEW_TABS.MISSING_DOCS}
            label={missingDocsLabel}
            value={REQUEST_VIEW_TABS.MISSING_DOCS}
            disabled={!canOpenMissingDocs}
            data-test="missing-documents-tab"
          />
        )

        tabPanels.push(
          <TabPanel key={REQUEST_VIEW_TABS.MISSING_DOCS} value={REQUEST_VIEW_TABS.MISSING_DOCS}>
            {hasCaseDemandId && (
              <MissingDocumentClientView caseId={caseDemandId} isCompleted={!canEditMissingDocuments} />
            )}
          </TabPanel>
        )
      }

      tabs.push(
        <MuiTab
          key={REQUEST_VIEW_TABS.REVISION}
          label="Revisions"
          value={REQUEST_VIEW_TABS.REVISION}
          disabled={!permissions[REQUEST_VIEW_TABS.REVISION]}
          data-test="revisions-tab"
        />
      )

      tabPanels.push(
        <TabPanel key={REQUEST_VIEW_TABS.REVISION} value={REQUEST_VIEW_TABS.REVISION}>
          {permissions[REQUEST_VIEW_TABS.REVISION] ? (
            <RevisionRequestView requestId={requestId} requestStatus={request.intake_status} />
          ) : (
            <Navigate to={getRequestViewUrl(REQUEST_VIEW_TABS.REQUEST, true)} replace />
          )}
        </TabPanel>
      )

      const oneTab = tabs.length === 1

      const setHref = (nextTab: REQUEST_VIEW_TABS) => {
        if (!permissions[nextTab]) {
          nextTab = REQUEST_VIEW_TABS.REQUEST
        }

        if (nextTab === currentTab) {
          return
        }

        const historyRecord = REQUEST_VIEW_HISTORY[nextTab]
        window.history.replaceState(historyRecord, historyRecord, getRequestViewUrl(nextTab))
      }

      const { fullName: firstPlaintiffFullName } = getFirstPlaintiffName(request)

      // Don't add non-case things in here.  It's checking based on the firm of the current request
      const { showCaseFlagsFormEnabled, showCaseInsightsEnabled } = usePermissions({
        firmId: request.firm_id,
        suspense: true,
        enabled: user.isAuthorized,
      })

      const isCompleted = request.intake_status === "5"
      const isRevised = request.revised

      const isCompletedOrRevised = isCompleted || isRevised
      const isStandardDemand = request.type === DOCUMENT_TYPES.STANDARD
      const availableCaseInsights = (caseFlags || []).length > 0 && showCaseInsightsEnabled

      const showViewCaseButton = caseId
      const showCaseInsightButton =
        caseId &&
        isStandardDemand &&
        showCaseFlagsFormEnabled &&
        isCompletedOrRevised &&
        showCaseInsightsEnabled

      return (
        <>
          <Helmet>
            <title>Request: {firstPlaintiffFullName} - EvenUp</title>
          </Helmet>
          <Container>
            <Box display="flex" sx={{ justifyContent: "space-between" }} mt={2} mb={2}>
              <Box display="flex" sx={{ alignItems: "flex-start" }}>
                <Typography variant="h1" sx={{ maxWidth: theme.spacing(70), wordBreak: "break-word" }}>
                  {firstPlaintiffFullName}
                </Typography>
                <Box ml={2} mt={1.5} mb="auto" alignItems="flex-start">
                  <RequestTypeBadge type={request.type} />
                </Box>
              </Box>
              <Box display="flex" sx={{ alignItems: "center" }}>
                {showCaseInsightButton && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    data-test="edit-case-insights-button"
                    onClick={() => {
                      amplitudeApm.trackEvent(
                        new CaseAnalyticEvent(CaseAnalyticsEventTypes.CaseInsightsFormViewed, {
                          firm_id: request.firm_id,
                          firm_name: request.firm.name,
                          request_id: Number(requestId),
                          request_type: request.type,
                        })
                      )
                      navigate(`/cases/${caseId}/insights`)
                    }}
                    sx={{ mr: 2 }}
                    startIcon={<EditIcon />}
                  >
                    Case Insights
                  </Button>
                )}
                {showViewCaseButton && (
                  <ViewCaseButton caseId={caseId} availableInsights={availableCaseInsights} />
                )}
              </Box>
            </Box>
            <TabContext value={currentTab}>
              <Box display="flex" sx={{ borderBottom: 1, borderColor: "divider" }}>
                {!oneTab && (
                  <MuiTabs
                    value={currentTab}
                    onChange={(_, newValue) => {
                      setHref(newValue as REQUEST_VIEW_TABS)
                      setCurrentTab(newValue)
                    }}
                    indicatorColor="secondary"
                  >
                    {tabs}
                  </MuiTabs>
                )}
                <Box data-test="last-updated-time" mt="auto" mb="auto" ml="auto">
                  Last Updated: {lastModifiedMessage}
                </Box>
              </Box>
              {tabPanels}
            </TabContext>
          </Container>
        </>
      )
    },
    {
      FallbackComponent: ({ error, resetErrorBoundary }) => {
        const { showErrorMessage } = useHandleMessages()
        const parsedError = parseError(error)

        useEffect(() => {
          if (parsedError && parsedError.status === 404) {
            // Do nothing because our Fallback component will display appropriate error message
          } else if (error instanceof ApiError) {
            showErrorMessage({
              message: (
                <>
                  Failed to fetch url:
                  <br />
                  <pre>{String(error.cause)}</pre>
                </>
              ),
              error,
            })
          } else {
            showErrorMessage({
              message: String(error),
              error,
            })
          }
        }, [error, showErrorMessage, parsedError])

        return <ViewRequestFallback error={error} resetErrorBoundary={resetErrorBoundary} />
      },
    }
  ),
  <RequestSkeleton />
)
