import { useShallow } from "zustand/react/shallow"
import { ProviderSummaryStoreType } from "./types"

export function useDatesOfService() {
  return useShallow((state: ProviderSummaryStoreType) => {
    return Array.from(new Set([...state.medicalSummaries.keys(), ...state.injuryDetails.keys()])).sort(
      (a, b) => +new Date(a) - +new Date(b)
    )
  })
}
