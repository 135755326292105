import { useCallback } from "react"
import { DropdownContainer, DropdownLabel, ExhibitProviderContainer, ExhibitProviderDisplay } from "./styled"
import { EditableSelect } from "evenup-ui/EditableSelect"
import CircleIcon from "@mui/icons-material/Circle"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { UserExhibit } from "exhibit-builder/store/types"

export function ExhibitProviderDropdown({ id, label }: { id: UserExhibit["id"]; label: string }) {
  const userExhibit = useExhibitBuilderStore(filesSelectors.getUserExhibitById(id))
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const providers = useExhibitBuilderStore(useShallow(state => state.providers))
  const handleProviderCreate = useCallback((name: string) => exhibitBuilderActions.createProvier(name), [])

  const handleProviderRename = useCallback(
    (providerId: string, name: string) => exhibitBuilderActions.renameProvider({ providerId, name }),
    []
  )

  const handleSortingProviderChange = useCallback(
    (providerId: string | null) => {
      exhibitBuilderActions.updateUserExhibit({ id, sortingProviderId: providerId })
    },
    [id]
  )

  const sortingProvider = userExhibit.sortingProviderId ? providers[userExhibit.sortingProviderId] : ""

  return (
    <DropdownContainer data-test="exhibit-provider">
      <DropdownLabel>{label} Provider:</DropdownLabel>
      <ExhibitProviderContainer>
        <EditableSelect
          readOnly={isReadOnly}
          sx={{ width: "100%" }}
          value={userExhibit.sortingProviderId ?? ""}
          options={Object.values(providers)}
          onChange={handleSortingProviderChange}
          onCreate={handleProviderCreate}
          onRename={handleProviderRename}
          emptyValueLabel="Unassigned"
          startAdornment={
            sortingProvider ? (
              <ExhibitProviderDisplay>
                <CircleIcon htmlColor={sortingProvider?.color} fontSize="small" />
                {sortingProvider?.name}
              </ExhibitProviderDisplay>
            ) : (
              "Unassigned"
            )
          }
        />
      </ExhibitProviderContainer>
    </DropdownContainer>
  )
}
