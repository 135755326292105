import styled from "@emotion/styled"
import { TableCell } from "@mui/material"

export const StyledStatusCell = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  overflowX: "hidden",
  maxWidth: "20em",
}))

export const StyledProgressCell = styled("div")(({ theme }) => ({
  color: theme.palette.grey[600],
}))

export const StyledLoadingTableCell = styled(TableCell)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}))

export const ErrorMessageCell = styled("div")(() => ({
  whiteSpace: "nowrap",
  maxWidth: "100%",
}))
