import { PropsWithChildren, memo, useCallback } from "react"
import { DropdownContainer, DropdownLabel, Dropdowns, UserExhibitItemContainer } from "./styled"
import {
  SortableNestedListItem,
  SortableNestedListItemProps,
} from "common/nested-list/SortableNestedListItem"
import { Collapse, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { plaintiffsSelectors } from "exhibit-builder/store/plaintiffs"
import { DndUserExhibitData, UserExhibit } from "exhibit-builder/store/types"
import { useShallow } from "zustand/react/shallow"
import { PROVIDER_KEY } from "exhibit-builder/store/constants"
import { PDFStatus } from "./PDFStatus"
import { Tag } from "./Tag"
import { DocTypeDropdown } from "./DocTypeDropdown"
import { SubDocTypeDropdown } from "./SubDocTypeDropdown"
import { ExhibitProviderDropdown } from "./ExhibitProviderDropdown"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { useCollapseStore } from "exhibit-builder/Collapse/collapse"

export const UserExhibitItem = memo(function UserExhibitItem({
  id,
  item,
  children,
  ...props
}: PropsWithChildren<SortableNestedListItemProps<DndUserExhibitData>> & { id: UserExhibit["id"] }) {
  const type = useExhibitBuilderStore(state => state.exhibitBuilderType)
  const userExhibit = useExhibitBuilderStore(filesSelectors.getUserExhibitById(id))
  const docTypes = useExhibitBuilderStore(state => state.docTypes)
  const plaintiffs = useExhibitBuilderStore(plaintiffsSelectors.getPlaintiffs)
  const collapsed = useCollapseStore(state => state.collapseStates[id])

  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))

  const handlePlaintiffChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      exhibitBuilderActions.updateUserExhibit({ id, plaintiffId: event.target.value })
    },
    [id]
  )

  const selectedDocType = userExhibit.docType ? docTypes[userExhibit.docType] : null
  const showSortingProvider = userExhibit.docType === PROVIDER_KEY
  const oneRow = !showSortingProvider

  return (
    <UserExhibitItemContainer data-test="user-exhibit-item" isHighlighted={userExhibit.isHighlighted}>
      <SortableNestedListItem {...props} item={item}>
        <Collapse in={!collapsed} mountOnEnter unmountOnExit data-test="user-exhibit-item-content">
          <PDFStatus id={id} />
          {type === EB_DOC_TYPE.DEMAND && (
            <Dropdowns oneRow={oneRow}>
              <DocTypeDropdown id={id} />
              {Boolean(selectedDocType?.subDocTypes?.length) && <SubDocTypeDropdown id={id} />}
              {showSortingProvider && <ExhibitProviderDropdown id={id} label="Sorting" />}

              <DropdownContainer>
                <DropdownLabel>Plaintiff:</DropdownLabel>
                <Select
                  value={userExhibit.plaintiffId ?? ""}
                  onChange={handlePlaintiffChange}
                  data-test="user-exhibit-plaintiff-dropdown"
                  readOnly={isReadOnly}
                >
                  {Object.values(plaintiffs).map((plaintiff, index) => (
                    <MenuItem key={index} value={plaintiff.id}>
                      {plaintiff.name}
                    </MenuItem>
                  ))}
                </Select>
              </DropdownContainer>

              {userExhibit.tag && <Tag tag={userExhibit.tag} />}
            </Dropdowns>
          )}
          {type === EB_DOC_TYPE.MEDCHRON && (
            <Dropdowns oneRow={oneRow}>
              <ExhibitProviderDropdown id={id} label="Exhibit" />
              <DocTypeDropdown id={id} />
              {userExhibit.tag && <Tag tag={userExhibit.tag} />}
            </Dropdowns>
          )}
          {children}
        </Collapse>
      </SortableNestedListItem>
    </UserExhibitItemContainer>
  )
})
