import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"

import { encodeExhibitValue } from "./utils"
import { EXHIBIT_ID, FILE_TO_UPLOAD_ID, PARTITION_ID, USER_EXHIBIT_ID } from "./constants"
import { EXHIBIT } from "demand/Providers/constants"
import { useContext } from "react"
import { FormContext } from "demand/context"
import useCase from "hooks/useCase"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"

export const ExhibitSelect = ({
  value,
  onChange,
  disabled,
  provider,
  error,
  helperText,
  className = "",
  onBlur,
}) => {
  const { caseId } = useContext(FormContext)
  const { caseObj } = useCase(caseId)

  const userExhibits = useExhibitBuilderStore(useShallow(state => Object.values(state.userExhibitMap)))

  return (
    <FormControl className={className} disabled={disabled} error={error} data-test="related-file-select">
      <Select value={value ?? ""} onChange={onChange} onBlur={onBlur}>
        <MenuItem value="" disabled>
          Please Select
        </MenuItem>
        {provider?.filesToUpload?.map(({ name, formId }) => (
          <MenuItem
            data-test="related-file-name"
            key={formId}
            value={encodeExhibitValue(FILE_TO_UPLOAD_ID, formId)}
            disabled={disabled}
          >
            {name}
          </MenuItem>
        ))}
        {provider?.exhibits?.map(({ name, pk, exhibitType = null }) => (
          <MenuItem
            data-test="related-file-name"
            key={pk}
            value={
              !exhibitType || exhibitType === EXHIBIT
                ? encodeExhibitValue(EXHIBIT_ID, pk)
                : encodeExhibitValue(PARTITION_ID, pk)
            }
            disabled={disabled}
          >
            {name}
          </MenuItem>
        ))}
        {caseObj.use_exhibit_builder &&
          userExhibits.map(({ name, id }) => (
            <MenuItem
              data-test="related-file-name"
              key={id}
              value={encodeExhibitValue(USER_EXHIBIT_ID, id)}
              disabled={disabled}
            >
              {name}
            </MenuItem>
          ))}
      </Select>
      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
