import styled from "@emotion/styled"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import CircleIcon from "@mui/icons-material/Circle"
import MultiSelectAutoComplete, { MultiSelectAutoCompleteProps } from "evenup-ui/MultiSelectAutoComplete"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

const ProviderItem = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}))

export function ProviderFilter() {
  const selectedProviders = useExhibitBuilderStore(useShallow(state => state.filters.selectedProviders))
  const providers = useExhibitBuilderStore(state => state.providers)
  const providerOptions = useMemo(
    () =>
      Object.values(providers).map(provider => ({
        label: provider.name,
        value: provider.id,
        color: provider.color,
      })),
    [providers]
  )

  const value = useMemo(
    () => providerOptions.filter(option => selectedProviders?.includes(option.value)),
    [providerOptions, selectedProviders]
  )

  const handleProviderChange: MultiSelectAutoCompleteProps<
    (typeof providerOptions)[number]
  >["onChangeValue"] = options => {
    exhibitBuilderActions.selectProviders(options.map(option => option.value))
  }

  return (
    <MultiSelectAutoComplete
      size="small"
      label="Provider"
      value={value}
      options={providerOptions}
      optionRender={option => (
        <ProviderItem>
          <CircleIcon htmlColor={option.color} />
          {option.label}
        </ProviderItem>
      )}
      onChangeValue={handleProviderChange}
    />
  )
}
