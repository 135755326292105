import { Citation, CitationDTO } from "common/citation-viewer/store"

export enum QUESTION_STATUS {
  CREATED = "CREATED",
  PROCESSING = "PROCESSING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  NOT_RECEIVED_FROM_BACKEND = "NOT_RECEIVED_FROM_BACKEND",
}

export const TERMINAL_STATES = [QUESTION_STATUS.COMPLETE, QUESTION_STATUS.ERROR]
export interface Chat {
  question: string
  id: string
  createdAt: string
  status: QUESTION_STATUS
  answer: {
    content: string
    citations: Citation[]
  }[]
  isHelpful: boolean | null
  comment: string
}
export interface ChatListDTO {
  data: Pick<ChatDTO, "id" | "question" | "created_at">[]
}

export interface ChatDTO {
  case_id: string
  firm_id: string
  user_id: string
  question: string
  answer:
    | {
        content: string
        citations: CitationDTO[] | null
      }[]
    | null
  is_helpful: boolean | null
  comment: string | null
  id: string
  status: QUESTION_STATUS
  created_at: string
}
