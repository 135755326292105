import { useCallback } from "react"
import { DropdownLabel, DropdownContainer } from "./styled"
import { Select, MenuItem, SelectChangeEvent } from "@mui/material"
import { red } from "@mui/material/colors"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { UserExhibit } from "exhibit-builder/store/types"
import { UNASSIGNED_KEY } from "exhibit-builder/store/constants"
import { useShallow } from "zustand/react/shallow"

const ItemValue = ({ name, value }: { name?: string; value?: string }) => {
  return !value || value === UNASSIGNED_KEY ? <span style={{ color: red[700] }}>Unassigned</span> : name
}

export function DocTypeDropdown({ id }: { id: UserExhibit["id"] }) {
  const userExhibit = useExhibitBuilderStore(filesSelectors.getUserExhibitById(id))
  const docTypes = useExhibitBuilderStore(state => state.docTypes)
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))

  const handleDocTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      exhibitBuilderActions.updateUserExhibit({ id, docType: event.target.value, subDocType: UNASSIGNED_KEY })
    },
    [id]
  )

  return (
    <DropdownContainer>
      <DropdownLabel>Doc Type:</DropdownLabel>
      <Select
        value={userExhibit.docType ?? ""}
        onChange={handleDocTypeChange}
        data-test="user-exhibit-doc-type-dropdown"
        displayEmpty
        renderValue={value => <ItemValue name={docTypes[value]?.name} value={docTypes[value]?.key} />}
        readOnly={isReadOnly}
      >
        {Object.values(docTypes).map((section, index) => (
          <MenuItem key={index} value={section.key} data-test={section.name}>
            {section.name}
          </MenuItem>
        ))}
      </Select>
    </DropdownContainer>
  )
}
