import { ExhibitBuilderData, SubDocType } from "../store/types"

export type DocTypesSlice = Pick<ExhibitBuilderData, "docTypes">

export const docTypesSlice: DocTypesSlice = {
  docTypes: {},
}

export const docTypesSelectors = {
  getAllSubDocTypes: (state: DocTypesSlice) => {
    const subDocTypes: Record<string, SubDocType> = {}
    for (const docType of Object.values(state.docTypes)) {
      docType.subDocTypes?.forEach(subDocType => {
        subDocTypes[subDocType.key] = subDocType
      })
    }

    return Object.values(subDocTypes)
  },
  getSubDocTypesByDocTypes: (docTypes: string[]) => (state: DocTypesSlice) =>
    docTypes.flatMap(docType => state.docTypes[docType]?.subDocTypes || []),
}
