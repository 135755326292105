import { DndExhibitPartitionData } from "exhibit-builder/store/types"
import {
  StyledSortableNestedListItem,
  StyledMedChronPartitionItem,
  StyledRecordPagesList,
  StyledRecordPages,
  StyledRecordPagesListContainer,
  StyledRecordPagesListHeader,
} from "./styled"
import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { PropsWithChildren } from "react"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { useShallow } from "zustand/react/shallow"
import { Typography } from "@mui/material"

export const ExhibitPartitionItem = function ExhibitPartitionItem({
  children,
  ...props
}: PropsWithChildren<SortableNestedListItemProps<DndExhibitPartitionData>>) {
  const type = useExhibitBuilderStore(state => state.exhibitBuilderType)
  const records = props.item.children
  const { id: partitionId, userExhibitId } = props.item["ITEM_REF"]
  const recordIds = records.map(record => record["ITEM_REF"].id)
  const { partition, recordPagesMap } = useExhibitBuilderStore(
    useShallow(filesSelectors.getRecordUserExhibitPages({ recordIds, userExhibitId, partitionId }))
  )

  if (type === EB_DOC_TYPE.DEMAND) {
    return (
      <div data-test="exhibit-partition-item">
        <StyledSortableNestedListItem {...props} backgroundColor="white">
          {children}
        </StyledSortableNestedListItem>
      </div>
    )
  }

  if (type === EB_DOC_TYPE.MEDCHRON) {
    return (
      <div data-test="medchron-exhibit-partition-item">
        <StyledMedChronPartitionItem {...props}>
          {children}
          <StyledRecordPagesListContainer>
            <StyledRecordPagesListHeader variant="listSubHeader" fontWeight="bold">
              <div>Exhibit Pages</div>
              <div>
                ({partition.userExhibitStartPage} - {partition.userExhibitEndPage})
              </div>
            </StyledRecordPagesListHeader>
            <StyledRecordPagesList>
              {recordIds.map(recordId => {
                const startPage = recordPagesMap[recordId]?.startPage
                const endPage = recordPagesMap[recordId]?.endPage
                return (
                  <StyledRecordPages key={`exhibit-pages-${recordId}`}>
                    <Typography variant="body2" fontWeight="bold">
                      {startPage && endPage ? `${startPage}-${endPage}` : "Not Found"}
                    </Typography>
                  </StyledRecordPages>
                )
              })}
            </StyledRecordPagesList>
          </StyledRecordPagesListContainer>
        </StyledMedChronPartitionItem>
      </div>
    )
  }
}
