import { useExhibitBuilderStore } from "exhibit-builder/store"
import { FiltersSlice } from "exhibit-builder/store/filters"
import {
  DndExhibitPartitionData,
  DndRecordOrBillData,
  DndUserExhibitData,
  DndVirtualListData,
  ExhibitPartition,
  UserExhibit,
} from "exhibit-builder/store/types"
import { useShallow } from "zustand/react/shallow"
import { checkValidUserExhibit } from "exhibit-builder/utils"

export function useListData(filters: Partial<FiltersSlice["filters"]>): DndVirtualListData {
  const {
    exhibitPartitionOrder,
    exhibitPartitionMap,
    userExhibitOrder,
    userExhibitMap,
    recordsAndBillsMap,
    recordsAndBillsOrder,
  } = useExhibitBuilderStore(
    useShallow(state => ({
      exhibitPartitionOrder: state.exhibitPartitionOrder,
      exhibitPartitionMap: state.exhibitPartitionMap,
      userExhibitOrder: state.userExhibitOrder,
      userExhibitMap: state.userExhibitMap,
      recordsAndBillsMap: state.recordsAndBillsMap,
      recordsAndBillsOrder: state.recordsAndBillsOrder,
    }))
  )

  const {
    selectedProviders,
    selectedDocTypes,
    selectedSubDocTypes,
    selectedPlaintiffs,
    selectedStartDate,
    selectedEndDate,
    selectedExhibitTags,
    selectedFiles,
    onlyInvalidExhibits,
  } = filters

  const hasFiltersApplied = Boolean(
    selectedDocTypes?.length ||
      selectedSubDocTypes?.length ||
      selectedStartDate ||
      selectedEndDate ||
      selectedExhibitTags?.length ||
      selectedProviders?.length ||
      selectedPlaintiffs?.length ||
      selectedFiles?.length
  )

  const exactProviderMatch = filters.exactProviderMatch
  function filteredPartitionRecordsAndBills(
    partitionId: ExhibitPartition["id"],
    userExhibitId: UserExhibit["id"]
  ) {
    const filteredData: DndRecordOrBillData[] = []
    recordsAndBillsOrder[partitionId]?.forEach(recordOrBillId => {
      const recordOrBill = recordsAndBillsMap[recordOrBillId]

      if (!recordOrBill) {
        return
      }

      const startDate = new Date(
        recordOrBill.type === "Medical Bill" ? recordOrBill.dateOfFirstService : recordOrBill.dateOfService
      )
      const endDate = new Date(
        recordOrBill.type === "Medical Bill" ? recordOrBill.dateOfLastService : recordOrBill.dateOfService
      )

      const isProviderInSelection =
        !selectedProviders?.length ||
        selectedProviders.some(
          provider =>
            provider === recordOrBill.providerId ||
            (provider === userExhibitMap[userExhibitId].sortingProviderId && !exactProviderMatch)
        )
      const isStartDateInFilterRange = !selectedStartDate || startDate >= new Date(selectedStartDate)
      const isEndDateInFilterRange = !selectedEndDate || endDate <= new Date(selectedEndDate)

      const showRecordOrBill = isProviderInSelection && isStartDateInFilterRange && isEndDateInFilterRange

      if (showRecordOrBill) {
        filteredData.push({
          id: recordOrBillId,
          uniqueKey: `${recordOrBill.type}-${recordOrBillId}`,
          type: "recordOrBill",
          userExhibitId,
          partitionId,
        })
      }
    })

    return filteredData
  }

  function filteredUserExhibitPartitions(userExhibitId: UserExhibit["id"]) {
    const filteredData: DndExhibitPartitionData[] = []
    exhibitPartitionOrder[userExhibitId]?.forEach(partitionId => {
      const exhibitPartition = exhibitPartitionMap[partitionId]

      const matchFileFilter = !selectedFiles?.length || selectedFiles.includes(exhibitPartition.fileId)

      if (!matchFileFilter) {
        return
      }

      const sortingProviderId = userExhibitMap[userExhibitId].sortingProviderId
      const partitionChildren = filteredPartitionRecordsAndBills(partitionId, userExhibitId)
      const isFilteringByProvider = selectedProviders?.length || selectedStartDate || selectedEndDate
      const matchSortingProvider = sortingProviderId && selectedProviders?.includes(sortingProviderId)

      if (isFilteringByProvider && !matchSortingProvider && partitionChildren.length === 0) {
        return
      }

      filteredData.push({
        id: partitionId,
        uniqueKey: `exhibit-partition-${partitionId}`,
        type: "exhibitPartition",
        userExhibitId,
        children: partitionChildren,
      })
    })

    return filteredData
  }

  function filteredUserExhibits() {
    const filteredData: DndUserExhibitData[] = []
    userExhibitOrder?.forEach(userExhibitId => {
      const userExhibit = userExhibitMap[userExhibitId]

      if (onlyInvalidExhibits) {
        const { isValid } = checkValidUserExhibit(userExhibit)
        if (isValid) return
      }

      const matchDocType = !selectedDocTypes?.length || selectedDocTypes.includes(userExhibit.docType || "")

      const matchSubDocType =
        !selectedSubDocTypes?.length || selectedSubDocTypes.includes(userExhibit.subDocType || "")

      const matchPlaintiff =
        !selectedPlaintiffs?.length || selectedPlaintiffs.includes(userExhibit.plaintiffId || "")

      const matchExhibitTag = !selectedExhibitTags?.length || selectedExhibitTags.includes(userExhibit.tag)

      const showUserExhibit = matchDocType && matchSubDocType && matchPlaintiff && matchExhibitTag

      if (!showUserExhibit) return

      const userExhibitChildren = filteredUserExhibitPartitions(userExhibitId)

      if (hasFiltersApplied && userExhibitChildren.length === 0) {
        return
      }

      filteredData.push({
        id: userExhibitId,
        uniqueKey: `user-exhibit-${userExhibitId}`,
        type: "userExhibit",
        children: userExhibitChildren,
      })
    })

    return filteredData
  }

  return { uniqueKey: "virtual-list", type: "virtualList", children: filteredUserExhibits() }
}
