import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import MultiSelectAutoComplete, { MultiSelectAutoCompleteProps } from "evenup-ui/MultiSelectAutoComplete"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

export function Files() {
  const selectedFiles = useExhibitBuilderStore(useShallow(state => state.filters.selectedFiles))
  const files = useExhibitBuilderStore(useShallow(state => state.files))
  const options = useMemo(
    () =>
      Object.values(files).map(file => ({
        label: file.name,
        value: file.id,
      })),
    [files]
  )

  const value = useMemo(
    () => options.filter(option => selectedFiles?.includes(option.value)),
    [selectedFiles, options]
  )

  const handleChange: MultiSelectAutoCompleteProps<(typeof options)[number]>["onChangeValue"] = options => {
    exhibitBuilderActions.setSelectedFiles(options.map(option => option.value))
  }

  return (
    <MultiSelectAutoComplete
      size="small"
      label="Original File Name"
      value={value}
      options={options}
      onChangeValue={handleChange}
    />
  )
}
