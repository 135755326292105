import { memo, useEffect, useMemo, useState } from "react"
import { useProviderSummariesStore } from "./store"
import { formatDate } from "utils"
import { ExpandIcon, StatusMarker, SummaryItemContainer, SummaryItemHeader } from "./styled"
import { useDebouncedCallback } from "hooks/useDebouncedCallback"
import { Box, Collapse, Typography } from "@mui/material"
import { PROVIDER_SUMMARY_STATUS } from "api/services/summaries/types"
import { SummaryItemTabs } from "./SummaryItemTabs"

export const SummaryItem = memo(function SummaryItem({
  dateOfService,
  initialOpen = false,
}: {
  dateOfService: string
  initialOpen?: boolean
}) {
  const medicalSummaryStatus = useProviderSummariesStore(
    state => state.medicalSummaries.get(dateOfService)?.status
  )
  const hasMultipleMedicalProfessionals = useProviderSummariesStore(
    state => (state.medicalSummaries.get(dateOfService)?.medicalProfessionalOptions.length ?? 0) > 1
  )
  const expandAction = useProviderSummariesStore(state => state.action)

  const displayDate = useMemo(() => formatDate(dateOfService, "MMMM d, yyy", true), [dateOfService])

  const [opened, setOpened] = useState(initialOpen)
  const toggleOpened = useDebouncedCallback(() => setOpened(opened => !opened), 500, {
    leading: true,
    trailing: false,
  })

  const [statusChanged, setStatusChanged] = useState(false)
  const showStatus =
    !!medicalSummaryStatus && (medicalSummaryStatus !== PROVIDER_SUMMARY_STATUS.SUCCESS || statusChanged)

  useEffect(() => {
    if (medicalSummaryStatus) {
      return () => setStatusChanged(true)
    }
  }, [medicalSummaryStatus])

  useEffect(() => {
    if (expandAction === "expand") {
      requestAnimationFrame(() => setOpened(true))
    } else if (expandAction === "collapse") {
      requestAnimationFrame(() => setOpened(false))
    }
  }, [expandAction])

  return (
    <SummaryItemContainer>
      <SummaryItemHeader onClick={toggleOpened}>
        <ExpandIcon open={opened} />
        <Typography variant="helper" sx={{ letterSpacing: -0.12 }}>
          {displayDate}
        </Typography>
        {showStatus && <StatusMarker status={medicalSummaryStatus} />}
        {hasMultipleMedicalProfessionals && (
          <Typography
            variant="helper"
            sx={{ fontSize: "10px", lineHeight: "14px", ml: "auto", opacity: 0.6 }}
          >
            * Multiple Medical Professionals Detected
          </Typography>
        )}
      </SummaryItemHeader>
      <Collapse in={opened} mountOnEnter unmountOnExit>
        <Box sx={{ px: 1.5, pt: 1, pb: 1.5, backgroundColor: "#F6F6F6" }}>
          <SummaryItemTabs dateOfService={dateOfService} showStatus={showStatus} />
        </Box>
      </Collapse>
    </SummaryItemContainer>
  )
})
