import { ReferencePage } from "common/types/providers"
import {
  EXHIBIT_ID,
  PARTITION_ID,
  USER_EXHIBIT_ID,
} from "demand/Providers/Provider/ProviderForm/BillsSection/constants"
import { encodeExhibitValue } from "demand/Providers/Provider/ProviderForm/BillsSection/utils"

export const getObjectiveTestReferenceExhibitValue = (reference: Nullable<ReferencePage>) => {
  if (!reference) return ""

  if (reference.exhibitId) {
    return encodeExhibitValue(EXHIBIT_ID, reference.exhibitId)
  }

  if (reference.partitionId) {
    return encodeExhibitValue(PARTITION_ID, reference.partitionId)
  }
  if (reference.userExhibitId) {
    return encodeExhibitValue(USER_EXHIBIT_ID, reference.userExhibitId)
  }

  return ""
}
