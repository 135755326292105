import { useExhibitBuilderStore } from "exhibit-builder/store"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { useCallback } from "react"
import { uploadExhibit as uploadExhibitAPI, uploadMedchronExhibit as uploadMedchronExhibitAPI } from "api"

export const useUploadExhibit = () => {
  const exhibitBuilderType = useExhibitBuilderStore(state => state.exhibitBuilderType)

  const uploadExhibit = useCallback(
    ({ data, caseId, documentId }: { data: FormData; caseId?: string; documentId?: string }) => {
      if (exhibitBuilderType === EB_DOC_TYPE.MEDCHRON) {
        return uploadMedchronExhibitAPI({ data, documentId })
      }

      return uploadExhibitAPI({ data, caseId })
    },
    [exhibitBuilderType]
  )

  return {
    uploadExhibit,
  }
}
