import { Typography } from "@mui/material"
import { useProviderSummariesStore } from "./store"
import { EditorContainer, SummariesContainer } from "./styled"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { CaseVariablesContext } from "demand/Variables/context"
import { memo, useContext, useMemo } from "react"
import { CustomEditor } from "common/form-components/rich-text/CustomEditor"
import { v4 } from "uuid"

export const MedicalSummaryResult = memo(function MedicalSummaryResult({
  dateOfService,
  editorRef,
}: {
  dateOfService: string
  editorRef?: (editor: CustomEditor | null) => void
}) {
  const summaryResult = useProviderSummariesStore(
    state => state.medicalSummaries.get(dateOfService)?.summaryContent
  )
  const { variables } = useContext(CaseVariablesContext)
  const contentId = useMemo(() => `${summaryResult?.length}_${v4()}`, [summaryResult])

  return (
    <SummariesContainer>
      <Typography variant="labelBadge" sx={{ fontWeight: 600 }}>
        Generated Summary:
      </Typography>
      <EditorContainer empty={!summaryResult}>
        <RichTextEditor
          key={contentId}
          value={summaryResult ?? DEFAULT_VALUE}
          readonly
          withVariables
          variables={variables}
          editorRef={editorRef}
        />
      </EditorContainer>
    </SummariesContainer>
  )
})
