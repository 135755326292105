import React, { useMemo, useState } from "react"
import LinearProgress from "@mui/material/LinearProgress"
import Collapse from "@mui/material/Collapse"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import GetApp from "@mui/icons-material/GetApp"
import OpenInNew from "@mui/icons-material/OpenInNew"
import { Link, useParams } from "react-router-dom"
import AnnotationFile from "./AnnotationFile"
import Alert from "@mui/material/Alert"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import {
  downloadExhibits,
  fetchRequest_DEPRECATED,
  getAnnotatedExhibits,
  getCaseWithAnnotation,
  getPartitionProviders,
} from "api"
import { AnnotatedExhibit, PartitionProvider } from "./types"
import { config } from "common/config"
import { CaseWithAnnotation } from "case/types"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { Helmet } from "react-helmet"
import { Header, SectionBox } from "./styled"
import { SECTION_TO_EXPANDED_REQUESTS, FORM_SECTIONS } from "../constants"
import MobileNavButton from "demand/LeftNav/MobileNavButton"
import { RequestPanelPageWrapper } from "requests/RequestPanelPageWrapper"
import { Components, Virtuoso } from "react-virtuoso"
import { DemandExhibitBuilder } from "exhibit-builder/ExhibitBuilder"
import useCase from "hooks/useCase"
import { ExhibitBuilderToggle } from "exhibit-builder/ExhibitBuilderToggle"
import { queryClient } from "react-query/queryClient"
import { usePermissions } from "permissions/usePermissions"

const PageHeader = styled(Typography)({
  fontSize: "24px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.42,
  letterSpacing: "-0.48px",
})

const HeaderButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}))

const FilesBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}))

interface FilesContentProps {
  caseId: string
  isCaseLoading: boolean
  areExhibitsLoading: boolean
  areProvidersLoading: boolean
  caseObj?: CaseWithAnnotation
  exhibits?: AnnotatedExhibit[]
  partitionProviders?: PartitionProvider[]
  virtualized: boolean
}

const FilesContent: React.FC<FilesContentProps> = ({
  caseId,
  isCaseLoading,
  areExhibitsLoading,
  areProvidersLoading,
  caseObj,
  exhibits,
  partitionProviders,
  virtualized,
}) => {
  const { showErrorMessage } = useHandleMessages()
  const [downloadingExhibits, setDownloadingExhibits] = useState(false)
  const [areDeletedFilesExpanded, setAreDeletedFilesExpanded] = useState(false)
  const expandedRequestGroups = SECTION_TO_EXPANDED_REQUESTS[FORM_SECTIONS.INTAKE_FILES] ?? []
  const { data: request } = useQuery([queryKeys.request, caseObj?.request_id], fetchRequest_DEPRECATED, {
    enabled: !!caseObj?.request_id,
    meta: {
      disableLoader: true,
    },
  })
  const documentId = useCase(caseId).caseObj?.document_id
  const { exhibitBuilderToggleEnabled } = usePermissions()

  const deletedExhibits = useMemo(
    () => exhibits?.filter(exhibit => exhibit.is_deleted_from_request) ?? [],
    [exhibits]
  )
  const currentExhibits = useMemo(
    () => exhibits?.filter(exhibit => !exhibit.is_deleted_from_request && !exhibit.unable_to_parse) ?? [],
    [exhibits]
  )
  const unableToParseExhibits = useMemo(
    () => exhibits?.filter(exhibit => exhibit.unable_to_parse) ?? [],
    [exhibits]
  )

  const handleToggleExhibitBuilder = async (useExhibitBuilder: boolean) => {
    queryClient.refetchQueries([queryKeys.caseWithAnnotation, caseId])
    if (!useExhibitBuilder) {
      queryClient.refetchQueries([queryKeys.annotated_exhibits, caseId])
      queryClient.refetchQueries([queryKeys.partition_providers, caseId])
    }
  }

  if (isCaseLoading || areExhibitsLoading || areProvidersLoading)
    return (
      <Box pt={6} maxWidth="600px" margin="auto" data-test="loading-intake-files">
        <Typography>Loading intake files...</Typography>
        <Box mt={2}>
          <LinearProgress />
        </Box>
      </Box>
    )

  const handleDownloadExhibits = () => {
    setDownloadingExhibits(true)
    downloadExhibits({ caseId })
      .catch(error => {
        showErrorMessage({
          message:
            "Could not download exhibits. Try again shortly and file an issue if your problem persists.",
          error,
        })
      })
      .finally(() => {
        setDownloadingExhibits(false)
      })
  }

  if (!caseObj?.firm) {
    return (
      <Box pt={6} maxWidth="900px" margin="auto" data-test="no-firm-alert">
        <Alert severity="info">
          Cannot view Intake Files because this case is not currently attached to any firm. Add this case to a
          firm by filling out the Law Firm Information section in the Case Information tab.
        </Alert>
      </Box>
    )
  }

  if (!caseObj.firm.enable_annotations) {
    return (
      <Box pt={6} maxWidth="900px" margin="auto" data-test="firm-does-not-annotation-alert">
        <Alert severity="info">
          Cannot view Intake Files because File Annotations are not enabled for this firm. To view the
          annotated files check the &quot;Enable auto annotations&quot; option in this firms{" "}
          <Link to={`/settings/firms/${caseObj.firm.pk}`}>settings page</Link>.
        </Alert>
      </Box>
    )
  }

  if (!exhibits?.length) {
    return (
      <Box pt={6} maxWidth="900px" margin="auto" data-test="no-exhibits-alert">
        <Alert severity="info">
          <Box mb={1}>There are no files related to this case being annotated.</Box>
          <Box mb={1}>
            You can proceed to fill in the{" "}
            <Link to={`/demands/${caseId}/form/plaintiff`}>Case Information</Link> as normal.
          </Box>
          This case may have been created before auto annotations. If so, you will have to manually submit an
          annotation request to have your files annotated and they will not appear here.
        </Alert>
      </Box>
    )
  }

  const createAnnotationFilesFromExhibits = (exhibits: AnnotatedExhibit[]) => {
    const renderExhibit = (exhibit: AnnotatedExhibit) => (
      <AnnotationFile
        key={exhibit.pk}
        caseId={caseId ?? ""}
        exhibitId={exhibit.pk}
        fileName={exhibit.name}
        numberOfPages={exhibit.number_of_pages}
        status={exhibit.annotation_file?.status}
        errorDetails={exhibit.annotation_file?.error_details}
        clickable={false}
        splits={exhibit.splits}
        partitionProviders={partitionProviders}
        duplicates={exhibit.duplicates}
        actions={exhibit.actions}
        isDeleted={exhibit.is_deleted_from_request ?? false}
      />
    )

    if (virtualized) {
      return (
        <Virtuoso
          useWindowScroll
          style={{ width: "850px" }}
          data={currentExhibits}
          itemContent={index => renderExhibit(currentExhibits[index])}
          increaseViewportBy={1000}
          components={{
            List: FilesBox as Components["List"],
          }}
        />
      )
    }

    return exhibits.map(renderExhibit)
  }

  return (
    <RequestPanelPageWrapper
      caseId={caseId}
      request={request}
      expandedSections={expandedRequestGroups?.expand ?? []}
      highlightedFields={expandedRequestGroups?.highlight ?? []}
    >
      {exhibitBuilderToggleEnabled && (
        <ExhibitBuilderToggle
          caseId={caseId}
          useExhibitBuilder={caseObj?.use_exhibit_builder}
          onToggle={handleToggleExhibitBuilder}
        />
      )}
      {caseObj?.use_exhibit_builder && documentId ? (
        <DemandExhibitBuilder documentId={documentId} caseId={caseId} />
      ) : (
        <>
          <SectionBox>
            <Box mb={1}>
              <MobileNavButton />
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <PageHeader variant="h1">Intake Files</PageHeader>
              <HeaderButtons>
                <Button
                  size="small"
                  variant="outlined"
                  endIcon={downloadingExhibits ? <CircularProgress size={16} /> : <GetApp />}
                  disableElevation
                  onClick={handleDownloadExhibits}
                  disabled={downloadingExhibits}
                >
                  Download All
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  endIcon={<OpenInNew />}
                  disableElevation
                  target="_blank"
                  href={`${config.api.host}/case/${caseId}/kili/`}
                >
                  Annotation Tool
                </Button>
              </HeaderButtons>
            </Box>
            <FilesBox>{createAnnotationFilesFromExhibits(currentExhibits)}</FilesBox>
          </SectionBox>

          {unableToParseExhibits.length > 0 && (
            <SectionBox mt={4}>
              <Box mb={2}>
                <PageHeader>Unable To Parse</PageHeader>
              </Box>
              <Box mb={1}>
                <Alert severity="info">
                  <Box>
                    These files were unable to be parsed by our system and cannot be automatically imported or
                    used in exhibit groups when ordering.
                  </Box>
                  <Box mt={1}>
                    These files may be password protected or simply corrupt. You can manually download them
                    and use them still.
                  </Box>
                </Alert>
              </Box>
              <FilesBox>{createAnnotationFilesFromExhibits(unableToParseExhibits)}</FilesBox>
            </SectionBox>
          )}
          {deletedExhibits.length > 0 && (
            <SectionBox mt={4}>
              <Header onClick={() => setAreDeletedFilesExpanded(!areDeletedFilesExpanded)} mb={2}>
                <PageHeader>Deleted Intake Files</PageHeader>
                <IconButton>{areDeletedFilesExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
              </Header>
              <Collapse in={areDeletedFilesExpanded}>
                <FilesBox>{createAnnotationFilesFromExhibits(deletedExhibits)}</FilesBox>
              </Collapse>
            </SectionBox>
          )}
        </>
      )}
    </RequestPanelPageWrapper>
  )
}

const Files: React.FC<{ virtualized: boolean }> = ({ virtualized = false }) => {
  const { id: caseId } = useParams()
  const { data: caseObj, isLoading: isCaseLoading } = useQuery<CaseWithAnnotation>({
    queryKey: [queryKeys.caseWithAnnotation, caseId],
    queryFn: () => getCaseWithAnnotation({ caseId }),
    meta: { disableLoader: true },
  })
  const { data: exhibits, isLoading: areExhibitsLoading } = useQuery<AnnotatedExhibit[]>({
    queryKey: [queryKeys.annotated_exhibits, caseId],
    queryFn: () => getAnnotatedExhibits({ caseId }),
    meta: { disableLoader: true },
  })
  const { data: partitionProviders, isLoading: areProvidersLoading } = useQuery<PartitionProvider[]>({
    queryKey: [queryKeys.partition_providers, caseId],
    queryFn: () => getPartitionProviders({ caseId }),
    meta: { disableLoader: true },
    // only load after exhibits have loaded to avoid race condition on loading splits for first time
    // where partition provider endpoint returns blank because splits are still being generated
    enabled: !areExhibitsLoading,
  })

  return (
    <>
      <Helmet>
        <title>Demand: {caseObj?.name ?? ""} - EvenUp</title>
      </Helmet>
      <FilesContent
        caseId={caseId as string}
        isCaseLoading={isCaseLoading}
        areExhibitsLoading={areExhibitsLoading}
        areProvidersLoading={areProvidersLoading}
        caseObj={caseObj}
        exhibits={exhibits}
        partitionProviders={partitionProviders}
        virtualized={virtualized}
      />
    </>
  )
}

export default Files
