export const INPUT_PROPS = {
  currency: {
    min: 0,
    step: 1,
  },
  percentage: {
    min: 0,
    step: 0.1,
  },
}

export const CALCULATION_FIELDS = [
  "annual_salary",
  "supplemental_income",
  "end_of_impairment_date",
  "hourly_salary",
  "hours_worked",
  "working_hours_timeframe",
  "inflation_rate",
  "level_of_education",
  "occupation",
  "pre_judgement_rate",
  "salary_growth_rate",
  "start_of_impairment_date",
]

export const SALARY_FORMS = {
  annual: {
    key: "annual",
    fields: ["annual_salary", "supplemental_income"],
  },
  hourly: {
    key: "hourly",
    fields: ["hourly_salary", "hours_worked", "working_hours_timeframe"],
  },
  noSalary: {
    key: "noSalary",
    fields: ["occupation", "level_of_education"],
  },
  main: {
    key: "mainForm",
    fields: [
      "start_of_impairment_date",
      "end_of_impairment_date",
      "income_loss_story",
      "salary_growth_rate",
      "inflation_rate",
      "pre_judgement_rate",
    ],
  },
}

export const SALARY_INFO_ALL_SECTIONS_FIELDS = [
  ...SALARY_FORMS.annual.fields,
  ...SALARY_FORMS.hourly.fields,
  ...SALARY_FORMS.noSalary.fields,
]

export const INITIAL_FORM_STATE = {
  start_of_impairment_date: "",
  end_of_impairment_date: "",
  income_loss_story: "",
  annual_salary: "",
  supplemental_income: 0,
  salary_growth_rate: 0,
  inflation_rate: 0,
  pre_judgement_rate: 0,
  hourly_salary: "",
  hours_worked: "",
  working_hours_timeframe: "year",
  occupation: "",
  level_of_education: "",
  salary_information_type: SALARY_FORMS.annual.key,
}

export const NON_NULL_FIELDS = [
  "salary_growth_rate",
  "inflation_rate",
  "pre_judgement_rate",
  "supplemental_income",
]

export const SKIP_CLEAN = ["working_hours_timeframe"]

export const COLUMNS = [
  "start_date",
  "end_date",
  "loss_of_income",
  "loss_of_supplemental_income",
  "net_loss",
  "time_adj_net_loss",
]

export const COLUMN_TITLES = {
  start_date: "Start Date",
  end_date: "End Date",
  loss_of_income: "Lost Income",
  loss_of_supplemental_income: "(-) Suppl. Income",
  net_loss: "Net Loss ($)",
  time_adj_net_loss: "Time Adj. Net Loss ($)",
}

export const INVALID_DATE_ERROR_MESSAGE = "Start Date must be before End Date"

export const DEBOUNCE_DELAY = 300
