import React, { useState, useRef, useEffect, useCallback } from "react"

import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Drawer from "@mui/material/Drawer"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import { makeStyles } from "tss-react/mui"
import Person from "@mui/icons-material/Person"
import MenuRounded from "@mui/icons-material/MenuRounded"
import { GlobalStyles, Grow, MenuList, Paper, Popper, Skeleton } from "@mui/material"
import Close from "@mui/icons-material/Close"
import Logo from "common/logo.svg?react"

import { Link, NavLink } from "react-router-dom"

import { useAuth } from "hooks/useAuth"
import useUser from "hooks/useUser"
import { OSF } from "common/models/roles"
import {
  APP_HEADER_ID,
  APP_VERSION,
  BUILD_TIMESTAMP,
  HEADER_NAV_HEIGHT_SPACE_MULTIPLIER,
  HEADER_NAV_SCROLLTO_SPACE_MULTIPLIER,
  HEADER_NAV_Z_INDEX,
} from "./constants"
import { VERSION_EVENT_TOPIC } from "message-broker/topics"
import {
  EnvNotification,
  HeaderLeftWrapper,
  StickyHeaderContainer,
  VersionUpdateNotification,
} from "./styled"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import getApmEnvironment from "infrastructure/env/getEnvironment"
import { AuthorizedUser, User } from "common/models/user"
import { usePermissions } from "permissions/usePermissions"
import { useTheme } from "@emotion/react"
import { reload } from "./utils"
import { withSuspense } from "common/withSuspense"
import { ExpandMore } from "@mui/icons-material"
import { ImpersonationBanner } from "impersonation/ImpersonationBanner"

const useStyles = makeStyles()(theme => ({
  nav: {
    display: "flex",
    flexDirection: "row",
    zIndex: HEADER_NAV_Z_INDEX,
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER),
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 12px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(0, 2, 0, 0),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1, 0, 0),
    },
    position: "fixed",
    top: 0,
    width: "100vw",
    "@media print": {
      display: "none",
    },
  },
  logo: {
    display: "flex",
    width: "200px",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
  },
  linksAndUser: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  links: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  mobileLinks: {
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    border: "2px solid",
    transition: "color 0.2s ease-in",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.action.hover,
    },
  },
  drawerList: {
    width: "250px",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    color: theme.palette.secondary.main,
  },
  navLink: {
    textDecoration: "none",
  },
  navButton: {
    position: "relative",
  },
  navButtonActive: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  activeBar: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(0.5),
  },
  navListButton: {
    borderRadius: 0,
    padding: theme.spacing(2, 2),
    justifyContent: "left",
  },
  activeNavListButton: {
    borderRight: "4px solid",
  },
  navDropdownListButton: {
    borderRadius: 0,
    padding: theme.spacing(1, 1),
    justifyContent: "left",
  },
  activeNavDropdownListButton: {
    borderLeft: "4px solid",
  },
  betaTag: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: "12px",
    fontWeight: 700,
    height: "15px",
    padding: theme.spacing(1.5, 0),
    marginLeft: theme.spacing(1),
    "& > .MuiSvgIcon-root": {
      color: theme.palette.common.white,
      fontSize: "12px",
    },
  },
}))

const NavButton: React.FC<{ to: string; children: React.ReactNode; end?: boolean }> = ({
  to,
  end,
  children,
}) => {
  const { classes } = useStyles()
  return (
    <NavLink className={classes.navLink} to={to} end={end}>
      {({ isActive }) => (
        <Button
          variant="text"
          className={`${classes.navButton}${isActive ? " " + classes.navButtonActive : ""}`}
        >
          {children}
          {isActive && <div className={classes.activeBar} />}
        </Button>
      )}
    </NavLink>
  )
}

const NavDropdown: React.FC<{
  to: string
  children: React.ReactNode
  end?: boolean
  buttonText: React.ReactNode
}> = ({ to, children, end, buttonText }) => {
  const theme = useTheme()
  const { classes } = useStyles()
  const [open, setOpen] = React.useState(false)
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const menuRef = React.useRef<HTMLUListElement>(null)

  const handleShow = useCallback(() => {
    if (open) return
    setOpen(true)
  }, [open])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleButtonMouseLeave = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    // close the menu if the mouse leaves the button in any direction but the menu
    if (event.relatedTarget !== menuRef.current) {
      setOpen(false)
    }
  }, [])

  return (
    <Box>
      <NavLink to={to} end={end} onMouseOver={handleShow}>
        {({ isActive }) => (
          <Button
            ref={buttonRef}
            variant="text"
            color={isActive ? "secondary" : "primary"}
            endIcon={<ExpandMore />}
            onClick={handleClose}
            onMouseLeave={handleButtonMouseLeave}
          >
            {buttonText}
            {isActive && <div className={classes.activeBar} />}
          </Button>
        )}
      </NavLink>
      <Popper
        open={open}
        anchorEl={buttonRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        sx={{ zIndex: theme.zIndex.appBar }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <MenuList onMouseLeave={handleClose} ref={menuRef} onClick={handleClose}>
                {children}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

const Links: React.FC<{
  user: User
}> = ({ user }) => {
  const { classes } = useStyles()

  const {
    canViewSettlementsEnabled,
    canPostSettlementsEnabled,
    canViewExecutiveAnalyticsEnabled,
    showBeamerEnabled,
  } = usePermissions()

  if (user.isInternal) {
    return (
      <Box className={classes.links}>
        <NavButton to="/cases">Cases</NavButton>
        <NavButton to="/requests">Requests</NavButton>
        <NavButton to="/demands">Demands</NavButton>
        <SettlementLinks
          canViewSettlementsEnabled={canViewSettlementsEnabled}
          canPostSettlementsEnabled={canPostSettlementsEnabled}
        />
        {canViewExecutiveAnalyticsEnabled && (
          <NavButton to="/executive-analytics">Executive Analytics</NavButton>
        )}
        <NavButton to="/search">Search</NavButton>
        {showBeamerEnabled && <Button className="beamerTrigger">Notifications</Button>}
      </Box>
    )
  }

  if (user.isExternal) {
    return (
      <Box className={classes.links}>
        <NavButton to="/cases">Cases</NavButton>
        <NavButton to="/requests">Requests</NavButton>
        <SettlementLinks
          canViewSettlementsEnabled={canViewSettlementsEnabled}
          canPostSettlementsEnabled={canPostSettlementsEnabled}
        />
        {canViewExecutiveAnalyticsEnabled && (
          <NavButton to="/executive-analytics">Executive Analytics</NavButton>
        )}
        {showBeamerEnabled && <Button className="beamerTrigger">Notifications</Button>}
      </Box>
    )
  }
  if (user.role === OSF) {
    return (
      <Box className={classes.links}>
        <NavButton to="/requests">Requests</NavButton>
        <NavButton to="/demands">Demands</NavButton>
      </Box>
    )
  }

  return null
}

function SettlementLinks({
  canViewSettlementsEnabled,
  canPostSettlementsEnabled,
}: {
  canViewSettlementsEnabled: boolean
  canPostSettlementsEnabled: boolean
}) {
  if (!canViewSettlementsEnabled && !canPostSettlementsEnabled) {
    return null
  }

  return (
    <>
      {canViewSettlementsEnabled && (
        <NavDropdown to="/settlements" buttonText="Settlements">
          <NavDropdownListButton to="/settlements/get-estimate" end>
            Get Estimate
          </NavDropdownListButton>
          <NavDropdownListButton to="/settlements/saved">Saved Cases</NavDropdownListButton>
          {canPostSettlementsEnabled && (
            <NavDropdownListButton to="/settlements/share" end>
              Share
            </NavDropdownListButton>
          )}
        </NavDropdown>
      )}
      {canPostSettlementsEnabled && !canViewSettlementsEnabled && (
        <NavDropdown to="/settlements/share" buttonText="Settlements">
          <NavDropdownListButton to="/settlements/share" end>
            <Box sx={{ minWidth: "150px", textAlign: "left" }}>Share</Box>
          </NavDropdownListButton>
        </NavDropdown>
      )}
    </>
  )
}

const NavDropdownListButton: React.FC<{
  to: string
  children: React.ReactNode
  end?: boolean
}> = ({ to, end = false, children }) => {
  const { classes, cx } = useStyles()
  return (
    <NavLink className={classes.navLink} to={to} end={end}>
      {({ isActive }) => (
        <Button
          variant="text"
          color={isActive ? "secondary" : "primary"}
          className={cx(classes.navDropdownListButton, isActive && classes.activeNavDropdownListButton)}
          fullWidth
        >
          {children}
        </Button>
      )}
    </NavLink>
  )
}

const NavListButton: React.FC<{
  to: string
  children: React.ReactNode
  end?: boolean
}> = ({ to, end = false, children }) => {
  const { classes, cx } = useStyles()
  return (
    <NavLink className={classes.navLink} to={to} end={end}>
      {({ isActive }) => (
        <Button
          variant="text"
          color={isActive ? "secondary" : "primary"}
          className={cx(classes.navListButton, isActive && classes.activeNavListButton)}
          fullWidth
          size="large"
        >
          {children}
        </Button>
      )}
    </NavLink>
  )
}

const NavListHierarchy: React.FC<{ children: React.ReactNode; parent: React.ReactNode }> = ({
  children,
  parent,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>{parent}</Box>
      <Box sx={{ paddingLeft: 2 }}>{children}</Box>
    </Box>
  )
}

const MobileLinks: React.FC<{
  user: User
}> = ({ user }) => {
  const [open, setOpen] = useState(false)
  const auth = useAuth()
  const { classes, cx } = useStyles()
  const {
    canViewSettlementsEnabled,
    canPostSettlementsEnabled,
    canViewExecutiveAnalyticsEnabled,
    showBeamerEnabled,
  } = usePermissions()

  const handleLogout = () => {
    setOpen(false)
    auth.logout()
  }

  if (user.isInternal) {
    return (
      <>
        <IconButton onClick={() => setOpen(prev => !prev)}>
          <MenuRounded />
        </IconButton>

        <Drawer open={open} onClick={() => setOpen(false)} onClose={() => setOpen(false)} anchor="right">
          <Box className={classes.drawerList}>
            <Box display="flex" justifyContent="end">
              <IconButton>
                <Close />
              </IconButton>
            </Box>
            <NavListButton to="/cases">Cases</NavListButton>
            <NavListButton to="/requests">Requests</NavListButton>
            <NavListButton to="/demands">Demands</NavListButton>
            <SettlementMobileLinks
              canViewSettlementsEnabled={canViewSettlementsEnabled}
              canPostSettlementsEnabled={canPostSettlementsEnabled}
            />
            {canViewExecutiveAnalyticsEnabled && (
              <NavListButton to="/executive-analytics">Executive Analytics</NavListButton>
            )}
            <NavListButton to="/search">Search</NavListButton>
            {showBeamerEnabled && (
              <Button className={cx(classes.navListButton, "beamerTrigger")}>Notifications</Button>
            )}
            <Divider />
            <Typography
              className={classes.navListButton}
              variant="caption"
              color="textPrimary"
            >{`Logged in as ${user.email}`}</Typography>
            <NavListButton to="/settings">Settings</NavListButton>
            <Button
              variant="text"
              className={cx(classes.navListButton)}
              fullWidth
              size="large"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Drawer>
      </>
    )
  }

  if (user.isExternal) {
    return (
      <>
        <IconButton onClick={() => setOpen(prev => !prev)}>
          <MenuRounded />
        </IconButton>

        <Drawer open={open} onClick={() => setOpen(false)} onClose={() => setOpen(false)} anchor="right">
          <Box className={classes.drawerList}>
            <Box display="flex" justifyContent="end">
              <IconButton>
                <Close />
              </IconButton>
            </Box>
            <NavListButton to="/cases">Cases</NavListButton>
            <NavListButton to="/requests">Requests</NavListButton>
            <SettlementMobileLinks
              canViewSettlementsEnabled={canViewSettlementsEnabled}
              canPostSettlementsEnabled={canPostSettlementsEnabled}
            />
            {canViewExecutiveAnalyticsEnabled && (
              <NavListButton to="/executive-analytics">Executive Analytics</NavListButton>
            )}
            {showBeamerEnabled && (
              <Button className={cx(classes.navListButton, "beamerTrigger")}>Notifications</Button>
            )}
            <Divider />
            <Typography
              className={classes.navListButton}
              variant="caption"
              color="textPrimary"
            >{`Logged in as ${user.email}`}</Typography>
            <NavListButton to="/settings">Settings</NavListButton>
            <Button
              variant="text"
              className={cx(classes.navListButton)}
              fullWidth
              size="large"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Drawer>
      </>
    )
  }

  return null
}

function SettlementMobileLinks({
  canViewSettlementsEnabled,
  canPostSettlementsEnabled,
}: {
  canViewSettlementsEnabled: boolean
  canPostSettlementsEnabled: boolean
}) {
  if (!canViewSettlementsEnabled && !canPostSettlementsEnabled) {
    return null
  }

  return (
    <>
      {canViewSettlementsEnabled && (
        <NavListHierarchy parent={<NavListButton to="/settlements">Settlements</NavListButton>}>
          <NavListButton to="/settlements/get-estimate" end>
            Get Estimate
          </NavListButton>
          <NavListButton to="/settlements/saved">Saved Cases</NavListButton>
          {canPostSettlementsEnabled && (
            <NavListButton to="/settlements/share" end>
              Share
            </NavListButton>
          )}
        </NavListHierarchy>
      )}
      {canPostSettlementsEnabled && !canViewSettlementsEnabled && (
        <NavListHierarchy parent={<NavListButton to="/settlements/share">Settlements</NavListButton>}>
          <NavListButton to="/settlements/share" end>
            Share
          </NavListButton>
        </NavListHierarchy>
      )}
    </>
  )
}

const UserMenu: React.FC<{ user: User }> = ({ user }) => {
  const auth = useAuth()
  const { classes } = useStyles()

  // user menu controls
  const menuRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const handleLogout = () => {
    setOpen(false)
    auth.logout()
  }

  const handleClose = () => setOpen(false)

  return (
    <Box>
      <Avatar className={classes.icon} ref={menuRef} onClick={() => setOpen(true)} data-test="user-menu-icon">
        <Person />
      </Avatar>
      <Menu
        id="user-menu"
        anchorEl={menuRef.current}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disabled onClick={handleClose}>
          {user.email}
        </MenuItem>
        <MenuItem data-test="settings-button" component={NavLink} to="/settings">
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout} data-test="logout-button">
          Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}

const ServiceVersionUpdate: React.FC = () => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false)

  useEffect(() => {
    function handleVersionNotify(event: Event) {
      if (!navigator || !navigator.serviceWorker) {
        return
      }

      if (event instanceof MessageEvent && event.data.topic === VERSION_EVENT_TOPIC) {
        const version = event.data.payload?.version as string | undefined
        const timestamp = event.data.payload?.timestamp as number | undefined

        if (
          version &&
          version !== APP_VERSION &&
          timestamp &&
          BUILD_TIMESTAMP &&
          timestamp > BUILD_TIMESTAMP
        ) {
          setNewVersionAvailable(true)
        }
      }
    }
    navigator.serviceWorker.addEventListener("message", handleVersionNotify)
    return () => navigator.serviceWorker.removeEventListener("message", handleVersionNotify)
  }, [])

  if (!newVersionAvailable) return null

  return (
    <VersionUpdateNotification severity="info" variant="filled" onClose={() => setNewVersionAvailable(false)}>
      A new version is available.
      <Button size="small" variant="text" onClick={reload}>
        Refresh
      </Button>
    </VersionUpdateNotification>
  )
}

const ServiceEnvNotification: React.FC = () => {
  const { isFeatureEnabled } = useFeatures()
  let env = getApmEnvironment() ?? "local"
  const hostname = window.location.hostname
  if (hostname.match(/\.eph\.evenup\.law$/) || hostname.match(/\.int\.evenup\.law$/)) {
    env = hostname.split(".")[0]
  }

  if (!isFeatureEnabled(FEATURES.SHOW_APP_VERSION)) {
    return null
  }

  return (
    <EnvNotification env={env}>
      <span>Env: {env}</span>
      {APP_VERSION && <span>Build version: {APP_VERSION}</span>}
    </EnvNotification>
  )
}

const LinksContainerFallback: React.FC = () => {
  return (
    <Box sx={{ display: "flex", gap: 4 }}>
      <Skeleton variant="rectangular" width={90} height={40} />
      <Skeleton variant="rectangular" width={90} height={40} />
      <Skeleton variant="circular" width={40} height={40} />
    </Box>
  )
}

const AuthorizedLinkContainer: React.FC<{ user: AuthorizedUser }> = withSuspense(
  ({ user }) => {
    // grab data with suspense, rely on parent to implement suspense boundary with fallback
    // prefetch permissions, so child components can use cached result
    const _result = usePermissions()

    const { classes } = useStyles()

    return (
      <>
        <Box className={classes.linksAndUser}>
          <Links user={user} />
          <UserMenu user={user} />
        </Box>
        <Box className={classes.mobileLinks}>
          <MobileLinks user={user} />
        </Box>
      </>
    )
  },
  <LinksContainerFallback />
)

// Load all the information needed for links and then pass it down to child components from here
// e.g, user info, feature flags, etc.
const LinksContainer: React.FC = withSuspense(
  () => {
    // grab data with suspense, rely on parent to implement suspense boundary with fallback
    const { user } = useUser({ suspense: true })

    return user.isAuthorized ? (
      <AuthorizedLinkContainer user={user} />
    ) : (
      <Button color="secondary" component={NavLink} to="/login">
        Login
      </Button>
    )
  },
  <LinksContainerFallback />
)

export default function Header(): React.ReactNode {
  const { classes } = useStyles()
  const theme = useTheme()

  return (
    <>
      <nav className={classes.nav} data-test="header" id={APP_HEADER_ID}>
        <GlobalStyles
          styles={{
            html: {
              scrollPaddingTop: theme.spacing(HEADER_NAV_SCROLLTO_SPACE_MULTIPLIER),
            },
          }}
        />
        <ServiceEnvNotification />
        <ImpersonationBanner />
        <HeaderLeftWrapper>
          <Link to="/" className={classes.logo}>
            <Logo />
          </Link>
          <ServiceVersionUpdate />
        </HeaderLeftWrapper>
        <LinksContainer />
      </nav>
      <StickyHeaderContainer className="sticky-header-container" />
    </>
  )
}
