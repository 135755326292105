import { PaginatedList } from "common/models/pagination"
import { Matter, MatterDto, Plaintiff, PlaintiffDto, MinimalFirm } from "common/types/matter"
import {
  CreateMinimalMatter,
  CreateMinimalMatterDto,
  FlagDTO,
  MinimalCreateMatterResponse,
  ReviewItemDTO,
  ReviewRunDTO,
} from "./types"
import { Flag } from "documents/flags/types"
import { ReviewItem, ReviewRun } from "review/store/types"
import { toCamelCase, toSnakeCase } from "common/helpers/object"
import { EditorContent } from "common/form-components/rich-text"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { Citation, FileUploadServiceMetaData } from "common/citation-viewer/store"
import { ChatDTO, Chat, ChatListDTO, QUESTION_STATUS } from "cases/CaseQAModal/types"

export class MatterDeserializer {
  static definitionFromJSON(matter: MatterDto): Matter {
    return {
      pk: matter.pk,
      plaintiffs: PlaintiffDeserializer.fromListJSON(matter.plaintiffs),
      firm: FirmDeserializer.definitionFromJSON(matter.firm),
      carrier: null,
      createdAt: matter.created_at,
      updatedAt: matter.updated_at,
      summaryRequest: matter?.summary_request,
    }
  }

  static fromListJSON(matters: MatterDto[]): Matter[] {
    return matters.map(MatterDeserializer.definitionFromJSON)
  }

  static fromPaginatedListJSON(matters: PaginatedList<MatterDto>): PaginatedList<Matter> {
    return new PaginatedList(
      MatterDeserializer.fromListJSON(matters.items),
      matters.count,
      matters.pageSize,
      matters.page
    )
  }

  static fromMinimalCreationResponse(data: MinimalCreateMatterResponse): MinimalCreateMatterResponse {
    return data
  }
}

export class MatterSerializer {
  static minimalCreateToJson(data: CreateMinimalMatter): CreateMinimalMatterDto {
    return {
      first_name: data.firstName,
      last_name: data.lastName,
      firm_id: data.firmId,
      title: data.title,
      initialize_summary_request: data.initializeSummaryRequest,
    }
  }
}

export class PlaintiffDeserializer {
  static definitionFromJSON(plaintiff: PlaintiffDto): Plaintiff {
    return {
      id: plaintiff.id,
      firstName: plaintiff.first_name,
      lastName: plaintiff.last_name,
      title: plaintiff.title,
    }
  }

  static fromListJSON(plaintiffs: PlaintiffDto[]): Plaintiff[] {
    return plaintiffs.map(PlaintiffDeserializer.definitionFromJSON)
  }
}

export class FirmDeserializer {
  static definitionFromJSON(firm: MinimalFirm): MinimalFirm {
    return firm
  }
}

export class FlagDeserializer {
  static fromJSON(flag: FlagDTO): Flag {
    const result: Flag = {
      id: flag.pk,
      category: flag.category,
      appointment: flag.appointment,
      quote: flag.quote,
      reason: flag.reason,
    }

    if (flag.partitions) {
      result.relations = flag.partitions.map(partition => ({
        partitionId: partition.partition_id,
        page: partition.page_number,
      }))
    }

    return result
  }

  static fromListJSON(flags: FlagDTO[]): Record<string, Flag> {
    const result: Record<string, Flag> = {}

    for (const flag of flags) {
      result[flag.pk] = FlagDeserializer.fromJSON(flag)
    }

    return result
  }
}

export class FlagSerializer {
  static newFlagToJSON(flag: Omit<Flag, "id">): Omit<FlagDTO, "pk"> {
    const result: Omit<FlagDTO, "pk"> = {
      category: flag.category,
      appointment: flag.appointment,
      quote: flag.quote,
      reason: flag.reason,
    }

    if (flag.relations) {
      result.partitions = flag.relations.map(partition => ({
        partition_id: partition.partitionId,
        page_number: partition.page,
      }))
    }

    return result
  }

  static flagToJSON(flag: Flag): FlagDTO {
    const result: FlagDTO = {
      category: flag.category,
      appointment: flag.appointment,
      quote: flag.quote,
      reason: flag.reason,
      pk: flag.id,
    }

    if (flag.relations) {
      result.partitions = flag.relations.map(partition => ({
        partition_id: partition.partitionId,
        page_number: partition.page,
      }))
    }

    return result
  }
}

export class ReviewRequestSerializer {
  static reviewItemToJSON(
    reviewItem: Pick<ReviewItem, "id"> & Partial<Omit<ReviewItem, "references">>
  ): Pick<ReviewItemDTO, "id"> & Partial<ReviewItemDTO> {
    return toSnakeCase(reviewItem)
  }
}

export class ReviewRequestDeserializer {
  static fromJSON(review: ReviewRunDTO): ReviewRun {
    const results = review.results.map(result => ({
      ...toCamelCase(result),
      originalText:
        result.output_text_type === "plain_text"
          ? result.original_text.replaceAll(/<\/?(b|i|var|u|mark).*?\/?>/gi, "")
          : result.original_text,
      suggestedText:
        result.output_text_type === "plain_text"
          ? result.suggested_text.replaceAll(/<\/?(b|i|var|u|mark).*?\/?>/gi, "")
          : result.suggested_text,
      references: result.references.map(toCamelCase),
    }))
    const content = new EditorContent(review.content)

    return {
      ...toCamelCase(review),
      results,
      content,
      resultContent: review.content as EditorRoot,
    }
  }

  static fromSearchJSON(
    reviews: Omit<ReviewRunDTO, "content" | "results">[],
    reviewRequestId: string
  ): Nullable<ReviewRun> {
    if (!reviews.length) return null

    const review = reviews.find(review => review.review_request_id === reviewRequestId)

    if (!review) return null

    return {
      ...toCamelCase(review),
      content: null,
      resultContent: null,
      results: [],
    } as ReviewRun
  }

  static fromCreateJSON(review: Omit<ReviewRunDTO, "content" | "results">): ReviewRun {
    return {
      ...toCamelCase(review),
      content: null,
      resultContent: null,
      results: [],
    } as ReviewRun
  }
}

export class CaseQADeserializer {
  static fromJSON(data: ChatDTO): Chat {
    const newAnswer = data.answer
      ? data.answer.map(answer => {
          return {
            ...answer,
            citations: answer.citations
              ? answer.citations.map(citation => {
                  return {
                    annotationFileId: citation.annotation_file_id,
                    fileUploadId: citation.file_upload_id ? citation.file_upload_id : "",
                    annotationRequestId: citation.annotation_request_id,
                    caseId: citation.case_id,
                    pageNumber: citation.page_number,
                    lines: citation.lines,
                  }
                })
              : [],
          }
        })
      : []
    return {
      ...data,
      isHelpful: data.is_helpful,
      comment: data.comment === null || data.comment === undefined ? "" : data.comment,
      answer: newAnswer,
      createdAt: data.created_at,
    }
  }

  static fromQuestionListJson(question: ChatListDTO): Chat[] {
    return question.data.map(questionData => {
      return {
        id: questionData.id,
        question: questionData.question,
        createdAt: questionData.created_at,
        status: QUESTION_STATUS.NOT_RECEIVED_FROM_BACKEND,
        answer: [],
        isHelpful: null,
        comment: "",
      }
    })
  }
}

export class FileUploadServiceDeserializer {
  static fromJSON(fileData: FileUploadServiceMetaData): Partial<Citation> {
    return {
      filename: fileData?.meta?.original_file?.filename,
      signedFileUrl: fileData?.results[0]?.signed_file_url,
      numberOfPages: fileData?.meta?.original_file?.number_of_pages,
    }
  }
}
