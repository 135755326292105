import styled from "@emotion/styled"
import { Box, Divider } from "@mui/material"
import { DynamicColorChip } from "common/DynamicColorChip"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { UserExhibit } from "exhibit-builder/store/types"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { pluralize, truncateMiddle } from "utils"

const Container = styled(Box)({
  display: "flex",
})

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  margin: theme.spacing(0, 2),
}))

const Name = styled.div({
  display: "flex",
  alignItems: "center",
})

export function CollapsedDisplayName({ id }: { id: UserExhibit["id"] }) {
  const exhibitName = useExhibitBuilderStore(filesSelectors.getUserExhibitById(id)).name
  const type = useExhibitBuilderStore(state => state.exhibitBuilderType)
  const userExhibit = useExhibitBuilderStore(filesSelectors.getUserExhibitById(id))
  const sortingProvider = useExhibitBuilderStore(state =>
    userExhibit.sortingProviderId ? state.providers[userExhibit.sortingProviderId] : null
  )
  const files = useExhibitBuilderStore(filesSelectors.getUserExhibitFiles(id))

  return (
    <Container>
      <Name>
        {truncateMiddle(exhibitName, 60)}{" "}
        {type === EB_DOC_TYPE.DEMAND && (
          <span data-test="collapsed-name-files">
            ({files.length} {pluralize({ count: files.length, noun: "file" })})
          </span>
        )}
      </Name>
      {sortingProvider && (
        <>
          <StyledDivider orientation="vertical" flexItem />
          <DynamicColorChip label={sortingProvider.name} color={sortingProvider.color} />
        </>
      )}
    </Container>
  )
}
