import { useMemo, useRef } from "react"
import { debounce, DebouncedFunc, DebounceSettingsLeading } from "lodash"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any[]) => void

const DEBOUNCED_TIME = 800

export function useDebouncedCallback<T extends Callback>(
  callback: T,
  debounceTime = DEBOUNCED_TIME,
  options?: DebounceSettingsLeading
): DebouncedFunc<T> {
  const ref = useRef<T>(callback)
  const optionsRef = useRef(options)

  ref.current = callback
  optionsRef.current = options

  return useMemo(
    () => debounce<T>(((...args) => ref.current(...args)) as T, debounceTime, optionsRef.current),
    [ref, debounceTime]
  )
}
