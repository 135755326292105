import { useCallback } from "react"

import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import MuiTooltip from "@mui/material/Tooltip"
import { useMutation } from "@tanstack/react-query"
import { makeStyles, withStyles } from "tss-react/mui"

// Internal Components
import { BlackTooltip } from "common/Tooltip"
import StatusLabel from "common/status/StatusLabel"
import AssigneeAvatar from "../../AssigneeAvatar"

// Icons
import DownloadIcon from "@mui/icons-material/GetApp"

// Helpers
import { downloadRequestDemandPackageFiles } from "api"
import useUser from "hooks/useUser"
import { formatTimeSinceNow } from "utils"
import getStatusToProperties from "../../RequestStatus/requestStatusProperties"
import {
  canDemandPackageBeDownloaded,
  canUserSeeDemandPackageDownload,
  canUserSeeFirmCell,
  canUserSeeSubmitter,
  canUserViewAssigneesCell,
} from "../../permissions/requestAction"
import { downloadAllFiles, getAnnotationTag } from "../../utils"

import CommentOutlined from "@mui/icons-material/CommentOutlined"
import { TAG_LABEL_TYPE, TagLabels } from "common/TagLabel"
import { roundDown } from "common/math"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { INTERNAL_ROLES_VALUES } from "common/models/roles"
import { DocumentTypeCell, PlaintiffNameCell } from "common/tables/cells/Request"
import { Column } from "common/tables/types"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { compact, memoize } from "lodash"
import { DOCUMENT_TYPES } from "requests/enums"
import { INTAKE_STATUSES } from "../../constants"
import { RequestsListItem } from "../types"
import { TOOLTIP_TEXT_MAP } from "./constants"
import styled from "@emotion/styled"
import { TableCellWithLink } from "common/tables/cells/TableCellWithLink"
import { TableCell } from "@mui/material"

const useStyles = makeStyles()(theme => {
  return {
    plaintiffName: {
      display: "grid",
      gridTemplateColumns: "2fr 1fr",
      alignItems: "center",
    },
    linkCell: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    creditCellContainer: {},
    avatarContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    statusContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",

      margin: "-2px 0",

      "& > *": {
        whiteSpace: "nowrap",
        margin: "2px 0",
      },

      "& > :not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
  }
})

const PlaintiffNameCellWrapper = ({ record }: { record: RequestsListItem }) => {
  const { classes } = useStyles()
  return (
    <Box className={classes.plaintiffName}>
      <PlaintiffNameCell record={record} />
    </Box>
  )
}

const FirmCell = ({ record }: { record: RequestsListItem }) => {
  return <Box>{record.firm.name}</Box>
}

const SubmitterCell = ({ record }: { record: RequestsListItem }) => {
  if (!record.submitter) {
    return null
  }

  return (
    <Box>
      {record.submitter.first_name} {record.submitter.last_name}
    </Box>
  )
}

const StatusCell = ({ record }: { record: RequestsListItem }) => {
  const { user } = useUser()
  const { classes } = useStyles()
  const status = getStatusToProperties(record.intake_status, user.role)
  const hasMissingDocs = [
    INTAKE_STATUSES.missingDocuments,
    INTAKE_STATUSES.missingDocsPartialResponse,
  ].includes(record.intake_status)

  const subtext =
    hasMissingDocs && record.days_missing_docs ? `${record.days_missing_docs} days since reported` : ""

  const tags = []
  if (record.revised) {
    tags.push({ type: TAG_LABEL_TYPE.INFO, label: "Revision" })
  }

  if (record.annotation_status && user.isInternal) {
    tags.push(getAnnotationTag(record.annotation_status))
  }

  return (
    <Box className={classes.statusContainer}>
      <StatusLabel
        status={record.intake_status}
        text={status.text}
        color={status.color}
        filled={status.filled}
        subtext={subtext}
      />
      <TagLabels tags={tags} />
    </Box>
  )
}

const CreditCell = ({ record }: { record: RequestsListItem }) => {
  const title = record.credit_comments
  const { classes } = useStyles()

  return (
    <Box className={classes.creditCellContainer}>
      <Box>{roundDown(record.credit_amount, { decimalPlaces: 2 })}</Box>
      {title && (
        <Box display="flex" minWidth="20px">
          <CommentTooltip title={title} placement="bottom" arrow>
            <CommentOutlined fontSize="small" />
          </CommentTooltip>
        </Box>
      )}
    </Box>
  )
}

const Assignees = ({ record }: { record: RequestsListItem }) => {
  const { user } = useUser()
  const { classes } = useStyles()
  const assignees = user.isInternal ? record.internal_assignees : record.external_assignees

  return (
    <Box className={classes.avatarContainer}>
      {assignees?.map(assignee => {
        return (
          <AssigneeAvatar
            firstName={assignee.first_name}
            lastName={assignee.last_name}
            key={`assignee-avatar-${assignee.pk}`}
          />
        )
      })}
    </Box>
  )
}

const LastModifiedCell = ({ record }: { record: RequestsListItem }) => {
  return (
    <Box style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
      {formatTimeSinceNow(record.updated_at)}
    </Box>
  )
}

const DownloadDemandPackageCell = ({ record }: { record: RequestsListItem }) => {
  const { showErrorMessage } = useHandleMessages()
  const isMedicalChronology = [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY, DOCUMENT_TYPES.PREMIUM_MEDCHRON].includes(
    record.type
  )
  const tooltipTextMap = isMedicalChronology
    ? TOOLTIP_TEXT_MAP[DOCUMENT_TYPES.MEDICAL_CHRONOLOGY]
    : TOOLTIP_TEXT_MAP[DOCUMENT_TYPES.STANDARD]
  const downloadAllFilesMutation = useMutation(
    async () => {
      await downloadAllFiles(downloadRequestDemandPackageFiles, `demand-package`, record.pk)
    },
    {
      onSuccess: () => {
        amplitudeApm.trackEvent(
          new RequestAnalyticEvent(RequestAnalyticsEventTypes.DemandPacketDownloaded, {
            request_id: record.pk,
          })
        )
      },
      onError: error => {
        showErrorMessage({
          message:
            "Something went wrong downloading all files. Try downloading individual files and report an issue if your problem persists.",
          error,
        })
      },
    }
  )

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      downloadAllFilesMutation.mutateAsync()
    },
    [downloadAllFilesMutation]
  )

  const demandPackageCanBeDownloaded = canDemandPackageBeDownloaded(record)
  const tooltipText = demandPackageCanBeDownloaded ? tooltipTextMap.ready : tooltipTextMap.notReady

  return (
    <BlackTooltip arrow title={tooltipText}>
      <div>
        <IconButton disabled={!demandPackageCanBeDownloaded} onClick={handleClick}>
          <DownloadIcon />
        </IconButton>
      </div>
    </BlackTooltip>
  )
}

const CommentTooltip = withStyles(MuiTooltip, {
  arrow: {
    color: "#000",
  },
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
})

const CustomTableCell: Column<RequestsListItem>["tableCellComponent"] = props => {
  return TableCellWithLink({
    ...props,
    to: `/requests/${props.record.pk}`,
  })
}

const StyledCustomTableCell = styled(CustomTableCell)(({ theme }) => ({
  maxWidth: "275px",
  overflowWrap: "anywhere",
  [theme.breakpoints.down("xl")]: {
    maxWidth: "400px",
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: "500px",
  },
}))

const defaultHeaderStyle = {
  whiteSpace: "nowrap",
}

export const getColumns: ({
  user,
}: {
  user: ReturnType<typeof useUser>["user"]
}) => Column<RequestsListItem>[] = memoize(({ user }) => {
  const items: Nullable<Column<RequestsListItem>>[] = [
    {
      id: "plaintiff_name",
      text: "Plaintiff",
      align: "left",
      tableCellComponent: StyledCustomTableCell,
      cellContentComponent: PlaintiffNameCellWrapper,
      style: defaultHeaderStyle,
    },
    {
      id: "firm",
      text: "Firm",
      align: "left",
      restrictView: canUserSeeFirmCell,
      cellContentComponent: FirmCell,
      style: defaultHeaderStyle,
    },
    {
      id: "document_type",
      text: "Document Type",
      cellContentComponent: DocumentTypeCell,
      // 175px fits all the different Document Types text
      style: { ...defaultHeaderStyle, width: "175px" },
    },
    {
      id: "submitter",
      text: "Requester",
      align: "left",
      cellContentComponent: SubmitterCell,
      restrictView: canUserSeeSubmitter,
      style: defaultHeaderStyle,
    },
    {
      id: "intake_status",
      text: "Status",
      align: "left",
      sortable: true,
      cellContentComponent: StatusCell,
      style: defaultHeaderStyle,
    },
    user.isRole(INTERNAL_ROLES_VALUES)
      ? {
          id: "credits",
          text: "Credits",
          cellContentComponent: CreditCell,
          align: "right",
          style: { ...defaultHeaderStyle, width: "80px" },
        }
      : null,
    {
      id: "assignees",
      text: "Assignees",
      align: "left",
      restrictView: canUserViewAssigneesCell,
      cellContentComponent: Assignees,
      style: defaultHeaderStyle,
    },
    {
      id: "updated_at",
      text: "Last Modified",
      align: "left",
      sortable: true,
      cellContentComponent: LastModifiedCell,
      style: { ...defaultHeaderStyle, width: "152px" },
    },
    {
      id: "download-demand-package",
      text: "",
      align: "center",
      sortable: false,
      tableCellComponent: TableCell, // use default TableCell since there are links in the content of the cell
      cellContentComponent: DownloadDemandPackageCell,
      restrictView: canUserSeeDemandPackageDownload,
      style: { ...defaultHeaderStyle, width: "72px" },
    },
  ]
  return compact(
    items.map(col => {
      if (col && !col.tableCellComponent) {
        col.tableCellComponent = CustomTableCell
      }
      return col
    })
  )
})
