import { AnnotationStatus } from "demand/Files/types"
import { TAG_LABEL_TYPE, TagLabelData } from "common/TagLabel"
import { User } from "common/models/user"
import { ALL, INTAKE_STATUSES } from "./constants"
import { RequestRevampProps } from "./ViewRequest/ViewRequestRevamp/types"

export const isDemandAvailable = (intakeData?: RequestRevampProps): boolean => {
  if (!intakeData) return false
  const isCompleted = intakeData.intake_status === INTAKE_STATUSES.completed
  const isRevised = intakeData.revised
  return isCompleted || isRevised
}

export const getEditDisabledMessageByStatus = (statusId: INTAKE_STATUSES): string => {
  switch (statusId) {
    case INTAKE_STATUSES.inProgress:
      return `Cannot edit a demand that is In Progress. Please email legalops@evenuplaw.com for edit requests and include the name of the claimant in the email title.`
    case INTAKE_STATUSES.cancelled:
      return `Cannot edit a demand that is Cancelled. Please start a new request.`
    case INTAKE_STATUSES.completed:
      return `Cannot edit a demand that is Completed. You may download the demand package at anytime. If a rework is necessary, please request rework.`
    case INTAKE_STATUSES.rework:
      return `Cannot edit a demand that is in Rework. Please email legalops@evenuplaw.com for edit requests and include the name of the claimant in the email title.`
    case INTAKE_STATUSES.redelivered:
      return `Cannot edit a demand that is Redelivered. You may download the demand package at anytime. If a rework is necessary, please request rework.`
    default:
      return ``
  }
}

export const getAnnotationTag = (status: AnnotationStatus): TagLabelData => {
  if (status == AnnotationStatus.PRE_ANNOTATING || status == AnnotationStatus.ANNOTATING) {
    return { type: TAG_LABEL_TYPE.ANNOTATION_IN_PROGRESS, label: "Annotation in Progress" }
  } else if (status == AnnotationStatus.COMPLETE) {
    return { type: TAG_LABEL_TYPE.ANNOTATION_COMPLETE, label: "Annotation Completed" }
  } else if (status == AnnotationStatus.AUTO_COMPLETE) {
    return { type: TAG_LABEL_TYPE.ANNOTATION_COMPLETE, label: "Automatic Annotations Completed" }
  } else if (status == AnnotationStatus.ERROR) {
    return { type: TAG_LABEL_TYPE.ANNOTATION_COMPLETE_WITH_ERRORS, label: "Annotation Complete with Errors" }
  } else {
    throw new Error("Got unexpected annotation status")
  }
}

interface DownloadFileBlobOptions {
  base64?: boolean
  extension?: string
}

export const downloadFileBlob = (
  fileName: string,
  fileBlob: Blob,
  options: DownloadFileBlobOptions = {}
): void => {
  const { base64: base64 = false, extension: extension = "zip" } = options
  const url = base64
    ? `data:application/pdf;base64,${fileBlob}`
    : window.URL.createObjectURL(new Blob([fileBlob]))
  // create a link element
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${fileName}.${extension}`)

  // add link to body and click on it
  document.body.appendChild(link)
  link.click()
  // clean up the link
  document.body.removeChild(link)
}

type DownloadEndpoint = (requestId: number, type?: string) => Promise<Response>

export const downloadAllFiles = async (
  endpoint: DownloadEndpoint,
  fileName: string,
  requestId: number,
  type = ALL
): Promise<void> => {
  const response = await endpoint(requestId, type)
  // create file url based on blob
  const fileBlob = await response.blob()
  downloadFileBlob(`${fileName}-${requestId}`, fileBlob)
}

export const assignedToUser = ({ user, request }: { user: User; request: RequestRevampProps }) =>
  !!request.internal_assignees?.find(assignee => assignee.pk === user.id)
