import React, { useState, useCallback, useEffect } from "react"
import Box from "@mui/material/Box"
import ErrorState from "../ErrorState"

import { useMutation, useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { getAnnotatedProviderBills, syncProviderBills } from "api"

import BillTable from "./BillTable"
import LoadingState from "./LoadingState"

import { amountInDollars } from "utils"
import { Bill, BillsResponse } from "./types"
import { Provider, Bill as ProviderBill } from "demand/Providers/types"
import { Container } from "../Container"

import { Entry, PartitionEntry } from "../types"
import { BillsHeader, Content, SubHeader } from "../styled"
import { Action, UPDATE_PROVIDER_FIELD } from "demand/Providers/store/reducer"
import { useDialog } from "hooks/useDialog"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { Close } from "@mui/icons-material"

interface BillsProps {
  providerId: number
  caseId: number
  currentBills: ProviderBill[]
  onEntryClick: (entry: PartitionEntry) => void
  dateUpdated?: string
  allowImport?: boolean
  dispatch?: React.Dispatch<Action>
}

const Bills = ({
  providerId,
  caseId,
  currentBills,
  onEntryClick,
  dateUpdated,
  allowImport = false,
  dispatch,
}: BillsProps): JSX.Element => {
  const [billResponse, setBillResponse] = useState<Nullable<BillsResponse>>(null)
  const [isDeviated, setIsDeviated] = useState<boolean>(false)
  const { isOpen: isConfirmOpen, closeDialog: closeConfirm, openDialog: openConfirm } = useDialog()

  const handleEntryClick = useCallback(
    (entry: Entry) => {
      onEntryClick({
        exhibitId: entry.exhibit_id,
        partitionId: entry.partition_id,
        page: entry.page,
      })
    },
    [onEntryClick]
  )

  const isSameBill = useCallback((annotatedBill: Bill, providerBill: ProviderBill) => {
    return annotatedBill.charges.find(billCharge => {
      const chargeWriteOffTotal = billCharge.write_offs.reduce((sum, writeOff) => sum + writeOff.amount, 0)
      return (
        billCharge.charge.amount === (providerBill.billed_amount ?? 0) &&
        chargeWriteOffTotal === (providerBill.adjusted_amount ?? 0)
      )
    })
  }, [])

  const checkForDeviation = useCallback(
    (annotatedBills: Bill[]) => {
      const annotatedCharges =
        annotatedBills
          .map(bill => {
            return bill.charges
          })
          .flat() ?? []

      if (annotatedCharges.length !== currentBills.length) {
        return setIsDeviated(true)
      }

      const matchesAll = currentBills.every(currentBill => {
        return annotatedBills.find(annotatedBill => {
          return isSameBill(annotatedBill, currentBill)
        })
      })

      if (!matchesAll) {
        return setIsDeviated(true)
      }

      const matchesAllReverse = annotatedBills.every(annotatedBill => {
        return currentBills.find(currentBill => {
          return isSameBill(annotatedBill, currentBill)
        })
      })

      if (!matchesAllReverse) {
        return setIsDeviated(true)
      }

      setIsDeviated(false)
    },
    [currentBills, isSameBill]
  )

  const { isFetching, isError } = useQuery(
    [queryKeys.annotatedBills, caseId, providerId],
    async () => {
      return await getAnnotatedProviderBills(caseId, providerId)
    },
    {
      meta: {
        disableLoader: true,
      },
      onSuccess: response => {
        setBillResponse(response)
      },
    }
  )

  const { isLoading: isImporting, mutate: importBills } = useMutation<Provider>({
    mutationFn: () => syncProviderBills(caseId, providerId),
    onSuccess: provider => {
      if (dispatch) {
        dispatch({
          type: UPDATE_PROVIDER_FIELD,
          payload: { providerId, field: "bills", value: provider.bills ?? [] },
        })
      }
    },
  })

  const handleImportAll = useCallback(() => {
    if (currentBills.length > 0) {
      openConfirm()
      return
    }
    importBills()
  }, [currentBills, openConfirm, importBills])

  const handleConfirm = useCallback(() => {
    importBills()
    closeConfirm()
  }, [closeConfirm, importBills])

  useEffect(() => {
    checkForDeviation(billResponse?.bills || [])
  }, [billResponse, checkForDeviation, currentBills])

  if (isFetching) {
    return <LoadingState />
  }

  if (!isError && !isFetching && !billResponse?.bills?.length) {
    return (
      <Container>
        <Box display="flex" m={"auto"}>
          <Box>No Annotated Bills</Box>
        </Box>
      </Container>
    )
  }

  if (isError || !billResponse) {
    return (
      <Container>
        <ErrorState message="There was an error retrieving annotated bills" />
      </Container>
    )
  }

  const { billed_total, write_off_total, bills } = billResponse

  return (
    <Container
      includeDeviation={isDeviated}
      includeAnnotationUpdated={!!dateUpdated}
      dateUpdated={dateUpdated}
    >
      <BillsHeader>
        <Box>Bills</Box>
        <Box>Total&#x3a;&nbsp;{amountInDollars(billed_total)}</Box>
        <Box>Adjustments&#x3a;&nbsp;{amountInDollars(write_off_total)}</Box>
      </BillsHeader>
      <SubHeader>
        <Box>Date of Service</Box>
        <Box>Charges</Box>
        <Box>Adjustments</Box>
      </SubHeader>
      <Content>
        {!!bills.length &&
          bills.map((bill: Bill, index) => {
            return <BillTable bill={bill} key={index} onEntryClick={handleEntryClick} />
          })}
      </Content>
      {allowImport && (
        <Box sx={{ textAlign: "right", pt: 1 }}>
          <Button onClick={handleImportAll} disabled={isImporting || !isDeviated}>
            {isImporting ? "IMPORTING..." : "IMPORT BILLS"}
          </Button>
          <BillsConfirmDialog
            isOpen={isConfirmOpen}
            onClose={closeConfirm}
            onConfirm={handleConfirm}
            disabled={isImporting}
          />
        </Box>
      )}
    </Container>
  )
}

const BillsConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  disabled,
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  disabled: boolean
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          <Box>Overwrite Bills Data?</Box>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        This will overwrite your current bills with the bills found from annotation.
      </DialogContent>
      <DialogActions>
        <Button disabled={disabled} onClick={onClose} variant="contained" color="inherit">
          Cancel
        </Button>
        <Button disabled={disabled} onClick={onConfirm} variant="contained" color="secondary">
          {disabled ? "Overwriting..." : "Overwrite"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export { Bills as default }
