import { EXHIBIT, PARTITIONED_EXHIBIT } from "demand/Providers/constants"
import { Exhibit, PartitionedExhibit, Provider } from "demand/Providers/types"

export const normalizeProviderExhibits = <T extends Pick<Provider, "exhibits" | "exhibit_partitions">>(
  provider: T
): (Exhibit | PartitionedExhibit)[] => {
  // Exhibits on the provider object can include both partitions and exhibits
  // To work around this, we filter out the partitions and re-add them below
  const exhibits: Exhibit[] = []
  const exhibitPartitions: Record<PartitionedExhibit["pk"], PartitionedExhibit> = {}

  provider.exhibits?.forEach((exhibit: Exhibit | PartitionedExhibit) => {
    if (exhibit.exhibitType === PARTITIONED_EXHIBIT) {
      exhibitPartitions[exhibit.pk] = exhibit as PartitionedExhibit
    } else {
      exhibits.push({ ...exhibit, exhibitType: EXHIBIT })
    }
  })

  // add partitions to the exhibits list if they don't already exist
  provider.exhibit_partitions?.forEach((partitionExhibit: PartitionedExhibit) => {
    if (!exhibitPartitions[partitionExhibit.pk]) {
      exhibitPartitions[partitionExhibit.pk] = { ...partitionExhibit, exhibitType: PARTITIONED_EXHIBIT }
    }
  })

  return [...exhibits, ...Object.values(exhibitPartitions)].sort((a, b) => {
    if (a.section_index === null && b.section_index === null) return 0
    if (a.section_index !== null && b.section_index === null) return 1
    if (a.section_index === null && b.section_index !== null) return -1

    return (a.section_index ?? -1) - (b.section_index ?? -1)
  })
}
