import { Box } from "@mui/material"
import { ObjectiveTest } from "api/services/provider-objective-test/types"
import { NoItems } from "../../styled"
import {
  DescriptionCell,
  ObjectiveTestContainer,
  ObjectiveTestReadonlyRow,
  ObjectiveTestFindingsReadonlyList,
  ObjectiveTestFindingsReadonlyRow,
} from "./styled"
import { formatDate } from "utils"
import { Provider } from "demand/Providers/types"
import { getExhibitReferencesSummary } from "demand/Providers/Provider/ProviderForm/ObjectiveTests/utils"
import { useExhibitBuilderStore } from "exhibit-builder/store"

interface ObjectiveTestsProps {
  data: ObjectiveTest[]
  provider: Provider
}

export const ObjectiveTests = ({ data, provider }: ObjectiveTestsProps) => {
  const userExhibits = useExhibitBuilderStore(state => state.userExhibitMap)

  if (!data.length)
    return <NoItems variant="outlined">No Objective Tests added. Edit provider to add some.</NoItems>

  return (
    <ObjectiveTestContainer>
      <ObjectiveTestReadonlyRow fontWeight={700}>
        <Box>Date</Box>
        <Box>Description</Box>
        <ObjectiveTestFindingsReadonlyRow>
          <Box>Findings</Box>
          <Box>References</Box>
        </ObjectiveTestFindingsReadonlyRow>
      </ObjectiveTestReadonlyRow>

      {data.map((objectiveTest: ObjectiveTest) => {
        return (
          <ObjectiveTestReadonlyRow key={objectiveTest.id}>
            <Box>
              {objectiveTest.date ? formatDate(new Date(objectiveTest.date), "MM/dd/yyyy", true) : ""}
            </Box>

            <DescriptionCell>{objectiveTest.description}</DescriptionCell>
            <ObjectiveTestFindingsReadonlyList>
              {objectiveTest.findings.map(({ id, finding, referencePages }) => {
                const exhibitReference = getExhibitReferencesSummary({
                  referencePages,
                  providerExhibits: provider.exhibits,
                  providerExhibitPartitions: provider.exhibit_partitions,
                  userExhibits,
                })

                return (
                  <ObjectiveTestFindingsReadonlyRow key={id}>
                    <Box>{finding}</Box>
                    <Box>{exhibitReference}</Box>
                  </ObjectiveTestFindingsReadonlyRow>
                )
              })}
            </ObjectiveTestFindingsReadonlyList>
          </ObjectiveTestReadonlyRow>
        )
      })}
    </ObjectiveTestContainer>
  )
}
