import { useEffect, useState, useCallback } from "react"
import { Box, Skeleton } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"
import { size } from "lodash"
import { deleteExhibit, fetchLossOfIncomeForCase, getCaseFacts, saveLossOfIncome, uploadExhibit } from "api"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import { getChangedFields, getCommonMutateOptions } from "utils"
import useAutosave from "hooks/useAutosave"
import { useDebouncedCallback } from "hooks/useDebouncedCallback"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { formSectionsToRoutes, STEP_STATUSES } from "../constants"
import { useFormContext } from "../context"
import { AdditionalInfoSection } from "./AdditionalInfoSection/AdditionalInfoSection"
import { SalarySection } from "./SalarySection/SalarySection"
import { ImpairmentSection } from "./ImpairmentSection"
import { CommentSection } from "./CommentSection"
import {
  INITIAL_FORM_STATE,
  SALARY_FORMS,
  SALARY_INFO_ALL_SECTIONS_FIELDS,
  SKIP_CLEAN,
  NON_NULL_FIELDS,
  CALCULATION_FIELDS,
  INVALID_DATE_ERROR_MESSAGE,
  DEBOUNCE_DELAY,
} from "./constants"
import MissingLossOfIncomeAlert from "demand/Alerts/MissingLossOfIncomeAlert"
import { useFileUploader, withFileUploadProvider } from "common/file-uploader"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"
import SimpleDemandSkipSectionBanner from "demand/SimpleDemandBanner"

export const IncomeLoss = withFileUploadProvider(({ lastVisited }) => {
  const { saveRef, currentPlaintiff } = useOutletContext()
  const [errors, setErrors] = useState(null)
  const { caseId, queryClient, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus, request } =
    useFormContext()
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)
  const { control, handleSubmit, reset, resetField, watch, formState, getValues, setValue } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  })
  const [files, setFiles] = useState(null)
  const [fileToUpload, setFileToUpload] = useState(null)

  const salaryInformationType = watch("salary_information_type")
  const startDate = watch("start_of_impairment_date")
  const endDate = watch("end_of_impairment_date")

  const { uploadFiles } = useFileUploader()
  const { showMessage } = useHandleMessages()

  const { data, isLoading } = useQuery(
    [queryKeys.incomeLoss, caseId, currentPlaintiff?.id],
    async () => await fetchLossOfIncomeForCase(caseId),
    SILENT_QUERY_PARAMS
  )

  const setDefaultStartOfLoss = useCallback(async () => {
    const caseFactsData = await getCaseFacts(caseId)
    const incidentDate = caseFactsData?.date_of_incident

    resetField("start_of_impairment_date", {
      defaultValue: incidentDate,
    })
  }, [caseId, resetField])

  useEffect(() => {
    if (!data || (!currentPlaintiff && multiPlaintiffEnabled)) return
    let plaintiffData = data
    if (multiPlaintiffEnabled) {
      plaintiffData = data.find(plaintiff => plaintiff.pk === currentPlaintiff.id)
    }

    let salary_information_type = SALARY_FORMS.annual.key
    for (const [key, value] of Object.entries(plaintiffData)) {
      if (SALARY_FORMS.annual.fields.includes(key) && value) {
        salary_information_type = SALARY_FORMS.annual.key
        break
      }
      if (SALARY_FORMS.hourly.fields.includes(key) && value) {
        salary_information_type = SALARY_FORMS.hourly.key
        break
      }
      if (SALARY_FORMS.noSalary.fields.includes(key) && value) {
        salary_information_type = SALARY_FORMS.noSalary.key
        break
      }
    }

    // TODO: Changing plaintiff before submit is finished cases the call to not go to the backend
    reset({
      ...(plaintiffData ?? {}),
      hourly_salary: plaintiffData?.hourly_salary ?? 0,
      salary_information_type: salary_information_type,
      plaintiff_id: plaintiffData.pk,
    })

    // TODO: Fix this - response recieves id instead of PK
    setFiles(
      plaintiffData?.exhibits?.map(exhibit => {
        return { ...exhibit, pk: exhibit.id }
      })
    )
  }, [data, currentPlaintiff, reset, multiPlaintiffEnabled])

  useEffect(() => {
    if (data?.start_of_impairment_date == null) {
      setDefaultStartOfLoss()
    }
  }, [data?.start_of_impairment_date, setDefaultStartOfLoss])

  const validateFormData = formData => {
    let errors = {}

    if (formData.start_of_impairment_date >= formData.end_of_impairment_date) {
      errors.start_of_impairment_date = INVALID_DATE_ERROR_MESSAGE
      errors.end_of_impairment_date = INVALID_DATE_ERROR_MESSAGE
    }
    // all future custom validation goes here

    return Object.keys(errors).length > 0 ? errors : undefined
  }

  const mutation = useMutation(
    saveLossOfIncome,
    getCommonMutateOptions({
      setErrors,
      reset,
      setSavedSuccessfully,
      showErrorMessage,
      onSuccessExtra: () => {
        handleUpdateStepStatus({ status: STEP_STATUSES.completed })
      },
    })
  )
  // succeed/fail silently
  const autosaveMutation = useMutation(saveLossOfIncome)

  const _cleanData = () => {
    // we need to null out the other sections
    // the calculator uses a fallback strategy
    // based on the information present
    const salary_information_type = getValues("salary_information_type")
    const currentTypeFields = SALARY_FORMS[salary_information_type].fields

    for (const field of SALARY_INFO_ALL_SECTIONS_FIELDS) {
      if (!currentTypeFields.includes(field) && !SKIP_CLEAN.includes(field)) {
        // TODO: Fix this on the backend
        let nullValue = NON_NULL_FIELDS.includes(field) ? 0 : null
        setValue(field, nullValue)
      } else {
        if (!getValues(field) && NON_NULL_FIELDS.includes(field)) {
          setValue(field, 0)
        }
      }
    }

    // Main form fields should just check if null and set to 0
    for (const field of SALARY_FORMS.main.fields) {
      if (!getValues(field)) {
        let nullValue = NON_NULL_FIELDS.includes(field) ? 0 : null
        setValue(field, nullValue)
      }
    }

    return getValues()
  }

  const deleteFileMutation = useMutation(deleteExhibit, {
    onSuccess: () => {
      showMessage({ type: "success", message: "Deleted file" })
      queryClient.invalidateQueries([queryKeys.incomeLoss])
    },
    onError: error => {
      showMessage({
        type: "error",
        message:
          "Error occurred deleting file. Please try again shortly or contact a dev if your problem persists.",
        error,
      })
    },
  })
  const uploadFileMutation = useMutation(uploadExhibit, {
    onSuccess: () => {
      setFileToUpload(null)
      queryClient.invalidateQueries([queryKeys.incomeLoss])
    },
    onError: error => {
      showMessage({
        type: "error",
        message:
          "Error occurred uploading file. Please try again shortly or contact a dev if your problem persists.",
        error,
      })
    },
  })
  const handleFileChange = async (file, remove = false) => {
    if (remove) {
      deleteFileMutation.mutate({ exhibitId: file.id, caseId })
    } else {
      const {
        items: [upload],
      } = await uploadFiles([file.file])

      if (upload.success) {
        const fileData = new FormData()
        const { documentType, name } = file

        fileData.append("upload_id", upload.uploadId)
        fileData.append("name", name)
        fileData.append("type", documentType)
        fileData.append("section", "income_loss")
        if (multiPlaintiffEnabled) {
          fileData.append("plaintiff_id", currentPlaintiff.id)
        }

        uploadFileMutation.mutate({ data: fileData, caseId })
      }
    }
  }

  const handleOnChange = handleSubmit(async data => {
    if (fileToUpload) {
      await handleFileChange(fileToUpload)
    }

    const cleanedData = _cleanData()

    // Optimistically populate existing Income Loss data with new form values
    queryClient.setQueryData([queryKeys.incomeLoss, caseId, currentPlaintiff?.id], oldData => {
      if (!multiPlaintiffEnabled || !currentPlaintiff) return { oldData, ...cleanedData }

      return oldData.map(plaintiff => {
        if (plaintiff.pk === currentPlaintiff.id) {
          return { ...plaintiff, ...cleanedData }
        }
        return plaintiff
      })
    })

    const changedFields = getChangedFields(data, formState)

    if (size(changedFields) > 0) {
      const formErrors = validateFormData(cleanedData)
      if (formErrors !== undefined) {
        setErrors(formErrors)
        return
      }

      return mutation.mutateAsync({ caseId, data: cleanedData }).then(() => {
        if (
          CALCULATION_FIELDS.find(key => {
            return Object.prototype.hasOwnProperty.call(changedFields, key)
          })
        ) {
          const key = multiPlaintiffEnabled
            ? [queryKeys.incomeLossCalculation, caseId, currentPlaintiff.id, "results"]
            : [queryKeys.incomeLossCalculation, caseId, "results"]
          queryClient.invalidateQueries(key)
        }
      })
    }
  })

  const autoSave = useCallback(() => {
    const data = { ...getValues(), case_id: caseId }
    const formErrors = validateFormData(data)
    if (formErrors === undefined) {
      autosaveMutation.mutate({ caseId, data })
    }
  }, [caseId, getValues, autosaveMutation])

  useAutosave({
    shouldAutosave: formState.isDirty,
    save: autoSave,
  })

  useEffect(() => {
    saveRef.current = handleOnChange
  }, [handleOnChange, saveRef])

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.income_loss
  })

  const handleChangeDebounced = useDebouncedCallback(handleOnChange, DEBOUNCE_DELAY)

  if (isLoading)
    return <Skeleton height={70} animation="wave" variant="rounded" data-test="income-loss-skeleton-loader" />

  return (
    <Box>
      <SimpleDemandSkipSectionBanner caseId={caseId} />
      <Box mb={3}>
        <MissingLossOfIncomeAlert caseId={caseId} currentPlaintiff={currentPlaintiff} />
      </Box>
      <form noValidate onChange={handleChangeDebounced}>
        <ImpairmentSection
          onClose={handleOnChange}
          errors={errors}
          setValue={setValue}
          startDate={startDate}
          endDate={endDate}
        />
        <SalarySection control={control} watch={watch} salaryInformationType={salaryInformationType} />
        <CommentSection control={control} />
      </form>
      <AdditionalInfoSection
        endDate={endDate}
        startDate={startDate}
        files={files}
        caseId={caseId}
        fileToUpload={fileToUpload}
        queryClient={queryClient}
        setFileToUpload={setFileToUpload}
        handleFileChange={handleFileChange}
        request={request}
        salaryInformationType={salaryInformationType}
        plaintiffId={currentPlaintiff?.id}
      />
    </Box>
  )
})
