import * as Sentry from "@sentry/browser"
import { EmptyResponse, makeApiCall, REQUEST_METHODS } from "./apiHelper"
import { isEmpty, pickBy, snakeCase, transform } from "lodash"
import { INTAKE_STATUSES } from "./requests/constants"
import { EXTERNAL_ROLES_VALUES } from "./common/models/roles"
import { statusInternalOSFOnly } from "./requests/RequestStatus/requestStatusProperties"
import { format, isEqual } from "date-fns"
import { handleEmptyResponse } from "api/utils"
import { getAPIServerURL } from "utils"
import { formatPlaintiffsProperties } from "requests/RequestForm/utils"

async function download(options, filename = null) {
  const blobResponse = await handleEmptyResponse(makeApiCall(options))
  const blob = await blobResponse.blob()
  const url = URL.createObjectURL(blob)

  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", filename ? filename : blobResponse.filename)
  link.setAttribute("style", "display: none")
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}

export const loginUser = async ({ ...data }) => {
  const options = {
    path: "/users/login",
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const logoutUser = async () => {
  const options = {
    path: "/users/logout",
    method: REQUEST_METHODS.POST,
  }

  return makeApiCall(options)
}

export const loginStytchUser = async ({
  token,
  oauth = false,
  stytchTokenType = false,
  redirectPath = "",
}) => {
  const options = {
    // TODO: don't add trailing / here until backend issue is resolved with this endpoint
    path: "/users/stytch",
    method: REQUEST_METHODS.POST,
    data: {
      token,
      oauth: !!oauth,
      stytch_token_type: stytchTokenType,
      redirect_path: redirectPath,
    },
  }

  return makeApiCall(options)
}

export const getCases = async ({ queryKey }) => {
  const [, { page, pageSize, sortBy, sortDirection, filterFirmId, filterCreatedBy, searchQuery }] = queryKey
  const orderingField = `${sortDirection === "asc" ? `-` : ``}${sortBy}`

  const options = {
    path: `/case/`,
    method: REQUEST_METHODS.GET,
    query: {
      page: page,
      page_size: pageSize,
      ordering: orderingField != false ? orderingField : null,
      firm_id: filterFirmId != false ? filterFirmId : null,
      creator_id: filterCreatedBy != false ? filterCreatedBy : null,
      search: searchQuery != false ? searchQuery : null,
    },
  }

  return makeApiCall(options)
}

export const getCase = async ({ queryKey }) => {
  const [, caseId] = queryKey
  const options = {
    path: `/case/${caseId}/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getCaseWithAnnotation = async ({ caseId }) => {
  const options = {
    path: `/case/${caseId}/?annotation=true`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const deleteCase = async caseId => {
  const options = {
    path: `/case/${caseId}`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const saveFavoriteCase = async ({ user, id, nickname }) => {
  const options = {
    path: `/users/${user.id}/saveFavorite/`,
    method: REQUEST_METHODS.PUT,
    data: {
      id,
      nickname,
    },
  }

  return makeApiCall(options)
}

export const unsaveFavoriteCase = async ({ user, id }) => {
  const options = {
    path: `/users/${user.id}/unsaveFavorite/`,
    method: REQUEST_METHODS.DELETE,
    data: { id },
  }

  return makeApiCall(options)
}

export const getFavoriteCase = async ({ queryKey }) => {
  let [_, data] = queryKey
  if (data?.state === "") {
    data.state = null
  }
  const options = {
    path: `/users/get_favorite/`,
    method: REQUEST_METHODS.GET,
    query: data,
  }

  return makeApiCall(options)
}

export const updateCollateralSourceRule = async ({ firmId, enable }) => {
  const options = {
    path: `/firm/${firmId}/collateral-source/`,
    method: REQUEST_METHODS.PUT,
    data: {
      has_collateral_source_rule: enable,
    },
  }

  return makeApiCall(options)
}

export const updateShouldCombineExhibits = async ({ caseId, ce }) => {
  const options = {
    path: `/case/${caseId}/combine-exhibits/`,
    method: REQUEST_METHODS.PUT,
    data: {
      combine_exhibits: ce,
    },
  }

  return makeApiCall(options)
}

export const getUserSession = async () => {
  const options = {
    path: "/users/me/",
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getInternalUsers = async ({ minimal } = {}) => {
  const options = {
    path: "/users/internalUsers/",
    method: REQUEST_METHODS.GET,
    query: {
      minimal: minimal,
    },
  }

  return makeApiCall(options)
}

export const updatePageSize = async data => {
  const options = {
    path: `/users/pagesize/`,
    method: REQUEST_METHODS.PUT,
    data,
  }

  return makeApiCall(options)
}

export const search = async ({ queryKey }) => {
  const [, { query, page, caseId }] = queryKey

  const options = {
    path: `/data/search/?page=${page}` + (caseId ? `&caseId=${caseId}` : ""),
    method: REQUEST_METHODS.POST,
    data: query,
  }

  return makeApiCall(options)
}

export const markCaseRelevant = async ({ caseId, id }) => {
  const options = {
    path: `/case/${caseId}/relevant/`,
    method: REQUEST_METHODS.PUT,
    data: { id },
  }

  return makeApiCall(options)
}

export const markCaseNotRelevant = async ({ caseId, id }) => {
  const options = {
    path: `/case/${caseId}/irrelevant/`,
    method: REQUEST_METHODS.DELETE,
    data: { id },
  }

  return makeApiCall(options)
}

export const getRelevantDocuments = async ({ queryKey }) => {
  const [, documentIds] = queryKey
  const options = {
    path: `/data/search/?ids=${documentIds.join()}`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const createCase = async caseData => {
  const options = {
    path: `/case/`,
    method: REQUEST_METHODS.POST,
    data: caseData,
  }

  return makeApiCall(options)
}

export const updateCase = async ({ id, updates }) => {
  const options = {
    path: `/case/${id}/`,
    method: REQUEST_METHODS.PATCH,
    data: updates,
  }

  return makeApiCall(options)
}

export const fetchFacetsForText = async facetData => {
  const options = {
    path: `/data/facets/`,
    method: REQUEST_METHODS.POST,
    data: facetData,
  }

  return makeApiCall(options)
}

export const previewDemand = async (caseId, extendedExhibits) => {
  const options = {
    path: `/case/${caseId}/preview-demand/?extendedExhibits=${extendedExhibits}`,
    method: REQUEST_METHODS.GET,
    json: false,
  }

  return makeApiCall(options)
}

export const generateDemand = (caseId, extendedExhibits) => {
  const options = {
    path: `/case/${caseId}/v2/demand/?extendedExhibits=${extendedExhibits}`,
    method: REQUEST_METHODS.POST,
  }

  return makeApiCall(options)
}

export const resetDemand = caseId => {
  const options = {
    path: `/case/${caseId}/reset/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const statusDemandV2 = (caseId, requestId) => {
  const options = {
    path: `/case/${caseId}/v2/demand/${requestId}/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const listGeneratedDemandsV2 = caseId => {
  const options = {
    path: `/case/${caseId}/v2/demand/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const downloadDemandV2 = (caseId, requestId) => {
  const options = {
    path: `/case/${caseId}/v2/demand/${requestId}/download/`,
    method: REQUEST_METHODS.POST,
    query: { filename: `${caseId}.zip` },
    json: false,
  }

  return makeApiCall(options)
}

export const validateDemand = async ({ queryKey }) => {
  const [, caseId] = queryKey
  const options = {
    path: `/case/${caseId}/is-valid/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

/** @deprecated use src/api/services/firms/getFirms.ts instead */
export const getFirms = async ({ page, pageSize, searchQuery } = {}) => {
  const options = {
    path: `/firm/`,
    method: REQUEST_METHODS.GET,
    query: {
      page: page,
      page_size: pageSize,
      search: searchQuery,
    },
  }

  return makeApiCall(options)
}

export const getFirmDamageTemplateSections = async ({ queryKey }) => {
  const [, firmId] = queryKey
  const options = {
    path: `/firm/${firmId}/damages-sections`,
    method: REQUEST_METHODS.GET,
  }
  return makeApiCall(options)
}

/** @deprecated use src/api/services/firm/getFirm.ts with extended option instead */
export const getFirmExtended = async ({ queryKey }) => {
  const [, firmId] = queryKey
  const options = {
    path: `/firm/${firmId}/?extended=true`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const deleteFirm = async firmId => {
  const options = {
    path: `/firm/${firmId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const saveAttorney = async ({ data }) => {
  const options = {
    path: `/attorney/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const resetPartitionsAndActions = async ({ caseId, exhibitId }) => {
  const options = {
    path: `/case/${caseId}/exhibit/${exhibitId}/reset-partitions/`,
    method: REQUEST_METHODS.POST,
  }

  return makeApiCall(options)
}

export const setPartitions = async ({ caseId, exhibitId, data }) => {
  const options = {
    path: `/case/${caseId}/exhibit/${exhibitId}/partition/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updatePartition = async ({ exhibitId, partitionId, data }) => {
  const options = {
    path: `/exhibits/${exhibitId}/partition/${partitionId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const createDuplicate = async ({ exhibitId, data }) => {
  const options = {
    path: `/exhibits/${exhibitId}/duplicates/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const deletePartition = async ({ exhibitId, partitionId }) => {
  const options = {
    path: `/exhibits/${exhibitId}/partition/${partitionId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const setDeletions = async ({ exhibitId, data }) => {
  const options = {
    path: `/exhibits/${exhibitId}/actions/set-deletions/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const removeAction = async ({ exhibitId, actionId }) => {
  const options = {
    path: `/exhibits/${exhibitId}/actions/${actionId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const setAttorney = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/firm/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const fetchLawFirmConfig = async caseId => {
  const options = {
    path: `/case/${caseId}/firm/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getAvailableSteps = async () => {
  const options = {
    path: `/case/asset/steps/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const savePlaintiffInfo = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/plaintiff/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const fetchPlaintiffInfoForCase = async caseId => {
  const options = {
    path: `/case/${caseId}/plaintiff/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const fetchPlaintiffInfoForCaseAndPlaintiff = async (caseId, plaintiffId) => {
  const options = {
    path: `/case/${caseId}/plaintiff/${plaintiffId}/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const updatePlaintiffInfo = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/plaintiff/${data.pk}/`,
    method: REQUEST_METHODS.PUT,
    data,
  }

  return makeApiCall(options)
}

export const patchPlaintiffInfo = async ({ caseId, plaintiffId, data }) => {
  const options = {
    path: `/case/${caseId}/plaintiff/${plaintiffId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const saveLossOfIncome = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/loss/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const calculateLossOfIncome = async (caseId, plaintiffId) => {
  const options = {
    path: `/case/${caseId}/loss/calculate/`,
    method: REQUEST_METHODS.GET,
    query: {
      plaintiff_id: plaintiffId,
    },
  }

  return makeApiCall(options)
}

export const fetchLossOfIncomeForCase = async caseId => {
  const options = {
    path: `/case/${caseId}/loss/info/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const saveCarrierInfo = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/defendant/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateCarrierInfo = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/defendant/${data.pk}/`,
    method: REQUEST_METHODS.PUT,
    data,
  }

  return makeApiCall(options)
}

export const fetchCarrierInfoForCase = async caseId => {
  const options = {
    path: `/case/${caseId}/defendant/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const saveProvider = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/provider/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const getProvider = async ({ caseId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const updateProvider = async ({ caseId, data, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/`,
    method: REQUEST_METHODS.PUT,
    data: { ...data, case_id: caseId },
  }

  return makeApiCall(options)
}

export const updateProviderOrder = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/provider/order/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateProviderFileOrder = async ({ caseId, providerId, data }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/upload/order/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const fetchProvidersByCase = async ({ queryKey }) => {
  const [, caseId] = queryKey
  const options = {
    path: `/case/${caseId}/provider/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getFutureExpenses = async ({ queryKey }) => {
  const [, caseId] = queryKey
  const options = {
    path: `/case/${caseId}/future-expenses/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const deleteFutureExpense = async ({ caseId, pk }) => {
  const options = {
    path: `/case/${caseId}/future-expenses/${pk}`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const updateFutureExpense = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/future-expenses/${data.pk}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const createFutureExpense = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/future-expenses/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const reorderFutureExpenses = async ({ caseId, newIdsInOrder, plaintiffId }) => {
  const options = {
    path: `/case/${caseId}/future-expenses/reorder/`,
    method: REQUEST_METHODS.POST,
    data: {
      ordered_ids: newIdsInOrder,
      plaintiff_id: plaintiffId,
    },
  }

  return makeApiCall(options)
}

export const deleteProvider = async ({ caseId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const deleteProviderFile = async ({ caseId, providerId, fileId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/upload/${fileId}`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const getIcdCodes = async ({ search = "", page = 1, page_size = 100 }) => {
  const params = {
    search,
    page,
    page_size,
  }
  const qs = new URLSearchParams(params)
  const options = {
    path: "/settings/icd-code/?".concat(qs.toString()),
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const saveIcdCodeForProvider = async ({ data, caseId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/icd/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateIcdCodesForProvider = async ({ data, caseId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/icd/`,
    method: REQUEST_METHODS.PUT,
    data,
  }

  return makeApiCall(options)
}

export const deleteIcdCode = async ({ codeId, caseId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/icd/${codeId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const getCptCodes = async ({ prefix = "", query = "" }) => {
  const params = {
    prefix,
    query,
  }
  const qs = new URLSearchParams(params)
  const options = {
    path: "/case/asset/cpt/?".concat(qs.toString()),
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const saveCptCodeForProvider = async ({ data, caseId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/cpt/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateCptCodesForProvider = async ({ data, caseId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/cpt/`,
    method: REQUEST_METHODS.PUT,
    data,
  }

  return makeApiCall(options)
}

export const deleteCptCode = async ({ codeId, caseId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/cpt/${codeId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const getNonEconomic = async caseId => {
  // for getting form data
  const options = {
    path: `/case/${caseId}/noneconomic/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const calculateNonEconomic = async (caseId, plaintiffId) => {
  // for getting calculated data to be generated
  const options = {
    path: `/case/${caseId}/noneconomic/calculate/`,
    method: REQUEST_METHODS.GET,
    query: {
      plaintiff_id: plaintiffId,
    },
  }

  return makeApiCall(options)
}

export const updateNonEconomic = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/noneconomic/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const getHousehold = async caseId => {
  const options = {
    path: `/case/${caseId}/household/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getHouseholdRelevantDate = async ({ queryKey }) => {
  const [, caseId, plaintiffId] = queryKey
  const options = {
    path: `/case/${caseId}/household/relevant_dates`,
    method: REQUEST_METHODS.GET,
    query: {
      plaintiff_id: plaintiffId,
    },
  }

  return makeApiCall(options)
}

export const calculateHouseholdLoss = async (caseId, plaintiffId) => {
  const options = {
    path: `/case/${caseId}/household/calculate/`,
    method: REQUEST_METHODS.GET,
    query: {
      plaintiff_id: plaintiffId,
    },
  }
  return makeApiCall(options)
}

export const updateHousehold = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/household/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const uploadHouseholdServicesFile = async ({ data, caseId }) => {
  const options = {
    path: `/case/${caseId}/household/file/`,
    method: REQUEST_METHODS.POST,
    json: false,
    isFormData: true,
    data,
  }

  return makeApiCall(options)
}

export const deleteHouseholdServicesFile = async ({ caseId, fileId }) => {
  const options = {
    path: `/case/${caseId}/household/file/${fileId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const getExhibitsOrder = async ({ caseId }) => {
  const options = {
    path: `/case/${caseId}/exhibit-order/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const updateExhibitsOrder = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/exhibit-order/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const saveCaseFacts = async ({ data, caseId }) => {
  const options = {
    path: `/case/${caseId}/facts/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const getCaseFacts = async caseId => {
  const options = {
    path: `/case/${caseId}/facts/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const updateStepStatus = async ({ data, caseId }) => {
  const options = {
    path: `/case/${caseId}/step/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const getStepStatuses = async ({ queryKey }) => {
  const [, caseId] = queryKey
  const options = {
    path: `/case/${caseId}/step/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const createNewFirm = async ({ data }) => {
  const options = {
    path: `/firm/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateFirm = async ({ data, firmId }) => {
  const options = {
    path: `/firm/${firmId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const saveFirmLogo = async ({ data, firmId }) => {
  const options = {
    path: `/firm/${firmId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
    json: false,
    isFormData: true,
  }

  return makeApiCall(options)
}

export const updateFirmFile = async ({ data, firmId }) => {
  const options = {
    path: `/firm/${firmId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
    json: false,
    isFormData: true,
  }

  return makeApiCall(options)
}

export const deleteFirmTemplate = async ({ firmId }) => {
  const options = {
    path: `/firm/${firmId}/template/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const createAttorney = async ({ data, firmId }) => {
  const options = {
    path: `/firm/${firmId}/attorney/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateAttorney = async ({ data, attorneyId }) => {
  const options = {
    path: `/firm/${data.firm_id}/attorney/${attorneyId}/`,
    method: REQUEST_METHODS.PUT,
    data,
  }

  return makeApiCall(options)
}

export const deleteAttorney = async ({ attorneyId, firmId }) => {
  const options = {
    path: `/firm/${firmId}/attorney/${attorneyId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const getAttorney = async ({ queryKey }) => {
  const [, firmId, attorneyId, extended] = queryKey
  const options = {
    path: `/firm/${firmId}/attorney/${attorneyId}?extended=${extended}`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getFirmAttorneys = async ({ queryKey }) => {
  const [, firmId] = queryKey
  const options = {
    path: `/firm/${firmId}/attorney/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getUsStates = async () => {
  const options = {
    path: `/case/asset/states/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getEducationLevels = async () => {
  const options = {
    path: `/case/asset/education-levels/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getSectionTypes = async () => {
  const options = {
    path: `/firm/asset/section-types/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const fetchDamagesSectionsByCase = async ({ queryKey }) => {
  const [, caseId] = queryKey
  const options = {
    path: `/case/${caseId}/damages-section/all/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const addDamagesSection = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/damages-section/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateDamagesSection = async ({ caseId, sectionId, data }) => {
  const options = {
    path: `/case/${caseId}/damages-section/${sectionId}/`,
    method: REQUEST_METHODS.PUT,
    data: {
      ...data,
      damage_amount: !isNaN(parseFloat(data.damage_amount)) ? data.damage_amount : 0,
    },
  }

  return makeApiCall(options)
}

export const getRequestsForFirm = async ({ queryKey }) => {
  const [
    ,
    {
      page,
      pageSize,
      sortBy,
      sortDirection,
      firmIds,
      statusIds,
      assigneeId,
      submitterId,
      searchQuery,
      documentType,
      caseType,
    },
    role,
  ] = queryKey
  const orderingField = `${sortDirection === "asc" ? "" : "-"}${sortBy}`

  const options = {
    path: `/intake/`,
    method: REQUEST_METHODS.GET,
    query: {
      page: page,
      page_size: pageSize,
      ordering: orderingField,
      firm_id: isEmpty(firmIds) ? null : firmIds,
      internal_assignees: assigneeId != false ? assigneeId : null,
      search: searchQuery != false ? searchQuery : null,
      submitter: submitterId !== false ? submitterId : null,
      document_type: documentType !== false ? documentType : null,
      case_type: caseType !== false ? caseType : null,
    },
  }

  if (isEqual(statusIds, [INTAKE_STATUSES.inProgress]) && EXTERNAL_ROLES_VALUES.includes(role)) {
    options.query.intake_status__in = [statusIds, ...statusInternalOSFOnly]
  } else {
    options.query.intake_status = isEmpty(statusIds) ? null : statusIds
  }

  return makeApiCall(options)
}

/** @deprecated use src/api/services/request/api.ts instead */
export const fetchRequest_DEPRECATED = async ({ queryKey }) => {
  const [, requestId] = queryKey
  const options = {
    path: `/intake/${requestId}/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options).then(data => ({
    ...data,
    plaintiffs: formatPlaintiffsProperties(data.plaintiffs),
  }))
}

export const updateFiles = async ({ requestId }) => {
  const options = {
    path: `/intake/${requestId}/updateFiles/`,
    method: REQUEST_METHODS.POST,
  }

  return makeApiCall(options)
}

export const getIntakeAsset = async ({ queryKey }) => {
  const [, assetType] = queryKey
  const options = {
    path: `/intake/asset/${assetType}/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

/** @deprecated use src/api/services/request/api.ts instead */
export const updateRequest_DEPRECATED = async ({ requestId, data }) => {
  const options = {
    path: `/intake/${requestId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options).then(data => ({
    ...data,
    plaintiffs: formatPlaintiffsProperties(data.plaintiffs),
  }))
}

export const calculateCredits = async ({ requestId, data }) => {
  const options = {
    path: `/intake/${requestId}/calculate_credits/`,
    method: REQUEST_METHODS.GET,
    query: data,
  }

  return makeApiCall(options)
}

export const fetchRequestFiles = async ({ queryKey }) => {
  const [, requestId] = queryKey
  const options = {
    path: `/intake/${requestId}/file/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const uploadRequestFile = async ({ requestId, data }) => {
  const options = {
    path: `/intake/${requestId}/file/`,
    method: REQUEST_METHODS.POST,
    json: false,
    isFormData: true,
    data,
  }

  return makeApiCall(options)
}

export const updateRequestFile = async ({ requestId, fileId, data }) => {
  const options = {
    path: `/intake/${requestId}/file/${fileId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const deleteRequestFile = async ({ requestId, fileId }) => {
  const options = {
    path: `/intake/${requestId}/file/${fileId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const downloadRevisionFiles = async (requestId, revisionId) => {
  const options = {
    path: `/intake/${requestId}/revision_request/${revisionId}/download_files`,
    method: REQUEST_METHODS.GET,
    json: false,
  }

  return makeApiCall(options)
}

export const getUser = async ({ queryKey }) => {
  const [, userId] = queryKey
  const options = {
    path: `/users/${userId}/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getUsers = async ({ page, pageSize, searchQuery, minimal, firmId } = {}) => {
  const options = {
    path: `/users/`,
    method: REQUEST_METHODS.GET,
    query: {
      page: page,
      page_size: pageSize,
      search: searchQuery,
      minimal: minimal,
      firm_id: firmId,
    },
  }

  return makeApiCall(options)
}

export const getSubmitters = async () => {
  const options = {
    path: `/users/submittersSearch/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const updateUser = async ({ userId, data }) => {
  const options = {
    path: `/users/${userId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const createUser = async ({ data }) => {
  const options = {
    path: `/users/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const changePassword = async ({ userId, data }) => {
  const options = {
    path: `/users/${userId}/changePassword/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const updateFirmManagers = async ({ firmId, data }) => {
  const options = {
    path: `/firm/${firmId}/managers/`,
    method: REQUEST_METHODS.PUT,
    data,
  }

  return makeApiCall(options)
}

export const deleteUser = async ({ userId }) => {
  const options = {
    path: `/users/${userId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const getExternalUsers = async ({ minimal } = {}) => {
  const options = {
    path: `/users/externalUsers/`,
    method: REQUEST_METHODS.GET,
    query: {
      minimal: minimal,
    },
  }

  return makeApiCall(options)
}

export const downloadRequestFiles = async (requestId, type = "ALL") => {
  const options = {
    path: `/intake/${requestId}/download_files?type=${type}`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const bulkDownloadGetStatus = async ticketId => {
  const options = {
    path: `/downloadfiles/${ticketId}/status`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const bulkDownloadDownloadFile = async ticketId => {
  const options = {
    path: `/downloadfiles/${ticketId}/download`,
    method: REQUEST_METHODS.GET,
    json: false,
  }

  return makeApiCall(options)
}

export const deleteRequest = async ({ requestId }) => {
  const options = {
    path: `/intake/${requestId}`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const deleteRequestDemandPackageFile = async ({ requestId, fileId }) => {
  const options = {
    path: `/intake/${requestId}/demand_package_file/${fileId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const downloadRequestDemandPackageFiles = async requestId => {
  const options = {
    path: `/intake/${requestId}/download_demand_package_files/`,
    method: REQUEST_METHODS.GET,
    json: false,
  }

  // If the file we get is empty, alert sentry
  const apiCall = makeApiCall(options).then(response => {
    if (
      response?.headers?.get("content-type") === "application/zip" &&
      response?.headers?.get("content-length") === "0"
    ) {
      Sentry.captureMessage("Demand Package Error. Downloaded zip is empty", {
        level: "error",
        extra: {
          response: response,
        },
      })
    }
    return response
  })
  return apiCall
}

export const updateExhibit = async ({ caseId, exhibitId, data }) => {
  const options = {
    path: `/case/${caseId}/exhibit/${exhibitId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const deleteExhibit = async ({ caseId, exhibitId }) => {
  const options = {
    path: `/case/${caseId}/exhibit/${exhibitId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const bulkDeleteExhibits = async ({ caseId, ids }) => {
  const options = {
    path: `/case/${caseId}/exhibit/?ids=${ids.join(",")}`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const deleteExhibitGroupsBySection = async ({ caseId, section }) => {
  const options = {
    path: `/case/${caseId}/exhibit-group/?section=${section}`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const createExhibitGroup = async ({ caseId, groupId, data }) => {
  const options = {
    path: `/case/${caseId}/exhibit-group/${groupId}/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateExhibitGroup = async ({ caseId, groupId, data }) => {
  const options = {
    path: `/case/${caseId}/exhibit-group/${groupId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const deleteExhibitGroup = async ({ caseId, groupId }) => {
  const options = {
    path: `/case/${caseId}/exhibit-group/${groupId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const downloadExhibits = async ({ caseId }) => {
  const options = {
    path: `/case/${caseId}/exhibit/download_all`,
    method: REQUEST_METHODS.GET,
    blob: true,
    json: false,
  }

  return await download(options, `Intake Files - ${caseId}.zip`)
}

export const downloadMedchronExhibits = async ({ documentId }) => {
  const options = {
    path: `/documents/${documentId}/exhibit/download_all`,
    method: REQUEST_METHODS.GET,
    blob: true,
    json: false,
  }

  return await download(options, `Intake Files - ${documentId}.zip`)
}

export const getExhibits = async ({ caseId, query }) => {
  const options = {
    path: `/case/${caseId}/exhibit/`,
    method: REQUEST_METHODS.GET,
    query,
  }

  return makeApiCall(options)
}

export const getExhibit = async ({ caseId, exhibitId, query }) => {
  const options = {
    path: `/case/${caseId}/exhibit/${exhibitId}/`,
    method: REQUEST_METHODS.GET,
    query,
  }

  return makeApiCall(options)
}

export const getAnnotatedExhibits = async ({ caseId }) => {
  const options = {
    path: `/case/${caseId}/annotated-exhibit/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getPartitionProviders = async ({ caseId }) => {
  const options = {
    path: `/case/${caseId}/partition_providers/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const createPartitionProvider = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/partition_providers/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updatePartitionProvider = async ({ caseId, partitionProviderId, data }) => {
  const options = {
    path: `/case/${caseId}/partition_providers/${partitionProviderId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const startDemand = async ({ requestId }) => {
  const options = {
    path: `/intake/${requestId}/start_demand/`,
    method: REQUEST_METHODS.POST,
  }

  return makeApiCall(options)
}

export const getMedicalAssociations = async ({ queryKey }) => {
  const [, params] = queryKey
  const nonNullParams = pickBy(params, value => !!value)
  // level1 => level_1
  const apiFriendlyParams = transform(
    nonNullParams,
    (result, value, key) => {
      result[snakeCase(key)] = value
      return result
    },
    {}
  )

  const qs = new URLSearchParams(apiFriendlyParams)
  const options = {
    path: `/data/static/md_description/?${qs}`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const uploadExhibit = async ({ data, caseId }) => {
  const options = {
    path: `/case/${caseId}/exhibit/`,
    method: REQUEST_METHODS.POST,
    json: false,
    isFormData: true,
    data,
  }

  return makeApiCall(options)
}

export const uploadMedchronExhibit = async ({ data, documentId }) => {
  const options = {
    path: `/documents/${documentId}/exhibit/`,
    method: REQUEST_METHODS.POST,
    json: false,
    isFormData: true,
    data,
  }

  return makeApiCall(options)
}

export const downloadLetterhead = async ({ customUrl, fileName = "" }) => {
  const options = {
    customUrl,
    method: REQUEST_METHODS.GET,
    blob: true,
    json: false,
  }

  return await download(options, fileName)
}

export const downloadExhibit = async ({ caseId, exhibitId, downloadFile = false, fileName = "" }) => {
  const options = {
    path: `/case/${caseId}/exhibit/${exhibitId}/smart-download/`,
    method: REQUEST_METHODS.GET,
    blob: true,
    json: false,
  }

  if (downloadFile) {
    return await download(options, fileName)
  }

  return makeApiCall(options)
}

export const downloadPartition = async ({ exhibitId, partitionId }) => {
  const options = {
    path: `/exhibits/${exhibitId}/partition/${partitionId}/download/`,
    method: REQUEST_METHODS.GET,
    blob: true,
    json: false,
  }

  return await download(options)
}

export const downloadFirmTemplate = async ({ firmId, fileName }) => {
  const options = {
    path: `/firm/${firmId}/download_template/`,
    method: REQUEST_METHODS.GET,
    blob: true,
    json: false,
  }

  return await download(options, fileName)
}

export const updateProviderExhibit = async ({ caseId, exhibitId, data, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/exhibit/${exhibitId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}
export const updateCaseRelevantDocument = async ({ caseId, documentId, data }) => {
  const options = {
    path: `/case/${caseId}/relevant_documents/${documentId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const deleteProviderExhibit = async ({ caseId, exhibitId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/exhibit/${exhibitId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const getProviderExhibits = async ({ caseId, section, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/exhibit/?section=${section}`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const createProviderExhibit = async ({ data, caseId, providerId, isFormData = false }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/exhibit/`,
    method: REQUEST_METHODS.POST,
    json: !isFormData,
    isFormData: isFormData,
    data,
  }

  return makeApiCall(options)
}

export const createCaseSummary = async ({ data }) => {
  const options = {
    path: `/case/summaries/create_summary/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const getRequestByCase = async ({ queryKey }) => {
  const [, caseId] = queryKey
  const options = {
    path: `/case/${caseId}/questionnaire/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getCaseByRequestId = async ({ queryKey }) => {
  const [, requestId] = queryKey
  const options = {
    path: `/intake/${requestId}/case/`,
    method: REQUEST_METHODS.GET,
  }

  const response = await makeApiCall(options)
  if (response instanceof EmptyResponse) {
    return null
  }

  return response
}

export const createNewChunkedUpload = async ({ data }) => {
  const options = {
    path: import.meta.env.VITE_CND_UPLOAD ? `/upload/upload/generate_url/` : `/upload/upload/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}
export const cndUploadChunkedUpload = async ({ signUrl, data }) => {
  const options = {
    customUrl: signUrl,
    method: REQUEST_METHODS.PUT,
    data,
    json: false,
    isFormData: true,
  }

  return makeApiCall(options)
}

export const uploadChunkedUpload = async ({ uploadId, data }) => {
  const options = {
    path: `/upload/upload/${uploadId}/uploadchunk/`,
    method: REQUEST_METHODS.POST,
    data,
    json: false,
    isFormData: true,
  }

  return makeApiCall(options)
}

export const getNonEconomicValidationFields = async caseId => {
  const options = {
    path: `/case/${caseId}/noneconomic/validate/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getHouseholdServicesValidationFields = async caseId => {
  const options = {
    path: `/case/${caseId}/household/validate/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getLossOfIncomeValidationFields = async caseId => {
  const options = {
    path: `/case/${caseId}/loss/validate/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getLossOfIncomeColumns = async caseId => {
  // const [, caseId] = queryKey
  const options = {
    path: `/case/${caseId}/loss/columns/`,
    method: REQUEST_METHODS.GET,
  }
  return makeApiCall(options)
}

export const updateLossOfIncomeColumns = async ({ data, caseId }) => {
  const options = {
    path: `/case/${caseId}/loss/columns/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const downloadGenericFile = async ({ downloadURL }) => {
  const path = downloadURL.replace(getAPIServerURL(), "")
  const options = {
    path: path,
    method: REQUEST_METHODS.GET,
    json: false,
  }

  return makeApiCall(options)
}

export const getSectionMissingExhibits = async ({ caseId, section }) => {
  const options = {
    path: `/case/${caseId}/missing_exhibits/?section=${section}`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getMissingExhibits = async ({ caseId, onlyUnresolved = true }) => {
  const options = {
    path: `/case/${caseId}/missing_exhibits/`,
    method: REQUEST_METHODS.GET,
    query: {
      is_resolved: onlyUnresolved ? false : null,
    },
  }

  return makeApiCall(options)
}

export const getCurrentMissingExhibits = async ({ caseId }) => {
  const options = {
    path: `/case/${caseId}/missing_exhibits/current/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const createMissingExhibit = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/missing_exhibits/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateMissingExhibit = async ({ caseId, missingExhibitId, data }) => {
  const options = {
    path: `/case/${caseId}/missing_exhibits/${missingExhibitId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const getMissingExhibitEvents = async ({ caseId }) => {
  const options = {
    path: `/case/${caseId}/missing_exhibit_events/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const createMissingExhibitEvent = async ({ caseId, data }) => {
  const options = {
    path: `/case/${caseId}/missing_exhibit_events/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const sendMissingExhibitNotification = async ({ caseId, missingExhibitId, data }) => {
  const options = {
    path: `/case/${caseId}/missing_exhibit_events/${missingExhibitId}/notify/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const getMinimalProviders = async caseId => {
  const options = {
    path: `/case/${caseId}/provider/minimal/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getExhibitByQuestionnaireFileId = async (requestId, questionnaireFileId) => {
  const options = {
    path: `/intake/${requestId}/file/${questionnaireFileId}/exhibit/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const fetchInjuries = async lookupTerm => {
  const options = {
    path: `/data/static/injury_names`,
    method: REQUEST_METHODS.GET,
    query: {
      injury_names: lookupTerm,
    },
  }

  return makeApiCall(options)
}

export const createContract = async ({ data }) => {
  const options = {
    path: `/contract/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const updateContract = async ({ data, contractId }) => {
  const options = {
    path: `/contract/${contractId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return makeApiCall(options)
}

export const deleteContract = async contractId => {
  const options = {
    path: `/contract/${contractId}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const getContracts = async query => {
  const options = {
    path: `/contract/`,
    method: REQUEST_METHODS.GET,
    query: query,
  }

  return makeApiCall(options)
}

export const deletePricingModel = async id => {
  const options = {
    path: `/contract/pricing-model/${id}/`,
    method: REQUEST_METHODS.DELETE,
  }

  return makeApiCall(options)
}

export const downloadAllRequestsCSV = async () => {
  const options = {
    path: `/intake/csv/`,
    method: REQUEST_METHODS.GET,
    json: false,
    blob: true,
    headers: {
      Accept: "text/csv",
    },
  }

  return await download(options, `requests_${format(new Date(), "MM/dd/yyyy")}.csv`)
}

export const getFirmsCurrentContract = async ({ firmId }) => {
  const options = {
    path: `/firm/${firmId}/current_contract/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const cancelContracts = async ({ firmId, cancellation_date, cancellation_note }) => {
  const options = {
    path: `/firm/${firmId}/cancel_contracts/`,
    method: REQUEST_METHODS.POST,
    data: { cancellation_date, cancellation_note },
  }

  return makeApiCall(options)
}

export const getFirmsFirstContract = async firmId => {
  const options = {
    path: `/firm/${firmId}/first_contract/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getAnnotatedProviderIcdCodes = async (caseId, providerId) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/annotated_icd_codes/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options).then(response => (isEmpty(response) ? [] : response))
}

export const syncProviderIcdCodes = async (caseId, providerId) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/sync_icd_codes/`,
    method: REQUEST_METHODS.POST,
  }

  return makeApiCall(options)
}

export const syncProviderAppointments = async (caseId, providerId) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/sync_appointments/`,
    method: REQUEST_METHODS.POST,
  }

  return makeApiCall(options)
}

export const syncProviderBills = async (caseId, providerId) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/sync_bills/`,
    method: REQUEST_METHODS.POST,
  }

  return makeApiCall(options)
}

export const getFeaturePermissions = async (query = {}) => {
  const options = {
    path: `/settings/feature-permission/`,
    method: REQUEST_METHODS.GET,
    query: query,
  }

  return makeApiCall(options)
}

export const updateFeaturePermission = async ({ permissionId, data }) => {
  const options = {
    path: `/settings/feature-permission/${permissionId}/`,
    method: REQUEST_METHODS.PATCH,
    data: data,
  }

  return makeApiCall(options)
}

export const getAnnotatedProviderAppointments = async (caseId, providerId) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/annotated_appointments/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getAnnotatedProviderBills = async (caseId, providerId) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/annotated_bills/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const getAnnotatedInjuryDetails = async (caseId, providerId) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/annotated_injury_details/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const setProviderReviewedAtDate = async ({ caseId, providerId }) => {
  const options = {
    path: `/case/${caseId}/provider/${providerId}/review/`,
    method: REQUEST_METHODS.POST,
    data: {},
  }

  return makeApiCall(options)
}

export const importProviders = async caseId => {
  const options = {
    path: `/case/${caseId}/import-providers/`,
    method: REQUEST_METHODS.POST,
  }

  return makeApiCall(options)
}

export const getProviderUpdatesForCase = async caseId => {
  const options = {
    path: `/case/${caseId}/provider_updates/`,
    method: REQUEST_METHODS.GET,
  }

  return makeApiCall(options)
}

export const unlinkPartitionFromProvider = async ({ exhibitId, partitionId, data }) => {
  const options = {
    path: `/exhibits/${exhibitId}/partition/${partitionId}/unlink-provider/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

export const createLitifyRequest = async caseData => {
  const options = {
    path: `/integrations/litify/`,
    method: REQUEST_METHODS.POST,
    data: caseData,
  }

  return makeApiCall(options)
}

export const syncPermissions = async () => {
  const options = {
    path: `/settings/feature-permission/refresh/`,
    method: REQUEST_METHODS.POST,
    json: false,
  }

  return makeApiCall(options)
}

export const getPermissionsForUser = async ({ data }) => {
  const options = {
    path: `/settings/feature-permission/check_features/`,
    method: REQUEST_METHODS.POST,
    data: data,
  }

  return makeApiCall(options)
}

export const updateSingleFeaturePermission = async ({ permissionId, data }) => {
  const options = {
    path: `/settings/feature-permission/${permissionId}/single/`,
    method: REQUEST_METHODS.PUT,
    data: data,
  }

  return makeApiCall(options)
}

export const deleteSingleFeaturePermission = async ({ permissionId, data }) => {
  const options = {
    path: `/settings/feature-permission/${permissionId}/single/`,
    method: REQUEST_METHODS.DELETE,
    data: data,
  }

  return makeApiCall(options)
}

export const smartAdvocateCreateQuestionnaire = async data => {
  const options = {
    path: `/integrations/smart_advocate/create_questionnaire/`,
    method: REQUEST_METHODS.POST,
    data: data,
  }

  return makeApiCall(options)
}

export const smartAdvocateGetCaseInformation = async caseNumber => {
  const options = {
    path: `/integrations/smart_advocate/case_information/`,
    method: REQUEST_METHODS.GET,
    query: {
      case_number: caseNumber,
    },
  }

  return makeApiCall(options)
}

export const smartAdvocateGetInsurance = async caseNumber => {
  const options = {
    path: `/integrations/smart_advocate/insurance_options/`,
    method: REQUEST_METHODS.GET,
    query: {
      case_number: caseNumber,
    },
  }

  return makeApiCall(options)
}

export const smartAdvocateGetCaseDocuments = async caseNumber => {
  const options = {
    path: `/integrations/smart_advocate/case_documents/`,
    method: REQUEST_METHODS.GET,
    query: {
      case_number: caseNumber,
    },
  }

  return makeApiCall(options)
}

export const updateExhibitPartition = async ({ exhibitId, partitionId, data }) => {
  const options = {
    path: `/exhibits/${exhibitId}/partition/${partitionId}/`,
    method: REQUEST_METHODS.PATCH,
    data: data,
  }

  return makeApiCall(options)
}
