import { ProviderMedicalProfessional } from "api/services/provider-summaries/types"
import { noop } from "lodash"
import { memo, useMemo } from "react"
import { Control, Controller } from "react-hook-form"
import { StyledAutocompleteOption, SummaryForm } from "./styled"
import { DateField, InputField } from "common/form-components"
import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { useProviderSummariesStore } from "./store"

type MedicalProfessionalFormProps = {
  control: Control<ProviderMedicalProfessional>
  dateOfService: string
  setMedicalProfessional: (item: ProviderMedicalProfessional) => void
}

export const MedicalProfessionalForm = memo(function MedicalProfessionalForm({
  control,
  dateOfService,
  setMedicalProfessional,
}: MedicalProfessionalFormProps) {
  const medicalProfessionals = useProviderSummariesStore(
    state => state.medicalSummaries.get(dateOfService)?.medicalProfessionalOptions
  )
  const medicalProfessionalTitles = useMemo(
    () => (medicalProfessionals ?? []).map(({ title }) => title),
    [medicalProfessionals]
  )

  return (
    <SummaryForm>
      <DateField disabled initialValue={dateOfService} onChange={noop} size="small" label="Date of Service" />
      <Controller
        control={control}
        name="title"
        rules={{ required: true, minLength: 1 }}
        render={({ field, fieldState }) => (
          <Autocomplete
            options={medicalProfessionalTitles}
            freeSolo
            clearOnBlur={false}
            blurOnSelect
            {...field}
            onChange={(_, value) => field.onChange({ target: { value: value ?? "" } })}
            onInputChange={(_, value) => field.onChange({ target: { value: value ?? "" } })}
            renderOption={(props, option) => (
              <StyledAutocompleteOption {...props}>{option}</StyledAutocompleteOption>
            )}
            renderInput={params => (
              <TextField
                {...params}
                error={!!fieldState.error}
                label="Medical Professional Title"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                variant="outlined"
                size="small"
              />
            )}
          />
        )}
      />
      <InputField
        rules={{ required: true, minLength: 1 }}
        control={control}
        name="firstName"
        label="Medical Professional First Name"
        size="small"
      />
      <InputField
        rules={{ required: true, minLength: 1 }}
        control={control}
        name="lastName"
        label="Medical Professional Last Name"
        size="small"
      />
      <InputField
        rules={{ required: true, minLength: 1 }}
        control={control}
        name="designation"
        label="Medical Professional Designation"
        size="small"
      />

      {medicalProfessionals && medicalProfessionals.length > 1 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Box sx={{ fontSize: "10px", lineHeight: "14px" }}>* Multiple Medical Professionals Detected:</Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            {medicalProfessionals.map(item => (
              <Box
                key={`${item.title}_${item.firstName}_${item.lastName}_${item.designation}`}
                sx={{ display: "flex", gap: 1.25, alignItems: "center", cursor: "pointer" }}
                onClick={() =>
                  setMedicalProfessional({
                    title: "",
                    firstName: "",
                    lastName: "",
                    designation: "",
                    ...item,
                  })
                }
              >
                <ArrowUpwardIcon sx={{ width: "12px", height: "12px" }} color="secondary" />
                <Typography variant="helper" sx={{ fontSize: "10px" }}>
                  {item.title} {item.firstName} {item.lastName}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </SummaryForm>
  )
})
