import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEventHandler,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react"
import { FormControl, InputLabel, SxProps, selectClasses } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Autocomplete, {
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  createFilterOptions,
} from "@mui/material/Autocomplete"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import TextButton from "common/buttons/TextButton"
import { EditableSelectInput, StyledPopper, StyledAutocompleteInput } from "./styled"
import PopperComponent from "./Popper"
import ValueComponent from "./Value"
import { EMPTY_VALUE_COLOR, EMPTY_VALUE_DEFAULT_NAME, EMPTY_VALUE_ID, EmptyValue } from "./constants"
import { isValue } from "./utils"
import { OptionItem } from "./OptionItem"
import { ListPaper, ListPaperProps } from "./ListPaper"
import { Theme } from "@emotion/react"
import { Item } from "./type"
import { useHandleMessages } from "common/messages/useHandleMessages"

export type EditableSelectProps = {
  value: Nullable<Item["id"]>
  options: Item[]
  onCreate: (value: string) => Item | Promise<Item>
  onRename: (id: Item["id"], name: string) => void
  customInputRenderer?: (customProps: { onClick: MouseEventHandler }) => React.ReactElement
  sx?: SxProps<Theme>
  label?: string
  itemType?: string
  inputPlaceholder?: string
  emptyValueLabel?: string
  startAdornment?: ReactNode
  readOnly?: boolean
} & EitherProps<
  {
    disableEmpty: true
    onChange: (value: string) => void
  },
  {
    onChange: (value: Nullable<string>) => void
  }
>

const filterOptions = createFilterOptions<Item>({
  trim: true,
})

export function EditableSelect({
  value,
  options,
  customInputRenderer,
  label,
  disableEmpty,
  sx = {},
  inputPlaceholder,
  itemType = "item",
  emptyValueLabel,
  startAdornment,
  readOnly,
  ...callbacks
}: EditableSelectProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [popperWidth, setPopperWidth] = useState(0)
  const [search, setSearch] = useState("")
  const [draftName, setDraftName] = useState("")
  const [isCreating, setIsCreating] = useState(false)
  const [editingId, setEditingId] = useState<Nullable<Item["id"]>>(null)
  const { showErrorMessage } = useHandleMessages()

  const emptyOption = useMemo(
    () => ({
      id: EMPTY_VALUE_ID,
      name: emptyValueLabel ?? EMPTY_VALUE_DEFAULT_NAME,
      color: EMPTY_VALUE_COLOR,
    }),
    [emptyValueLabel]
  )

  const searchRef = useRef(search)
  searchRef.current = search

  const selectedValue = useMemo(
    () => options.find(option => option.id === value) ?? emptyOption,
    [options, value, emptyOption]
  )
  const selectableOptions = useMemo(
    () => (disableEmpty ? options : [...options, emptyOption]),
    [options, disableEmpty, emptyOption]
  )

  const open = Boolean(anchorEl)
  const id = open ? "editable-select" : undefined

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
    setSearch("")
    setEditingId(null)
  }, [anchorEl])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!isCreating && !readOnly) {
        setAnchorEl(event.currentTarget)
        setPopperWidth(event.currentTarget.clientWidth)
        setSearch("")
      }
    },
    [isCreating, readOnly]
  )

  const handleSearchChange = useCallback(
    (e: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
      setSearch(reason === "input" ? value : "")
    },
    []
  )

  const handleEscapeClose = useCallback((event: ChangeEvent<BaseObject>, reason: AutocompleteCloseReason) => {
    if (reason === "escape") {
      setAnchorEl(null)
      setSearch("")
      setEditingId(null)
    }
  }, [])

  const callbacksRef = useRef(callbacks)
  callbacksRef.current = callbacks

  const handleOnChange = useCallback(
    (e: React.SyntheticEvent<Element, Event>, value: EmptyValue | Item) => {
      const nextValue = isValue(value) ? value.id : null

      if (disableEmpty && nextValue === null) return

      callbacksRef.current.onChange(nextValue as Item["id"])
    },
    [disableEmpty]
  )

  const handleOnCreate = useCallback(async () => {
    const valueToCreate = searchRef.current
    const onCreate = callbacksRef.current.onCreate

    if (valueToCreate && onCreate) {
      setAnchorEl(null)
      setIsCreating(true)
      setDraftName(valueToCreate)

      try {
        const { id: nextValue } = await onCreate(valueToCreate)
        callbacksRef.current.onChange(nextValue)
      } catch (error) {
        showErrorMessage({
          message: `There was an error creating ${itemType}.`,
          error,
        })
      } finally {
        setIsCreating(false)
        setDraftName("")
      }
    }
  }, [showErrorMessage, itemType])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.code === "Enter") {
        handleOnCreate()
      }
    },
    [handleOnCreate]
  )

  const hasExactMatch = selectableOptions.some(
    ({ name }) => name.toLowerCase().trim() === search.toLowerCase().trim()
  )

  return (
    <>
      {customInputRenderer ? (
        customInputRenderer({ onClick: handleClick })
      ) : (
        <FormControl sx={{ width: 420, ...sx }} disabled={isCreating}>
          {label && <InputLabel shrink>{label}</InputLabel>}
          <EditableSelectInput
            readOnly={readOnly}
            data-test="editable-select-input"
            size="small"
            label={label}
            notched
            disabled={isCreating}
            open={anchorEl !== null}
            inputProps={{ "aria-hidden": true }}
            startAdornment={
              startAdornment !== undefined ? (
                startAdornment
              ) : (
                <ValueComponent value={selectedValue} draftName={draftName} isCreating={isCreating} />
              )
            }
            endAdornment={!readOnly && <ArrowDropDownIcon className={selectClasses.icon} />}
            onClick={handleClick}
          />
        </FormControl>
      )}
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        sx={{ width: popperWidth, minWidth: editingId !== null ? 580 : 280 }}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Autocomplete
            data-test="editable-select-autocomplete"
            open
            value={selectedValue}
            onChange={handleOnChange}
            onClose={handleEscapeClose}
            PopperComponent={PopperComponent}
            PaperComponent={ListPaper}
            slotProps={{
              paper: {
                createButton: search && !hasExactMatch && (
                  <TextButton
                    size="small"
                    onMouseDown={handleOnCreate}
                    disabled={isCreating}
                    data-test="create-button"
                  >
                    {isCreating ? `Creating new ${itemType}...` : `+ Create new ${itemType}`}
                  </TextButton>
                ),
              } as ListPaperProps,
            }}
            inputValue={search}
            onInputChange={handleSearchChange}
            forcePopupIcon={false}
            disableClearable
            renderInput={params => (
              <StyledAutocompleteInput
                ref={params.InputProps.ref}
                inputProps={{
                  ...params.inputProps,
                  disabled: isCreating || editingId !== null,
                  "data-test": "editable-search-input",
                }}
                autoFocus
                onKeyDown={handleKeyDown}
                readOnly={editingId !== null}
                placeholder={inputPlaceholder}
                size="small"
              />
            )}
            renderOption={(props, option) => (
              <OptionItem
                {...props}
                option={option}
                onSave={callbacks.onRename}
                onEdit={setEditingId}
                edit={option.id === editingId}
                readonly={editingId !== null && editingId !== option.id}
                options={options}
                onMouseDown={e => e.stopPropagation()}
              />
            )}
            options={selectableOptions}
            filterOptions={filterOptions}
            // EMPTY_VALUE option should always be presented
            getOptionLabel={option =>
              option.id === EMPTY_VALUE_ID ? `${search} ${Math.random()}` : option.name
            }
          />
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}
