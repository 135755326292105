import { STEP_CODES } from "../constants"
import { DocumentSelectStep } from "./components/DocumentSelectStep"
import { useRequestContext } from "requests/context"
import { FormByType } from "./FormByType"

export const RequestStep = () => {
  const { activeStep } = useRequestContext()
  switch (activeStep) {
    case STEP_CODES.DOCUMENT_TYPE: {
      return <DocumentSelectStep />
    }

    case STEP_CODES.CLIENT_INFORMATION: {
      return <FormByType />
    }

    default: {
      return null
    }
  }
}
