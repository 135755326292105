import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { COMPONENTS_KEYS } from "documents/constants"
import { APPOINTMENT_TAG_ACTIONS, PROVIDER_RECONCILIATION_STATUSES } from "documents/types"
import { INTAKE_STATUSES } from "requests/constants"
import { DOCUMENT_TYPES } from "requests/enums"

export enum DOCUMENT_GENERATION_STATUS {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
  ERROR = "error",
}

export enum GENERATED_SUMMARY_STATUSES {
  NOT_REQUESTED = "Not Requested",
  EMPTY = "Empty",
  PENDING = "Pending",
  FAILED_OR_HUNG = "Failed Or Hung",
  NEW_VERSION_AVAILABLE = "New Version Available",
  DEDUPING_BPS = "Deduping Bullet Point Summaries",
  DEDUPE_BPS_FAILED = "Dedupe Bullet Point Summaries Failed",
  COMPLETED = "Complete",
}

export interface AppointmentCreatedBy {
  pk: PrimaryKey
  first_name: string
  last_name: string
  email: string
}

export interface AppointmentTagDto {
  action: APPOINTMENT_TAG_ACTIONS
  created_at: string
  created_by: AppointmentCreatedBy
}

export interface DocumentGenerationDTO extends Required<BaseEntity> {
  requested_at: string
  completed_at: Nullable<string>
  status: DOCUMENT_GENERATION_STATUS
  document_file: Nullable<string>
  request_id: PrimaryKey
  error_code: Nullable<string>
  error_message: Nullable<string>
}

export interface DocumentGenerationEntity {
  id: PrimaryKey
  started: string
  completed: string
  status: DOCUMENT_GENERATION_STATUS
  downloadURL: Nullable<string>
  errorCode: Nullable<string>
  errorMessage: Nullable<string>
}

export interface DocumentGenerationResponseEntity {
  requestId: PrimaryKey
}

export interface ExhibitDto extends Required<BaseEntity> {
  name: string
  number_of_pages?: Nullable<number>
  file_name: Nullable<string>
}

export interface AppointmentDto {
  pk: string
  date_of_service: string
  time_of_service: Nullable<string>
  provider_id: Nullable<ProviderDto["pk"]>
  partitions: PrimaryKey[]
  user_summary: Nullable<EditorRoot>
  generated_summary: Nullable<EditorRoot>
  generated_summary_status: GENERATED_SUMMARY_STATUSES
  tags: AppointmentTagDto[]
}

export interface ProviderDto {
  pk: string
  name: string
  color: string
}

export interface PartitionDto extends Required<BaseEntity> {
  name: string
  start_page: number
  end_page: number
  exhibit: PrimaryKey
}

export interface PlaintiffDto extends Required<BaseEntity> {
  first_name: string
  last_name: string
}

export interface ICDCodeRelationDto {
  partition_id: PrimaryKey
  page_number: Nullable<number>
}

export interface ICDCodeDto {
  pk: string
  appointment_id: AppointmentDto["pk"]
  icd_code: string
  description: string
  partitions: ICDCodeRelationDto[]
}

export interface DocumentEntitiesDto {
  plaintiff: Nullable<PlaintiffDto>
  exhibits: ExhibitDto[]
  provider: ProviderDto[]
  partitions: PartitionDto[]
  appointments: AppointmentDto[]
  icd_codes: ICDCodeDto[]
  questionnaire_id: PrimaryKey
  date_of_incident: string
}

export interface MedchronTileDTO {
  number_of_appointments: number
  start_date: string
  end_date: string
  questionnaire_type: DOCUMENT_TYPES
  questionnaire_status: INTAKE_STATUSES
  provider_reconciliation_status: PROVIDER_RECONCILIATION_STATUSES
  use_exhibit_builder: boolean
}

export interface SyncWithDocumentStructureDTO {
  document_id: string
}

export interface BaseAppointmentOperationPayload {
  appointmentId: AppointmentDto["pk"]
}

export interface SetAppointmentProvider extends BaseAppointmentOperationPayload {
  providerId: AppointmentDto["provider_id"]
}

export interface SetAppointmentDate extends BaseAppointmentOperationPayload {
  dateOfService: AppointmentDto["date_of_service"]
}
export interface SetAppointmentTime extends BaseAppointmentOperationPayload {
  timeOfService: AppointmentDto["time_of_service"]
}

export interface SetAppointmentSummary extends BaseAppointmentOperationPayload {
  summary: NonNullable<AppointmentDto["user_summary"]>
}

export type CreateIcdCode = Pick<ICDCodeDto, "icd_code" | "partitions">
export type UpdateIcdCode = CreateIcdCode & { id: ICDCodeDto["pk"] }
export type DeleteIcdCode = Pick<ICDCodeDto, "icd_code">
export type CreateAppointmentPartition = Pick<PartitionDto, "exhibit" | "start_page" | "end_page">

export type CreateAppointment = RequiredNonNullableObj<
  Pick<AppointmentDto, "date_of_service" | "provider_id">
> &
  Pick<AppointmentDto, "user_summary"> & {
    icd_codes: CreateIcdCode[]
    partitions: CreateAppointmentPartition[]
  }

export type UpdateResponse<T> = {
  success: true
} & T

export interface MatchesDocumentStructureResponseDto {
  matching_document_structure: boolean
}

export interface MatchesDocumentStructureResponse {
  matchingDocumentStructure: boolean
}

export interface DocumentStructureDto {
  document_structure_id: Nullable<string>
  component_key: COMPONENTS_KEYS
  pk: number
  section_id: string
  type: string
}

export interface DocumentStructureComponent {
  documentStructureId: Nullable<string>
  componentKey: COMPONENTS_KEYS
  pk: number
  sectionId: string
  type: string
}
