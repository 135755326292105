import React, { useEffect, useMemo, useState, useCallback } from "react"
import Skeleton from "@mui/material/Skeleton"
import { caseService } from "api/services/case"
import { CASE_SECTIONS } from "common/types/sections"
import { CaseAlert } from "demand/components/CaseAlert"

import size from "lodash/size"
import { useSuspenseQuery } from "@suspensive/react-query"

import { getCommonMutateOptions } from "../../utils/common"
import { getChangedFields } from "../../utils/common"

import { CaseSection } from "../CaseSection"

import { withSuspense } from "common/withSuspense"

import { useMutation } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"

import { queryKeys } from "../../react-query/constants"
import useAutosave from "../../hooks/useAutosave"
import { useFormContext } from "../context"
import { formSectionsToRoutes, STEP_STATUSES } from "../constants"
import { CasePainAndSuffering } from "api/services/case/types"
import { DamagesSection } from "../DamagesSection"
import { isEditorEmpty } from "common/form-components/rich-text/utils"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { ClickInToEditor } from "./ClickInToEditor"
import { FieldBox, PainAndSufferingContainer } from "./styled"
import { theme } from "app/theme"
import { queryClient } from "react-query/queryClient"

interface Props {
  lastVisited: React.MutableRefObject<Nullable<string>>
}

function PainAndSufferingSection({
  values,
  plaintiffId,
}: {
  values: CasePainAndSuffering
  plaintiffId: string | undefined
}) {
  const userActionRequired = Object.values(values.sections).some(section => section.userActionRequired)
  const { caseId, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus } = useFormContext()

  const { saveRef } = useOutletContext<{
    saveRef: React.MutableRefObject<(e?: React.BaseSyntheticEvent) => Promise<void>>
  }>()

  const [errors, setErrors] = useState<string | null>(null)
  const { control, handleSubmit, reset, formState, setValue } = useForm({
    defaultValues: values,
  })

  const contentId = values.pk

  const mutationOptions = getCommonMutateOptions({
    setErrors,
    reset,
    setSavedSuccessfully,
    showErrorMessage,
    setValue,
    onSuccess: () => {
      setSavedSuccessfully(true)
      handleUpdateStepStatus({ status: STEP_STATUSES.completed })
    },
  })

  const { mutate: saveCasePainAndSuffering } = useMutation(
    caseService.saveCasePainAndSuffering,
    mutationOptions
  )

  const handleSaveCasePainAndSuffering = useCallback(
    (data: CasePainAndSuffering) => {
      reset(data, { keepValues: true })
      queryClient.setQueryData([queryKeys.painAndSuffering, caseId, plaintiffId], data)

      saveCasePainAndSuffering({ caseId, plaintiffId, data })
    },
    [caseId, plaintiffId, reset, saveCasePainAndSuffering]
  )

  const handleOnBlur = handleSubmit(data => {
    const hasChangedFields = size(getChangedFields(data, formState)) > 0
    if (!hasChangedFields) return

    handleSaveCasePainAndSuffering(data)
  })

  const handleFormSubmit = handleSubmit(handleSaveCasePainAndSuffering)

  useEffect(() => {
    saveRef.current = handleFormSubmit
  }, [saveRef, handleFormSubmit])

  useAutosave({
    shouldAutosave: formState.isDirty && !!contentId && !!plaintiffId,
    save: handleFormSubmit,
  })

  const damageSection = useMemo(() => {
    if (!values?.damages_sections) return null
    return values?.damages_sections[0] ?? null
  }, [values])

  const damageSectionHasDetails: boolean =
    !!damageSection &&
    !!damageSection.details_json &&
    !isEditorEmpty(damageSection.details_json as EditorRoot<true>)

  const descriptionTemplatedSection = values?.sections?.pain_and_suffering_plaintiff_details ?? null
  const showDamagesSection = damageSectionHasDetails || !descriptionTemplatedSection

  const firmTemplate = useMemo(() => {
    if (!values?.firm_template) return null
    return values.firm_template
  }, [values])

  return (
    <PainAndSufferingContainer>
      {userActionRequired && (
        <CaseAlert
          title="New templates have been added to reflect the newly selected attributes."
          message="Ensure templates are modified appropriately below."
        />
      )}
      {errors && <span role="alert">{errors}</span>}

      {firmTemplate && (
        <DamagesSection
          caseId={caseId}
          template={firmTemplate}
          section={damageSection}
          showTitle={false}
          showPreview={false}
          showDetails={showDamagesSection}
        />
      )}

      <ClickInToEditor
        control={control}
        section={CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_PRE_TEXT}
        caseId={caseId}
        keyValue={`pre-text-${plaintiffId}`}
        onBlur={handleOnBlur}
      />

      {!showDamagesSection && (
        <FieldBox onBlur={handleOnBlur} theme={theme}>
          <CaseSection
            control={control}
            section={CASE_SECTIONS.PAIN_AND_SUFFERING_PLAINTIFF_DETAILS}
            caseId={caseId}
            key={`details-${plaintiffId}`}
            includeAskAI
          />
        </FieldBox>
      )}

      <ClickInToEditor
        control={control}
        section={CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_POST_TEXT}
        caseId={caseId}
        keyValue={`post-text-${plaintiffId}`}
        onBlur={handleOnBlur}
      />
    </PainAndSufferingContainer>
  )
}

export const PainAndSuffering = withSuspense(
  function PainAndSuffering({ lastVisited }: Props) {
    const { caseId, currentPlaintiff } = useFormContext()
    const plaintiffId = currentPlaintiff?.id

    const { data: casePainAndSuffering } = useSuspenseQuery(
      [queryKeys.painAndSuffering, caseId, plaintiffId],
      () => caseService.getCasePainAndSuffering({ caseId, plaintiffId }),
      {
        enabled: !!plaintiffId,
      }
    )

    useEffect(() => {
      lastVisited.current = formSectionsToRoutes.pain_and_suffering
    }, [lastVisited])

    if (!casePainAndSuffering) return null

    return (
      <PainAndSufferingSection key={plaintiffId} values={casePainAndSuffering} plaintiffId={plaintiffId} />
    )
  },
  <Skeleton height={70} animation="wave" variant="rounded" />
)
