import { DndProps, SortableNestedList } from "common/nested-list/SortableNestedList"
import { UserExhibitListContent } from "./UserExhibitListContent"
import { UserExhibitListItem } from "./UserExhibitListItem"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { DndVirtualListData } from "exhibit-builder/store/types"
import { ActionPopup } from "./ActionPopup/ActionPopup"
import { DndContext } from "@dnd-kit/core"
import { useCallback, useMemo } from "react"
import { Box } from "@mui/material"
import { UserExhibitListPlaceholder } from "./UserExhibitListPlaceholder"
import * as Sentry from "@sentry/react"
import { useHandleMessages } from "common/messages/useHandleMessages"

export function UserExhibitList({ listData }: { listData: DndVirtualListData }) {
  const isReordering = useExhibitBuilderStore(useShallow(state => state.document.isReordering))
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const { showErrorMessage } = useHandleMessages()

  const handleReorder: DndProps<DndVirtualListData>["onUpdate"] = useCallback(
    async (_items, operation) => {
      if (!operation) {
        return
      }

      exhibitBuilderActions.setIsReordering(true)

      const { item, index } = operation
      if (item.type === "userExhibit") {
        try {
          await exhibitBuilderActions.reorderUserExhibit(item.id, index)
        } catch (err) {
          Sentry.captureException(err)
          showErrorMessage("There was an error reordering exhibits. Please refresh the page and try again.")
        }
      }

      if (item.type === "exhibitPartition" && operation.parent?.type === "userExhibit") {
        exhibitBuilderActions.setIsReordering(true)
        try {
          await exhibitBuilderActions.reorderExhibitPartition(item.id, index, operation.parent.id)
        } catch (err) {
          Sentry.captureException(err)
          showErrorMessage("There was an error reordering partitions. Please refresh the page and try again.")
        }
      }

      exhibitBuilderActions.setIsReordering(false)
    },
    [showErrorMessage]
  )

  const canDrop: NonNullable<DndProps<DndVirtualListData>["canDrop"]> = useCallback(
    (item, draggingItemId, items) => {
      const draggingItem = items[draggingItemId]
      const isMovingUserExhibit = draggingItem.type === "userExhibit" && item.type === "userExhibit"
      const isMovingExhibitPartition =
        draggingItem.type === "exhibitPartition" && item.type === "exhibitPartition"

      return isMovingUserExhibit || isMovingExhibitPartition
    },
    []
  )

  const listProps = useMemo(() => {
    if (isReadOnly) {
      return { readonly: true as const }
    }

    return { canDrag: () => !isReordering, canDrop, onUpdate: handleReorder }
  }, [isReadOnly, isReordering, handleReorder, canDrop])

  if (!listData.children.length) {
    return <div>no results</div>
  }

  return (
    <Box data-test="user-exhibit-list">
      <SortableNestedList
        items={[listData]}
        uniqueKey="uniqueKey"
        ItemComponent={UserExhibitListItem}
        ItemContentComponent={UserExhibitListContent}
        contentProps={null}
        placeholderProps={null}
        PlaceholderComponent={UserExhibitListPlaceholder}
        {...listProps}
      />
      <DndContext>
        <ActionPopup />
      </DndContext>
    </Box>
  )
}
