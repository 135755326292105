import React, { forwardRef } from "react"
import MUIButton, { ButtonProps as MUIButtonProps } from "@mui/material/Button"
import styled from "@emotion/styled"
import { theme } from "app/theme"
import { IconButton } from "@mui/material"

export interface ButtonProps extends MUIButtonProps {
  iconLeft?: React.ReactElement
  iconRight?: React.ReactElement
  label?: string
  color?: "primary" | "secondary" | "info"
  target?: string
  textButton?: boolean
  LabelOnly?: boolean
}

const getButtonColorStyle = ({ color = "primary", href, textButton, LabelOnly }: ButtonProps): object => {
  switch (color) {
    case "primary":
      return {
        background: theme.palette.blue.primary,
        color: theme.palette.background.default,
        "&:hover": {
          background: theme.palette.blue.dark,
        },
        "&:focus-visible": {
          background: theme.palette.blue.main,
        },
      }
    case "info":
      return {
        background: theme.palette.grey[700],
        color: theme.palette.background.paper,
        "&:hover": {
          background: theme.palette.grey[500],
        },
        "&:focus-visible": {
          background: theme.palette.grey,
        },
      }
    default:
      return {
        background: theme.palette.background.default,
        color: LabelOnly ? theme.palette.secondary.main : theme.palette.blue.primary,
        border: textButton ? "none" : `1px solid ${theme.palette.blue.bannerStroke}`,
        width: href ? "fit-content" : "auto",
        "&:hover": {
          background: theme.palette.blue.contentBox,
          textDecoration: href ? "underline" : "none",
        },
        "&:focus-visible": {
          background: theme.palette.blue.bannerStroke,
          textDecoration: href ? "underline" : "none",
        },
      }
  }
}

const defaultStyles = ({ size = "medium", disabled = false, ...props }): object => {
  const styles: Record<string, string | number> = {
    ...getButtonColorStyle(props),
    height: theme.spacing(size === "medium" ? 5 : 4),
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px", // Defaulting this to 6px to match designs (jhubay told me so)
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
    textDecoration: props.href ? "underline" : "none",
    fontFamily: "Inter, sans-serif",
    fontWeight: 500,
  }

  if (disabled) {
    styles.background = theme.palette.disabled.buttonBackground
    styles.color = theme.palette.disabled.main
  }

  return styles
}

export const StyledButton = styled(MUIButton, { shouldForwardProp: prop => prop !== "textButton" })(
  ({ size = "medium", ...props }: MUIButtonProps) => ({
    ...defaultStyles({ size, ...props }),
    padding: theme.spacing(0.5, 2),
  })
)

const StyledIconButton = styled(IconButton)(({ size = "medium", ...props }: ButtonProps) => ({
  ...defaultStyles({ size, ...props }),
  height: theme.spacing(4),
  width: theme.spacing(4),
}))

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, iconLeft, iconRight, ...props }, ref) => {
    const renderContent = () => {
      if (props.children)
        return (
          <StyledButton ref={ref} {...props}>
            {props.children}
          </StyledButton>
        )

      if (!label && iconLeft) {
        return (
          <StyledIconButton ref={ref} {...props}>
            {iconLeft}
          </StyledIconButton>
        )
      }

      if (!label && iconRight) {
        return (
          <StyledIconButton ref={ref} {...props}>
            {iconRight}
          </StyledIconButton>
        )
      }

      return (
        <StyledButton ref={ref} {...props}>
          {iconLeft}
          {label}
          {iconRight}
        </StyledButton>
      )
    }

    return renderContent()
  }
)
Button.displayName = "Button"
