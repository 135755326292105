import { PropsWithChildren, useMemo } from "react"
import { withSuspense } from "common/withSuspense"
import { DocumentSkeleton } from "./skeletons/DocumentSkeleton"
import { DocumentHeaderContainer } from "./styled"
import { useParams } from "react-router-dom"
import { DocumentContextProvider } from "./context"
import { DocumentTitle } from "./DocumentTitle"
import { KiliButton, MedicalSummaryButton } from "./Buttons"
import { Box } from "@mui/material"
import { DocumentData } from "./DocumentData"
import { DocumentErrors } from "./DocumentErrors"
import { useDocumentStateStore } from "./store/documentState"

export const Document = withSuspense(
  function Document({ children }: PropsWithChildren): Nullable<JSX.Element> {
    const { id: documentId } = useParams()
    const contextValue = useMemo(() => (documentId ? { documentId } : null), [documentId])
    const showExtraButtons = useDocumentStateStore(state => state.showExtraButtons)
    const showAnnotationButton = useDocumentStateStore(state => state.showAnnotationButton)
    const title = useDocumentStateStore(state => state.title)

    if (!contextValue) return null

    return (
      <DocumentContextProvider value={contextValue}>
        <DocumentData>
          <div>
            {title && (
              <DocumentHeaderContainer>
                <DocumentTitle />
                <Box display="flex" gap={1}>
                  {showAnnotationButton && <KiliButton documentId={documentId} />}
                  {showExtraButtons && <MedicalSummaryButton documentId={documentId} />}
                  <div id="page-actions-container" />
                </Box>
              </DocumentHeaderContainer>
            )}
            <div>{children}</div>
          </div>
        </DocumentData>
        <DocumentErrors />
      </DocumentContextProvider>
    )
  },
  <DocumentSkeleton />
)
